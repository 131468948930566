import { AUTHEN } from '@constants/auth'
import { DocumentNode } from 'graphql'
import { IErrorResponse, TYPE_ERROR } from '../models/common'
import { refreshTokenAction } from '../store/auth/auth.action'
import { actions } from '../store/auth/auth.reducer'
import { TYPES_AUTH } from '../store/auth/auth.type'
import { store } from '../store/store'
import { getTokenCookie } from '../utils/cookies'
import { helper } from '../utils/helper/common'
import client from './client'
import { ISubscriptionRequest } from '../models/graphql'
import { PATHNAME } from '@constants/common'

class Graphql {
  private async refreshToken() {
    const refreshToken = getTokenCookie(AUTHEN.REFRESHTOKEN)
    const rememberMeToken = getTokenCookie(AUTHEN.REMEMBERMETOKEN)
    const data = {
      refreshToken,
      rememberMeToken,
    }
    const dataClean = helper.cleanObj(data)
    return await store.dispatch(refreshTokenAction(dataClean))
  }

  public async mutationRequest<T>(
    mutation: DocumentNode,
    resMutation: string,
    variables?: T,
    option?: T
  ) {
    const res = await client.mutate({
      mutation,
      variables: variables,
      fetchPolicy: 'no-cache',
      context: {
        ...option,
      },
    })
    const data = res.data[resMutation]
    if (data.error) {
      if (data.error.message === TYPE_ERROR.JWT_EXPIRED) {
        const resRefesh = await this.refreshToken()
        if (resRefesh.payload.data) {
          const res = await client.mutate({
            mutation,
            variables: variables,
            fetchPolicy: 'no-cache',
            context: {
              ...option,
            },
          })
          const dataNew = res.data[resMutation]
          return dataNew
        }
        if (resRefesh.payload.error) {
          const err: IErrorResponse = resRefesh.payload
          store.dispatch(actions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
          return err
        }
      } else if (
        data.error.message === TYPE_ERROR.UNAUTHORIZED ||
        data.error.message === TYPE_ERROR.REQUEST_INVALID
      ) {
        store.dispatch(actions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
        return data
      } else return data
    } else {
      return data
    }
  }

  public async queryRequest<T>(
    query: DocumentNode,
    resQuery: string | Array<string>,
    variables?: T
  ) {
    const res = await client.query({
      query,
      variables,
      fetchPolicy: 'no-cache',
    })
    if (typeof resQuery === 'string') {
      const data = res.data[resQuery]
      if (data.error) {
        if (data.error.message === TYPE_ERROR.JWT_EXPIRED) {
          const resRefesh = await this.refreshToken()
          if (resRefesh.payload.data) {
            const res = await client.query({
              query,
              variables: variables,
              fetchPolicy: 'no-cache',
            })
            const dataNew = res.data[resQuery]
            return dataNew
          } else {
            store.dispatch(actions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
            return resRefesh.payload
          }
        } else if (
          data.error.message === TYPE_ERROR.UNAUTHORIZED ||
          data.error.message === TYPE_ERROR.REQUEST_INVALID
        ) {
          store.dispatch(actions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
          return data
        } else return data
      } else {
        return data
      }
    } else {
      const dataRes: Array<T> = []
      resQuery.forEach(async (queryS) => {
        const data = res.data[queryS]
        if (data.error) {
          if (data.error.message === TYPE_ERROR.JWT_EXPIRED) {
            const resRefesh = await this.refreshToken()
            if (resRefesh.payload.data) {
              const res = await client.query({
                query,
                variables: variables,
                fetchPolicy: 'no-cache',
              })
              const dataNew = res.data[queryS]
              dataRes.push(dataNew)
            } else {
              store.dispatch(actions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
              return resRefesh.payload
            }
          } else if (
            data.error.message === TYPE_ERROR.UNAUTHORIZED ||
            data.error.message === TYPE_ERROR.REQUEST_INVALID
          ) {
            store.dispatch(actions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
            return data
          } else return data
        } else {
          dataRes.push(data)
        }
      })
      return dataRes
    }
  }

  /**
   * This function handles GraphQL subscriptions. It subscribes to a GraphQL query and provides
   * callback functions for handling the received data and errors.
   *
   * @template T - The type of the data expected in the response.
   *
   * @param input - An object containing the necessary parameters for the subscription request.
   * @param input.query - The GraphQL query to subscribe to.
   * @param input.variables - The variables for the GraphQL query.
   * @param input.resQuery - The key in the response data to access the actual data.
   * @param input.option - Additional options for the request.
   * @param input.onNext - A callback function to handle the received data.
   * @param input.onError - A callback function to handle errors.
   *
   * @returns A subscription object that can be used to unsubscribe from the subscription.
   */
  public subscriptionRequest<T>(input: ISubscriptionRequest<T>) {
    const res = client.subscribe({
      query: input.query,
      variables: input.variables,
      fetchPolicy: 'no-cache',
      context: {
        ...input.option,
      },
    })

    return res.subscribe(
      ({ data }) => input.onNext && input.onNext(data[input.resQuery].data),
      async (error) => {
        if (error?.message && error.message.includes(TYPE_ERROR.JWT_EXPIRED)) {
          await this.refreshToken()
        }
        return input.onError && input.onError(error)
      }
    )
  }
}
export const graphqlApi = new Graphql()
