import { gql } from '@apollo/client'

export const GET_USER_PLAN_BY_USER_ID = gql`
  query getUserPlanByUserId($userId: Int!) {
    getUserPlanByUserId(userId: $userId) {
      id
      userId
      planId
      plan {
        id
        name
        price
        metadata
        remarks
        planFeatures {
          id
          feature {
            id
            key
          }
        }
      }
      expirationFrom
      expirationTo
    }
  }
`

export const GET_USER_PLAN_PLAN_PRICE = gql`
  query getUserPlanPrice {
    getUserPlanPrice {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        currency
        price
        billingPeriodType
        startTimestamp
        upcoming {
          price
          planName
          billingPeriodType
          startTimestamp
        }
      }
    }
  }
`

export const UPDATE_PLAN_BY_CHECKOUT_SESSION = gql`
  mutation updatePlanByCheckoutSession($checkoutSessionId: String!) {
    updatePlanByCheckoutSession(checkoutSessionId: $checkoutSessionId) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const CHANGE_USER_SUBSCRIPTION = gql`
  mutation changeSubscription($input: ChangeSubscriptionInput!) {
    changeSubscription(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const CANCEL_CMS_SUBSCRIPTION = gql`
  mutation cancelCmsSubscription {
    cancelSubscription {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_DISCOUNT_DETAILS = gql`
  query getPaymentDetails($priceId: String!) {
    getPaymentDetails(input: { priceId: $priceId }) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        discount
        totalWithoutDiscount
        total
        startTimestamp
        endTimestamp
        currency
      }
    }
  }
`

export const UPDATE_CREDIT_CARD_DETAILS = gql`
  mutation createOnlySetupSession($input: CreateOnlySetupSessionInput!) {
    createOnlySetupSession(input: $input) {
      statusCode
      message
      data {
        sessionId
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_BILLING_HISTORY = gql`
  query getBillingHistory {
    getBillingHistory {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        invoices {
          invoiceId
          total
          storage
          planName
          planNameEn
          currency
          startTimestamp
          invoiceUrl
        }
        upcomingInvoices {
          invoiceId
          total
          storage
          planName
          planNameEn
          currency
          startTimestamp
          billingPeriodType
        }
      }
    }
  }
`
