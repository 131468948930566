import React from 'react'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { IProject } from '@models/project'
import DefaultImage from '@assets/images/project-thumb-default.svg'
import DefaultImage2 from '@assets/images/project-thumb-default-2.svg'
import ThumbBottomInfo from './ThumbBottomInfo'
import ThumbBottomActions from './ThumbBottomActions'
import { ProjectPublishStatusName } from '@models/organization'
import classNames from 'classnames'
import ThumbBottomStatus from './ThumbBottomStatus'

interface IPropsType {
  projectEdit: IProject | null
  handleOpenMoreSettingProject: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    indexOpen: number,
    project: IProject
  ) => void
  handleGetInfoProject: (projectId: number) => void
}
const ThumModeComponent: React.FC<IPropsType> = (props): React.ReactElement => {
  const { projectEdit, handleGetInfoProject, handleOpenMoreSettingProject } =
    props
  const { listProject } = useAppSelector((state: RootState) => state.project)
  return (
    <div className="grid grid-cols-3 search-grid-3xl:grid-cols-4 search-grid-4xl:grid-cols-5 h-full">
      {listProject &&
        listProject.projects &&
        listProject.projects.map((project, index) => (
          <div
            className="p-4 hover:bg-white__op-50 rounded-2xl cursor-pointer group"
            key={index}
            onClick={() => handleGetInfoProject(project.projectId)}
          >
            <div
              className="w-full h-40 rounded-lg overflow-hidden relative bg-gray-700
            "
            >
              {project.projectImageUrl ? (
                <img
                  src={project.projectImageUrl}
                  className="w-full h-full"
                  alt=""
                />
              ) : (
                <>
                  <img
                    src={DefaultImage}
                    className="absolute w-full h-full inset-0 mix-blend-multiply object-cover"
                    alt=""
                  />
                  <img
                    src={DefaultImage2}
                    className="absolute w-full h-full inset-0 mix-blend-soft-light object-cover"
                    alt=""
                  />
                </>
              )}
            </div>
            <div
              className={classNames(
                'grid grid-cols-1',
                {
                  'justify-items-center':
                    project.publishStatus !==
                    ProjectPublishStatusName.UN_PUBLISH,
                },
                {
                  'justify-items-start place-items-start':
                    project.publishStatus ===
                    ProjectPublishStatusName.UN_PUBLISH,
                }
              )}
            >
              <div className="invisible group-hover:visible mt-3 flex justify-between items-center row-start-1 col-start-1 w-full">
                <ThumbBottomInfo project={project} />
                <ThumbBottomActions
                  project={project}
                  projectEdit={projectEdit}
                  index={index}
                  handleOpenMoreSettingProject={handleOpenMoreSettingProject}
                />
              </div>
              <div className="visible group-hover:invisible mt-3 flex justify-between items-center row-start-1 col-start-1 w-full">
                <ThumbBottomInfo project={project} />
                {project.publishStatus !==
                  ProjectPublishStatusName.UN_PUBLISH && (
                  <ThumbBottomStatus project={project} />
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default ThumModeComponent
