import _ from 'lodash'
import * as pdfjsLib from 'pdfjs-dist'
pdfjsLib.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js'

import {
  ICoordinates,
  IDataSelectMarkerInfoTag,
  IInfoTagResponse,
  ILayer,
  IMarkerResponse,
  IResLayerOfGetSpaceAndScene,
  IResponseUpdateScene,
  IResponseUpdateSpace,
  IResSceneOfGetSpaceAndScene,
  IResSpaceOfGetSpaceAndScene,
  IScene,
  ISpace,
  ISpot,
  ISpotInfoTag,
  ISpotMarker,
  NodeTreeViewItem,
  structureSpaceAndScene,
  TypeContentInfoTag,
} from '../../models/sceneSetting'
import {
  IMedia,
  IMediaFolder,
  IProcessUploadFile,
  ISelectedMedia,
  IUploadFile,
  IUploadingMediasGlobal,
  TYPE_MEDIA,
  TYPE_STATUS_UPLOAD,
} from '../../models/media'
import { store } from '../../store/store'
import { setTokenCookie } from '../cookies'
import { IDefaultSceneSet, IResponseListProject } from '../../models/project'
import html2canvas from 'html2canvas'
import { VALID_WHITE_SPACE_START_REGEX } from '@constants/common'
import { AUTHEN } from '@constants/auth'
import { Node } from '@components/common/draggableTree/node'
import { TYPE_SETTING } from '@stores/scene/scene.reducer'
import { ProjectPublishStatusName } from '@models/organization'

class Helper {
  /**
   * Returns email is valid or not.
   *
   * @param {string} email.
   * @return {boolean} true or false.
   */
  public validateEmail(email: string) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  public getToken() {
    const globalStore = store.getState()
    const token = globalStore.auth.accessToken
    return token
  }

  public getRefreshToken() {
    const globalStore = store.getState()
    const token = globalStore.auth.refreshToken
    return token
  }

  public getExpiredTime() {
    const globalStore = store.getState()
    const time = globalStore.auth.expired
    return time
  }

  public sortMedias(a: IMedia, b: IMedia) {
    if (
      a.status === TYPE_STATUS_UPLOAD.UPLOADING &&
      b.status !== TYPE_STATUS_UPLOAD.UPLOADING
    ) {
      return -1
    } else if (
      a.status !== TYPE_STATUS_UPLOAD.UPLOADING &&
      b.status === TYPE_STATUS_UPLOAD.UPLOADING
    ) {
      return 1
    }
    return a.name.localeCompare(b.name)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public cleanObj(obj: any) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName]
      }
    }
    return obj
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public compareTwoObject(obj1: any, obj2: any) {
    let result = {}
    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        if (key === 'password' && obj1[key] !== '*******') {
          result = { ...result, [key]: obj1[key] }
        }
        if (key === 'avatar' && obj1[key].file) {
          result = { ...result, [key]: obj1[key] }
        }
        if (key !== 'avatar' && key !== 'password') {
          result = { ...result, [key]: obj1[key] }
        }
      }
    }
    return result
  }

  public toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  public removeFromList<T>(list: T[], index: number) {
    const resultAfterRemeved = Array.from(list)
    const [removed] = resultAfterRemeved.splice(index, 1)
    return { removed, resultAfterRemeved }
  }

  public addToList<T>(list: T[], index: number, element: T) {
    const result = Array.from(list)
    result.splice(index, 0, element)
    return result
  }

  public reorder<T>(list: T[], startIndex: number, endIndex: number) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  public getIndexLayer(listItem: ILayer[], id: number) {
    let indexLayer = -1
    listItem.forEach((layer, index: number) => {
      layer.spaces.forEach((space) => {
        if (space.info.id === id) {
          indexLayer = index
          return
        }
      })
    })
    return indexLayer
  }

  public findScene(listItem: ILayer[], id: number) {
    const member: Array<ISpace> = []
    listItem.forEach((layer) => {
      layer.spaces.forEach((space) => {
        if (space.info.id === id) {
          member.push(space)
          return
        }
      })
    })
    return member[0]
  }

  public removeScene(listLayer: ILayer[], sceneId: number) {
    const newList = _.cloneDeep(listLayer)
    if (newList && newList.length > 0) {
      newList.forEach((layer) => {
        if (layer.spaces && layer.spaces.length > 0) {
          layer.spaces.forEach((space) => {
            if (space.scenes && space.scenes.length > 0) {
              space.scenes.forEach((scene, index) => {
                if (scene.info.id === sceneId) {
                  space.scenes.splice(index, 1)
                }
              })
            }
          })
        }
      })
    }
    return newList
  }

  public removeSpace(listLayer: ILayer[], spaceId: number) {
    const newList = _.cloneDeep(listLayer)
    if (newList && newList.length > 0) {
      newList.forEach((layer) => {
        if (layer.spaces && layer.spaces.length > 0) {
          layer.spaces.forEach((space, index) => {
            if (space.info.id === spaceId) {
              layer.spaces.splice(index, 1)
            }
          })
        }
      })
    }
    return newList
  }

  public removeLayer(listLayer: ILayer[], layerId: number) {
    const newList = _.cloneDeep(listLayer)
    if (newList && newList.length > 0) {
      newList.forEach((layer, index) => {
        if (layer.info.id === layerId) {
          newList.splice(index, 1)
        }
      })
    }
    return newList
  }

  public replaceChildrenInMediaFolders(
    mediaFolders: IMediaFolder[],
    folderId: number,
    children: IMedia[]
  ) {
    const newMediaFolders = _.cloneDeep(mediaFolders)

    const mediaFolder = newMediaFolders.find(
      (mediaFolder) => mediaFolder.id === folderId
    )
    if (!mediaFolder) {
      return newMediaFolders
    }
    mediaFolder.children = [...children]
    return [...newMediaFolders]
  }

  public addChildrenToMediaFolders(
    mediaFolders: IMediaFolder[],
    folderId: number,
    children: IMedia[]
  ) {
    const newMediaFolders = _.cloneDeep(mediaFolders)

    const mediaFolder = newMediaFolders.find(
      (mediaFolder) => mediaFolder.id === folderId
    )
    if (!mediaFolder) {
      return newMediaFolders
    }
    mediaFolder.children = [...(mediaFolder.children ?? []), ...children].sort(
      helper.sortMedias
    )
    return [...newMediaFolders]
  }

  public updateSpace(listLayer: Array<ILayer>, resSpace: IResponseUpdateSpace) {
    const newList = _.cloneDeep(listLayer)
    let isUpdateInfo = false
    let isStop = false
    if (newList && newList.length > 0) {
      for (const layer of newList) {
        if (isStop) {
          break
        }
        if (!resSpace.isLayer && layer && layer.spaces.length > 0) {
          for (const space of layer.spaces) {
            if (
              space.info.id === resSpace.id &&
              (space.info.title !== resSpace.title ||
                space.info.thumbnail.url !== resSpace.thumbnailUrl)
            ) {
              space.info.title = resSpace.title
              space.info.thumbnail.url = resSpace.thumbnailUrl
              isUpdateInfo = true
              isStop = true
              break
            }
          }
        }
        if (
          resSpace.isLayer &&
          layer.info.id === resSpace.id &&
          layer.info.title !== resSpace.title
        ) {
          layer.info.title = resSpace.title
          isUpdateInfo = true
        }
      }
    }
    return { isUpdateInfo, newListLayer: [...newList] }
  }

  public updateScene(
    listLayer: Array<ILayer>,
    resScene: IResponseUpdateScene,
    exSpaceId: number
  ) {
    const newList = _.cloneDeep(listLayer)
    let isUpdateStructure = false
    let isStop = false
    if (newList && newList.length > 0) {
      for (const layer of newList) {
        if (isStop) {
          break
        }
        if (layer && layer.spaces.length > 0) {
          for (const space of layer.spaces) {
            if (isStop) {
              break
            }
            if (space && space.scenes.length > 0) {
              for (const scene of space.scenes) {
                if (scene.info.id === resScene.sceneId) {
                  scene.info.title = resScene.title
                  scene.info.thumbnailUrl =
                    resScene.thumbnailUrl || resScene.contextThumbnailUrl
                  if (resScene.spaceId !== exSpaceId) {
                    isUpdateStructure = true
                  }
                  isStop = true
                  break
                }
              }
            }
          }
        }
      }
    }
    return { isUpdateStructure, newListLayer: [...newList] }
  }

  public genListScene(listLayer: Array<ILayer>) {
    const newList = _.cloneDeep(listLayer)
    const listScene: Array<IScene> = []
    if (newList && newList.length > 0) {
      newList.forEach((layer) => {
        if (layer && layer.spaces.length > 0) {
          layer.spaces.forEach((space) => {
            if (space && space.scenes.length > 0) {
              space.scenes.forEach((scene) => {
                listScene.push(scene)
              })
            }
          })
        }
      })
    }
    return [...listScene]
  }

  public genListSceneFromSpace(listSpace: Array<ISpace>, isReverse?: boolean) {
    const newList = _.cloneDeep(listSpace)
    const listScene: Array<IScene> = []
    if (newList && newList.length > 0) {
      newList.forEach((space) => {
        if (space && space.scenes.length > 0) {
          if (isReverse) {
            _.reverse(space.scenes).forEach((scene) => {
              listScene.push(scene)
            })
          } else {
            space.scenes.forEach((scene) => {
              listScene.push(scene)
            })
          }
        }
      })
    }
    return [...listScene]
  }

  public deleteSceneFromSpace(space: ISpace, idScene: number) {
    const newSpace = _.cloneDeep(space)
    newSpace.scenes.forEach((scene, index) => {
      if (scene.info.id === idScene) {
        newSpace.scenes.splice(index, 1)
      }
    })
    return newSpace
  }

  public deleteSceneFromLayer(layer: ILayer, idScene: number) {
    const newLayer = _.cloneDeep(layer)
    newLayer.spaces.forEach((space) => {
      if (space && space.scenes.length > 0) {
        space.scenes.forEach((scene, index) => {
          if (scene.info.id === idScene) {
            space.scenes.splice(index, 1)
          }
        })
      }
    })
    return newLayer
  }

  public deleteSceneAndUpdateActive(
    listSpaceAndScene: Array<ILayer>,
    layer: ILayer,
    space: ISpace,
    sceneId: number
  ) {
    let idNewSceneActive: number | null = null
    let newLayerActive: ILayer | null = null
    let newSpaceActive: ISpace | null = null
    const listSceneBeforeUpdate = _.cloneDeep(space.scenes)
    const listSpaceBeforeUpdate = _.cloneDeep(layer.spaces)
    const spaceAfterUpdate = this.deleteSceneFromSpace(space, sceneId)
    const layerAfterUpdate = this.deleteSceneFromLayer(layer, sceneId)
    const indexScene = _.findIndex(
      listSceneBeforeUpdate,
      (scene) => scene.info.id === sceneId
    )
    const dataNextScene = listSceneBeforeUpdate[indexScene + 1]
    const dataPrevScene = listSceneBeforeUpdate[indexScene - 1]
    if (dataNextScene || dataPrevScene) {
      idNewSceneActive = dataNextScene
        ? dataNextScene.info.id
        : dataPrevScene.info.id
      newLayerActive = layerAfterUpdate
      newSpaceActive = spaceAfterUpdate
    } else {
      const { idScene, newLayer, newSpace } =
        this.deleteSceneAndUpdateAnotherSpace(
          listSpaceAndScene,
          listSpaceBeforeUpdate,
          space.info.id,
          layerAfterUpdate
        )
      if (idScene && newLayer && newSpace) {
        idNewSceneActive = idScene
        newLayerActive = newLayer
        newSpaceActive = newSpace
      }
    }
    return { idNewSceneActive, newLayerActive, newSpaceActive }
  }

  public updateSceneThumbnail(
    listSpaceAndScene: Array<ILayer>,
    sceneId: number,
    newThumbnailId: number,
    contextType: string
  ): Array<ILayer> {
    const newListSpaceAndScene = _.cloneDeep(listSpaceAndScene)

    for (const layer of newListSpaceAndScene) {
      for (const space of layer.spaces) {
        for (const scene of space.scenes) {
          if (scene.info.id === sceneId) {
            if (contextType === TYPE_MEDIA.VIDEO) {
              scene.info.videoThumbnailId = newThumbnailId
            } else {
              scene.info.imageThumbnailId = newThumbnailId
            }
            break
          }
        }
      }
    }

    return newListSpaceAndScene
  }

  public deleteSceneAndUpdateAnotherSpace(
    listSpaceAndScene: Array<ILayer>,
    listSpaceBeforeUpdate: Array<ISpace>,
    spaceId: number,
    layerAfterUpdate: ILayer
  ) {
    let idScene: number | null = null
    let newSpace: ISpace | null = null
    let newLayer: ILayer | null = null
    const indexSP = _.findIndex(
      listSpaceBeforeUpdate,
      (space) => space.info.id === spaceId
    )
    const listSpaceNext = _.drop(listSpaceBeforeUpdate, indexSP + 1)
    const listSpacePrev = _.dropRight(
      listSpaceBeforeUpdate,
      listSpaceBeforeUpdate.length - indexSP
    )
    const listSceneNext = helper.genListSceneFromSpace(listSpaceNext)
    const listScenePrev = helper.genListSceneFromSpace(
      _.reverse(listSpacePrev),
      true
    )
    if (listSceneNext.length > 0 || listScenePrev.length > 0) {
      idScene =
        listSceneNext.length > 0
          ? listSceneNext[0].info.id
          : listScenePrev[0].info.id
      const space = _.find(
        listSpaceBeforeUpdate,
        _.flow(
          _.property('scenes'),
          _.partialRight(_.some, (scene: IScene) => scene.info.id === idScene)
        )
      )
      if (space) {
        newSpace = space
      }
      newLayer = layerAfterUpdate
    } else {
      const { dataNewLayer, dataNewScene, dataNewSpace } =
        this.deleteSceneAndUpdateAnotherLayer(
          listSpaceAndScene,
          layerAfterUpdate
        )
      if (dataNewLayer && dataNewSpace && dataNewScene) {
        newLayer = dataNewLayer
        newSpace = dataNewSpace
        idScene = (dataNewScene as IScene).info.id
      }
    }
    return { idScene, newLayer, newSpace }
  }

  public deleteSceneAndUpdateAnotherLayer(
    listLayer: Array<ILayer>,
    layer: ILayer
  ) {
    const newList = _.cloneDeep(listLayer)
    let dataNewLayer: ILayer | null = null
    let dataNewSpace: ISpace | null = null
    let dataNewScene: IScene | null = null
    const indexLayer = _.findIndex(
      newList,
      (layerChild) => layerChild.info.id === layer.info.id
    )
    const listLayerNext = _.drop(newList, indexLayer + 1)
    const listLayerPrev = _.dropRight(newList, newList.length - indexLayer)
    if (listLayerNext) {
      const { layerNext, spaceNext, sceneNext } =
        this.findSceneFromListLayerNext(listLayerNext)
      dataNewLayer = layerNext
      dataNewSpace = spaceNext
      dataNewScene = sceneNext
    }
    if (listLayerNext.length === 0 && listLayerPrev.length > 0) {
      const { layerPrev, spacePrev, scenePrev } =
        this.findSceneFromListLayerPrev(_.reverse(listLayerPrev))
      dataNewLayer = layerPrev
      dataNewSpace = spacePrev
      dataNewScene = scenePrev
    }
    return { dataNewLayer, dataNewSpace, dataNewScene }
  }

  private findSceneFromListLayerPrev(listLayer: Array<ILayer>) {
    let layerPrev: ILayer | null = null
    let spacePrev: ISpace | null = null
    let scenePrev: IScene | null = null
    let isStop = false
    for (const layer of listLayer) {
      if (isStop) {
        break
      }
      if (layer.spaces.length > 0) {
        for (const space of _.reverse(layer.spaces)) {
          if (space.scenes.length > 0) {
            scenePrev = space.scenes[space.scenes.length - 1]
            if (scenePrev) {
              spacePrev = space
              layerPrev = layer
              isStop = true
              break
            }
          }
        }
      }
    }
    return { layerPrev, spacePrev, scenePrev }
  }

  private findSceneFromListLayerNext(listLayer: Array<ILayer>) {
    let isStop = false
    let layerNext: ILayer | null = null
    let spaceNext: ISpace | null = null
    let sceneNext: IScene | null = null
    for (const layer of listLayer) {
      if (isStop) {
        break
      }
      if (layer.spaces.length > 0) {
        for (const space of layer.spaces) {
          if (space.scenes.length > 0) {
            sceneNext = space.scenes[0]
            if (sceneNext) {
              spaceNext = space
              layerNext = layer
              isStop = true
              break
            }
          }
        }
      }
    }
    return { layerNext, spaceNext, sceneNext }
  }

  public openScene(
    listLayer: Array<ILayer>,
    idLayer: number,
    idSpace?: number
  ) {
    const newList = _.cloneDeep(listLayer)
    let isStop = false
    for (const layer of newList) {
      if (isStop) {
        break
      }
      if (layer.info.id === idLayer) {
        layer.collapsed = false
        if (layer.spaces.length > 0) {
          for (const space of layer.spaces) {
            if (space.info.id === idSpace) {
              space.collapsed = false
              break
            }
          }
        }
        isStop = true
        break
      }
    }
    return newList
  }

  public removeMediaFromFolders(
    mediaFolders: IMediaFolder[],
    selectedMedia: ISelectedMedia
  ) {
    const clonedMediaFolders = _.cloneDeep(mediaFolders)

    const folder = clonedMediaFolders.find(
      (folder) => folder.id === selectedMedia.folderId
    )
    if (!folder) {
      return clonedMediaFolders
    }

    folder.children = folder.children.filter(
      (media) => media.key !== selectedMedia.key
    )

    return clonedMediaFolders
  }

  public updateMediaFolder(
    mediaFolders: IMediaFolder[],
    folderId: number,
    name: string
  ) {
    const newMediaFolders = _.cloneDeep(mediaFolders)

    const folder = newMediaFolders.find((folder) => folder.id === folderId)
    if (!folder) {
      return newMediaFolders
    }
    folder.name = name
    newMediaFolders.sort((a, b) => a.name.localeCompare(b.name))

    return [...newMediaFolders]
  }

  public updateMediasInListMedias(medias: IMedia[], data: IMedia[]) {
    const clonedMedias = _.cloneDeep(medias)

    for (const newMedia of data) {
      const updatedMedia = clonedMedias.find(
        (media) =>
          media.key?.replace('360/', '') === newMedia.key?.replace('360/', '')
      )
      if (!updatedMedia) {
        return clonedMedias
      }

      Object.assign(updatedMedia, { ...newMedia })
    }

    clonedMedias.sort(this.sortMedias)

    return clonedMedias
  }

  public updateMediasInMediaFolders(
    mediaFolders: IMediaFolder[],
    data: IMedia[]
  ) {
    const newMediaFolders = _.cloneDeep(mediaFolders)
    for (const media of data) {
      for (const folder of newMediaFolders) {
        const updatingMedia = folder.children?.find(
          (item) =>
            item.key?.replace('360/', '') === media.key?.replace('360/', '')
        )
        if (updatingMedia) {
          Object.assign(updatingMedia, { ...media })
          folder.children.sort(this.sortMedias)
          return newMediaFolders
        }
      }
    }

    return newMediaFolders
  }

  public markStatusForMedias(media: IMedia) {
    if (media.status) return media
    if (media.mediaType === TYPE_MEDIA.VIDEO360) {
      return {
        ...media,
        progress: 0,
        status: TYPE_STATUS_UPLOAD.CONVERTING,
      }
    }
    return {
      ...media,
      progress: 0,
      status: TYPE_STATUS_UPLOAD.COMPLETE,
    }
  }

  public addUploadingMediasGlobal(
    uploadingMediasGlobal: IUploadingMediasGlobal[],
    payload: IUploadingMediasGlobal
  ) {
    const clonedUploadingMedias = _.cloneDeep(uploadingMediasGlobal)
    const uploadingMedias = clonedUploadingMedias.find(
      (item) =>
        item.projectId === payload.projectId &&
        item.folderId === payload.folderId
    )
    if (uploadingMedias) {
      uploadingMedias.medias.push(...payload.medias)
    } else {
      clonedUploadingMedias.push(payload)
    }
    return clonedUploadingMedias
  }

  public mixUploadingMediasWithMedias(
    uploadingMediasGlobal: IUploadingMediasGlobal[],
    payload: IUploadingMediasGlobal
  ) {
    const uploadingMediasAfterRemoving = this.removeUploadingMediasGlobal(
      uploadingMediasGlobal,
      payload
    )
    const uploadingMedias = uploadingMediasAfterRemoving.find(
      (item) =>
        item.projectId === payload.projectId &&
        item.folderId === payload.folderId
    )

    const mixedMedias = [...(uploadingMedias?.medias ?? []), ...payload.medias]
    mixedMedias.sort(this.sortMedias)
    return mixedMedias
  }

  public removeUploadingMediasGlobal(
    uploadingMediasGlobal: IUploadingMediasGlobal[],
    payload: IUploadingMediasGlobal
  ) {
    const clonedUploadingMedias = _.cloneDeep(uploadingMediasGlobal)
    const uploadingMedias = clonedUploadingMedias.find(
      (item) =>
        item.projectId === payload.projectId &&
        item.folderId === payload.folderId
    )

    if (!uploadingMedias?.medias) {
      return clonedUploadingMedias
    }
    uploadingMedias.medias = uploadingMedias?.medias.filter(
      (media) =>
        !payload.medias.some(
          (mediaPayload) =>
            mediaPayload.key.replace('360/', '') ===
            media.key.replace('360/', '')
        )
    )
    return clonedUploadingMedias
  }

  public checkHasScene(listLayer: Array<ILayer>) {
    const newListLayer = _.cloneDeep(listLayer)
    let isHasScene = false
    for (const layer of newListLayer) {
      for (const space of layer.spaces) {
        for (const scene of space.scenes) {
          if (scene) {
            isHasScene = true
            break
          }
        }
        if (isHasScene) break
      }
      if (isHasScene) break
    }
    return isHasScene
  }

  public updateAccessTokenAndRefreshToken(
    accessToken: string,
    refreshToken: string
  ) {
    setTokenCookie(AUTHEN.ACCESSTOKEN, accessToken)
    setTokenCookie(AUTHEN.REFRESHTOKEN, refreshToken)
  }

  public updateListProject(
    listProject: IResponseListProject,
    projectNew: {
      projectId: number
      publishStatus: ProjectPublishStatusName
      description: string
      projectName: string
    }
  ) {
    const { projectId, publishStatus, projectName, description } = projectNew
    const newList = _.cloneDeep(listProject)
    for (const project of newList.projects) {
      if (project.projectId === projectId) {
        project.description = description
        project.projectName = projectName
        project.publishStatus = publishStatus
        break
      }
    }
    return newList
  }

  public async genNumPage(file: string | null, num?: number) {
    const listUrl: Array<string> = []
    if (file) {
      const canvas = document.createElement('canvas')
      await pdfjsLib
        .getDocument({
          url: file,
          cMapUrl: 'https://unpkg.com/pdfjs-dist/cmaps/',
          cMapPacked: true,
          useWorkerFetch: true,
        })
        .promise.then(async (doc) => {
          const numPage = num
            ? num === 1
              ? num
              : doc.numPages > num
              ? num
              : doc.numPages
            : doc.numPages
          for (let i = 1; i <= numPage; i++) {
            const page = await doc.getPage(i)
            const viewport = page.getViewport({ scale: 1 })
            canvas.height = viewport.height
            canvas.width = viewport.width
            const renderContext = {
              canvasContext: canvas.getContext(
                '2d'
              ) as CanvasRenderingContext2D,
              viewport: viewport,
            }
            await page.render(renderContext).promise
            const urlImg = canvas.toDataURL('image/jpeg')
            listUrl.push(urlImg)
          }
        })
    }
    return listUrl
  }

  public base64toBlob(base64DataUrl: string) {
    const base64Data = base64DataUrl.split(';base64,').pop() as string
    const blob = new Blob(
      [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
      { type: 'image/png' }
    )
    return blob
  }

  public async captureSceenShot(id: string) {
    const cap = document.querySelector(`#${id}`)
    const heightCap = cap?.clientHeight
    const widthCap = cap?.clientWidth
    const capConffig = {
      imageTimeout: 15000,
      useCORS: false,
      allowTaint: false,
      backgroundColor: '#fff',
      foreignObjectRendering: false,
      logging: false,
      removeContainer: true,
      scale: 1,
      windowHeight: heightCap,
      windowWidth: widthCap,
    }

    if (cap) {
      const canvas = await html2canvas(cap as HTMLElement, capConffig)
      const blobs = await this._getCanvasBlob(canvas)
      return blobs
    }
    return null
  }

  private _getCanvasBlob(canvas: HTMLCanvasElement) {
    return new Promise(function (resolve) {
      canvas.toBlob(
        function (blob) {
          resolve(blob)
        },
        'image/jpeg',
        0.5
      )
    })
  }

  public removeWhiteSpaceStart(value: string) {
    if (!value) return value
    return value.replace(VALID_WHITE_SPACE_START_REGEX, '')
  }

  public updateProcessListFileUpload(
    processListFileUpload: Array<IProcessUploadFile>,
    fileUploading: IUploadFile
  ) {
    const newProcessListFileUpload = _.cloneDeep(processListFileUpload)
    for (const item of newProcessListFileUpload) {
      if (item.session === fileUploading.session) {
        for (const file of item.listDataFile) {
          if (
            file.fileName === fileUploading.dataFile.fileName &&
            file.lastModified === fileUploading.dataFile.lastModified
          ) {
            file.status = fileUploading.dataFile.status
          }
        }
      }
    }
    return newProcessListFileUpload
  }

  public mappingMarker(
    listMarker: Array<IMarkerResponse>,
    dataTypeSelect?: { type: TYPE_SETTING; id: number }
  ): Array<ISpot> {
    const newlistMarker = listMarker.map((marker) => {
      const { xAxis, yAxis, zAxis } = marker.coordinates
      if (dataTypeSelect && marker.id === dataTypeSelect.id) {
        return {
          spotType: 'marker',
          id: marker.id as number,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          sceneId: marker.linkedSceneId,
          isSelected: true,
        } as ISpotMarker
      }
      return {
        spotType: 'marker',
        id: marker.id as number,
        coordinates: {
          xAxis,
          yAxis,
          zAxis,
        },
        sceneId: marker.linkedSceneId,
        isSelected: false,
      } as ISpotMarker
    })

    return [...newlistMarker]
  }

  public mappingInfoTag(
    listInfoTag: Array<IInfoTagResponse>,
    dataTypeSelect?: { type: TYPE_SETTING; id: number }
  ): Array<ISpot> {
    const newlistInfoTag = listInfoTag.map((infoTag) => {
      const { id, tagType, color, size, coordinates, media } = infoTag
      const { xAxis, yAxis, zAxis } = coordinates
      if (dataTypeSelect && infoTag.id === dataTypeSelect.id) {
        return {
          spotType: 'infoTag',
          id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          size,
          tagType:
            tagType === TypeContentInfoTag.TEXT
              ? TypeContentInfoTag.INFO
              : media.mediaName
              ? media.mediaType
              : TypeContentInfoTag.FILE,
          color,
          isSelected: true,
        } as ISpotInfoTag
      }
      return {
        spotType: 'infoTag',
        id,
        coordinates: {
          xAxis,
          yAxis,
          zAxis,
        },
        size,
        tagType:
          tagType === TypeContentInfoTag.TEXT
            ? TypeContentInfoTag.INFO
            : media.mediaName
            ? media.mediaType
            : TypeContentInfoTag.FILE,
        color,
        isSelected: false,
      } as ISpotInfoTag
    })
    return [...newlistInfoTag]
  }

  public mappingMarkerInfoTag(
    listMarker: Array<IMarkerResponse>,
    listInfoTag: Array<IInfoTagResponse>,
    dataTypeSelect?: IDataSelectMarkerInfoTag
  ): Array<ISpot> {
    const newlistMarker = listMarker.map((marker) => {
      const { xAxis, yAxis, zAxis } = marker.coordinates
      if (
        dataTypeSelect &&
        dataTypeSelect.type === TYPE_SETTING.MARKER &&
        marker.id === dataTypeSelect.id
      ) {
        return {
          spotType: 'marker',
          id: marker.id as number,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          sceneId: marker.linkedSceneId,
          isSelected: true,
        } as ISpotMarker
      }
      return {
        spotType: 'marker',
        id: marker.id as number,
        coordinates: {
          xAxis,
          yAxis,
          zAxis,
        },
        sceneId: marker.linkedSceneId,
        isSelected: false,
      } as ISpotMarker
    })
    const newlistInfoTag = listInfoTag.map((infoTag) => {
      const { id, tagType, color, size, coordinates, media } = infoTag
      const { xAxis, yAxis, zAxis } = coordinates
      if (
        dataTypeSelect &&
        dataTypeSelect.type === TYPE_SETTING.INFO_TAG &&
        infoTag.id === dataTypeSelect.id
      ) {
        return {
          spotType: 'infoTag',
          id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          size,
          tagType:
            tagType === TypeContentInfoTag.TEXT
              ? TypeContentInfoTag.INFO
              : media.mediaName
              ? media.mediaType
              : TypeContentInfoTag.FILE,
          color,
          isSelected: true,
        } as ISpotInfoTag
      }
      return {
        spotType: 'infoTag',
        id,
        coordinates: {
          xAxis,
          yAxis,
          zAxis,
        },
        size,
        tagType:
          tagType === TypeContentInfoTag.TEXT
            ? TypeContentInfoTag.INFO
            : media.mediaName
            ? media.mediaType
            : TypeContentInfoTag.FILE,
        color,
        isSelected: false,
      } as ISpotInfoTag
    })
    return [...newlistMarker, ...newlistInfoTag]
  }

  public updateListInfoTag(
    listInfoTag: Array<IInfoTagResponse>,
    newInfoTag: IInfoTagResponse
  ) {
    const newListInfoTag = _.cloneDeep(listInfoTag)
    newListInfoTag.forEach((infotag, index) => {
      if (infotag.id === newInfoTag.id) {
        newListInfoTag.splice(index, 1)
      }
    })
    return [...newListInfoTag, newInfoTag]
  }

  public updateListMarker(
    listMarker: Array<IMarkerResponse>,
    newMarker: IMarkerResponse
  ) {
    const newListMarker = _.cloneDeep(listMarker)
    newListMarker.forEach((marker, index) => {
      if (marker.id === newMarker.id) {
        newListMarker.splice(index, 1)
      }
    })
    return [...newListMarker, newMarker]
  }

  public updateMarkerCoordinate(
    listMarker: Array<IMarkerResponse>,
    id: number,
    coordinates: ICoordinates
  ) {
    const newListMarker = _.cloneDeep(listMarker)
    newListMarker.forEach((marker) => {
      if (marker.id === id) {
        marker.coordinates = coordinates
      }
    })
    return [...newListMarker]
  }

  public generateNodeLayer(listLayer: ILayer[]): Node {
    const nodeRoot = new Node()
    const listNode: Node[] = []
    listLayer.forEach((layer) => {
      const nodeLayer = new Node()
      nodeLayer.metadata = layer.info
      const childLayer: Node[] = []
      layer.spaces.forEach((space) => {
        const nodeSpace = new Node()
        nodeSpace.metadata = space.info
        const childSpace: Node[] = []
        space.scenes.forEach((scene) => {
          const nodeScene = new Node()
          nodeScene.metadata = scene.info
          nodeScene.type = 3
          childSpace.push(nodeScene)
        })
        nodeSpace.append(...childSpace)
        nodeSpace.type = 2
        nodeSpace.collapsed = space.collapsed
        childLayer.push(nodeSpace)
      })
      nodeLayer.append(...childLayer)
      nodeLayer.type = 1
      nodeLayer.collapsed = listLayer.length > 1 ? layer.collapsed : false
      listNode.push(nodeLayer)
    })
    nodeRoot.append(...listNode)
    nodeRoot.type = 0
    return nodeRoot
  }

  public createItem(node: Node, parent?: NodeTreeViewItem): NodeTreeViewItem {
    const item: NodeTreeViewItem = {
      key: node.key,
      parent,
      children: [],
      node,
    }
    if (!node.collapsed) {
      item.children = node.children.map((child) => this.createItem(child, item))
    }
    return item
  }

  public sortSpaceAndScene(
    listLayer: Array<IResLayerOfGetSpaceAndScene>,
    listSpace: Array<IResSpaceOfGetSpaceAndScene>,
    listScene: Array<IResSceneOfGetSpaceAndScene>,
    structure: structureSpaceAndScene,
    rootId?: IDefaultSceneSet
  ): ILayer[] {
    const newList = listLayer.map((layer) => {
      const layerFind = _.find(structure.layers, { id: layer.id })
      const listSpaceFind = layerFind?.spaces
      const listIdSpaceFind = listSpaceFind?.map((space) => space.id)
      const listSpaceSort = listSpace
        .filter((space) => {
          return listIdSpaceFind?.includes(space.id)
        })
        .map((space) => {
          const spaceFind = _.find(listSpaceFind, { id: space.id })
          const listSceneFind = spaceFind?.scenes
          const listIdSceneFind = listSceneFind?.map((scene) => scene.id)
          const listScneSort = listScene
            .filter((scene) => {
              return listIdSceneFind?.includes(scene.id)
            })
            .map((scene) => {
              return {
                info: scene,
              }
            })
          if (listIdSceneFind) {
            listScneSort.sort((a, b) => {
              return (
                listIdSceneFind.indexOf(a.info.id) -
                listIdSceneFind.indexOf(b.info.id)
              )
            })
          }
          return {
            info: space,
            collapsed:
              rootId?.spaceId && rootId.spaceId === space.id ? false : true,
            scenes: [...listScneSort],
          }
        })
      if (listIdSpaceFind) {
        listSpaceSort.sort((a, b) => {
          return (
            listIdSpaceFind.indexOf(a.info.id) -
            listIdSpaceFind.indexOf(b.info.id)
          )
        })
      }

      return {
        info: layer,
        collapsed:
          rootId?.layerId && rootId.layerId === layer.id ? false : true,
        spaces: [...listSpaceSort],
      }
    })
    newList.sort((a, b) => {
      return (
        structure.layers.map((layer) => layer.id).indexOf(a.info.id) -
        structure.layers.map((layer) => layer.id).indexOf(b.info.id)
      )
    })
    return [...newList]
  }

  public setCollapsedLayerOrSpace(
    listLayer: Array<ILayer>,
    id: number,
    type: number
  ) {
    const newListLayer = _.cloneDeep(listLayer)
    let isStop = false
    for (const layer of newListLayer) {
      if (isStop) {
        break
      }
      if (type === 2 && layer.spaces.length > 0) {
        for (const space of layer.spaces) {
          if (space.info.id === id) {
            space.collapsed = !space.collapsed
            if (layer.collapsed) {
              layer.collapsed = !layer.collapsed
            }
            isStop = true
            break
          }
        }
      }
      if (type === 1 && layer.info.id === id) {
        layer.collapsed = !layer.collapsed
        isStop = true
        break
      }
    }
    return newListLayer
  }

  public expandLayer(listLayer: Array<ILayer>, id: number) {
    const newListLayer = _.cloneDeep(listLayer)
    let isStop = false
    for (const layer of newListLayer) {
      if (isStop) {
        break
      }
      if (layer.info.id === id) {
        layer.collapsed = false
        isStop = true
        break
      }
    }
    return { newListLayer }
  }

  public expandSpace(listLayer: Array<ILayer>, id: number) {
    const newListLayer = _.cloneDeep(listLayer)
    let isStop = false
    for (const layer of newListLayer) {
      if (isStop) {
        break
      }
      for (const space of layer.spaces) {
        if (space.info.id === id) {
          space.collapsed = false
          isStop = true
          break
        }
      }
    }

    return { newListLayer }
  }

  public getStructureSpaceAndScene(listLayer: Array<ILayer>) {
    const newStructure = listLayer.map((layer) => {
      const listSpace = layer.spaces.map((space) => {
        const listScene = space.scenes.map((scene) => {
          return { id: scene.info.id }
        })
        return {
          id: space.info.id,
          scenes: listScene,
        }
      })
      return {
        id: layer.info.id,
        spaces: listSpace,
      }
    })
    return newStructure
  }

  public addSpaceToLayer(
    listLayer: Array<ILayer>,
    newSpace: ISpace,
    isAddHead: boolean,
    idLayer: number,
    idSpace: number
  ) {
    const newListLayer = _.cloneDeep(listLayer)
    let isStop = false
    if (isAddHead) {
      for (const layer of newListLayer) {
        if (isStop) {
          break
        }
        if (layer.info.id === idLayer) {
          layer.collapsed = false
          layer.spaces.unshift(newSpace)
          isStop = true
          break
        }
      }
    } else {
      for (const layer of newListLayer) {
        if (isStop) {
          break
        }
        if (layer.info.id === idLayer) {
          layer.collapsed = false
          const indexAdd =
            layer.spaces.findIndex((space) => space.info.id === idSpace) + 1
          layer.spaces.splice(indexAdd, 0, newSpace)
          isStop = true
          break
        }
      }
    }
    return newListLayer
  }

  public addSceneToSpace(
    listLayer: Array<ILayer>,
    newScene: IScene,
    isAddHead: boolean,
    idLayer: number,
    idSpace: number,
    idScene?: number
  ) {
    const newListLayer = _.cloneDeep(listLayer)
    let isStop = false
    if (isAddHead) {
      for (const layer of newListLayer) {
        if (isStop) {
          break
        }
        if (layer.info.id === idLayer) {
          layer.collapsed = false
          for (const space of layer.spaces) {
            if (space.info.id === idSpace) {
              space.collapsed = false
              space.scenes.unshift(newScene)
              break
            }
          }
          isStop = true
          break
        }
      }
    } else {
      for (const layer of newListLayer) {
        if (isStop) {
          break
        }
        if (layer.info.id === idLayer) {
          layer.collapsed = false
          for (const space of layer.spaces) {
            if (space.info.id === idSpace) {
              const indexAdd =
                space.scenes.findIndex((scene) => scene.info.id === idScene) + 1
              space.collapsed = false
              space.scenes.splice(indexAdd, 0, newScene)
              break
            }
          }
          isStop = true
          break
        }
      }
    }
    return newListLayer
  }

  public addSceneToTailSpace(
    listLayer: Array<ILayer>,
    newScene: IScene,
    idLayer: number,
    idSpace: number
  ) {
    const newListLayer = _.cloneDeep(listLayer)
    let isStop = false
    for (const layer of newListLayer) {
      if (isStop) {
        break
      }
      if (layer.info.id === idLayer) {
        for (const space of layer.spaces) {
          if (space.info.id === idSpace) {
            space.collapsed = false
            space.scenes.push(newScene)
            break
          }
        }
        isStop = true
        break
      }
    }

    return newListLayer
  }

  public getLayerSpaceFromSceneId(listLayer: Array<ILayer>, idScene: number) {
    let isStop = false
    let dataLayer: ILayer | null = null
    let dataSpace: ISpace | null = null
    for (const layer of listLayer) {
      if (isStop) {
        break
      }
      if (layer.spaces.length > 0) {
        for (const space of layer.spaces) {
          if (isStop) {
            break
          }
          if (space.scenes.length > 0) {
            for (const scene of space.scenes) {
              if (scene.info.id === idScene) {
                dataLayer = layer
                dataSpace = space
                isStop = true
                break
              }
            }
          }
        }
      }
    }
    return { layer: dataLayer, space: dataSpace }
  }

  public formatBytes(bytes: number, decimals: number, unit: string) {
    if (bytes === 0) return unit ? `0${unit}` : '0Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = unit
      ? sizes.indexOf(unit)
      : Math.floor(Math.log(bytes) / Math.log(k))
    if (i < 0) {
      return unit ? `0${unit}` : '0Bytes'
    }

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
  }

  public redirectToContact() {
    const globalStore = store.getState()
    const contactUrl =
      globalStore.profile.userProfile?.info.partnerContactUrl ||
      'https://hello.whereness.io/#inquire'
    window.open(contactUrl)
  }
}
export const helper = new Helper()
