import React, { ReactNode, useEffect, useRef } from 'react'
import classNames from 'classnames'

export type PopupPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'leftMediaPopup'

type PopupTriger = 'hover' | 'click'
interface PopupProps {
  open: boolean
  close: () => void
  children?: React.ReactNode
  triger?: PopupTriger
  content: ReactNode
  claassNamePopup?: string
  position?: PopupPlacement
  isButtonClick?: boolean
  setIsButtonClick?: (value: boolean) => void
}
const Popup: React.FC<PopupProps> = (props) => {
  const {
    children,
    content,
    open,
    close,
    claassNamePopup,
    position,
    isButtonClick,
    setIsButtonClick,
  } = props
  const ref = useRef(null)
  const handleClickOutside = (event: MouseEvent) => {
    if (
      !(
        ref.current &&
        !(ref.current as HTMLElement).contains(event.target as Node)
      )
    ) {
      return
    }

    if (isButtonClick && setIsButtonClick) {
      event.stopPropagation()
      setIsButtonClick(false)
    }

    close()
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, close, isButtonClick])
  const renderPlacement = () => {
    switch (position) {
      case 'right':
        return 'right-0 top-full'
      case 'left':
        return 'left-0 top-full'
      case 'bottom':
        return 'top-full'
      case 'top':
        return 'bottom-full'
      case 'leftMediaPopup':
        return 'left-25 top-10'
      default:
        return '-right-0 top-full'
    }
  }
  return (
    <div className="relative inline-flex">
      {children}
      <div
        className={classNames(
          'w-auto absolute z-10 bg-gray-700 py-2 shadow-dark__popup rounded-lg',
          renderPlacement(),
          { invisible: !open },
          claassNamePopup
        )}
        ref={ref}
      >
        {content}
      </div>
    </div>
  )
}

export default Popup
