export const TYPE_USER_PLAN = {
  REDUCERS: {
    SET_USER_PLAN_BY_USER_ID: 'setUserPlanByUserId',
  },
  ACTIONS: {
    GET_USER_PLAN_BY_USER_ID: 'userPlan/getUserPlanByUserId',
    CREATE_CHECKOUT_SESSION: 'userPlan/createCheckoutSession',
    CHANGE_SUBSCRIPTION: 'userPlan/changeSubscription',
    CANCEL_CMS_SUBSCRIPTION: 'userPlan/cancelCmsSubscription',
    GET_USER_PLAN_DISCOUNT: 'userPlan/getDiscountDetails',
    UPDATE_CREDIT_CARD_DETAILS: 'userPlan/updateCreditCard',
    GET_BILLING_HISTORY: 'userPlan/getBillingHistory',
    GET_USER_PLAN_PLAN_PRICE: 'userPlan/getUserPlan',
    UPDATE_PLAN_BY_CHECKOUT_SESSION: 'userPlan/updatePlanByCheckoutSession',
  },
}
