export const API_TYPE_USER_PLAN = {
  QUERY: {
    GET_USER_PLAN_BY_USER_ID: 'getUserPlanByUserId',
    GET_DISCOUNT_DETAILS: 'getPaymentDetails',
    GET_BILLING_HISTORY: 'getBillingHistory',
    GET_USER_PLAN_PLAN_PRICE: 'getUserPlanPrice',
  },
  MUTATION: {
    CHANGE_USER_SUBSCRIPTION: 'changeSubscription',
    CANCEL_CMS_SUBSCRIPTION: 'cancelSubscription',
    UPDATE_CREDIT_CARD_DETAILS: 'createOnlySetupSession',
  },
}
