import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { PATHNAME } from '@constants/common'
import { useAppDispatch } from '@stores/hook'
import { getUserPlanByUserIdAction } from '@stores/userPlan/userPlan.action'
import { openNotification } from '@utils/notification'
import { actions } from '@stores/profile/profile.reducer'
import { TYPE } from '@models/common'
import { TYPE_PROFILE } from '@stores/profile/profile.type'

function PaymentSuccess() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [userPlan, setUserPlan] = useState<Record<string, any> | null>(null)
  const { t } = useTranslation()
  const userId = localStorage.getItem('userId')
  const [paymentConfrim, setPaymentConfrim] = useState(false)

  useEffect(() => {
    const getUserPlanByUserId = async () => {
      if (!userId) {
        console.warn('User ID is not available in localStorage')
        return
      }
      try {
        const data = await dispatch(
          getUserPlanByUserIdAction(parseInt(userId as string))
        ).unwrap()
        setUserPlan(data)
      } catch (error) {
        console.error('Error fetching user plan:', error)
        openNotification({
          type: TYPE.ERROR,
          key: 'getUserPlanByUserId',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
    getUserPlanByUserId()
  }, [dispatch, userId])

  useEffect(() => {
    const verifySession = async () => {
      try {
        const previousPlan = JSON.parse(
          localStorage.getItem('userPlan') || 'null'
        )
        if (!previousPlan) {
          throw new Error('No previous plan found')
        }

        const previousPlanId = previousPlan.planId
        const previousPlanName = previousPlan.plan?.name
        const currentPlanId = userPlan?.planId
        const currentPlanName = userPlan?.plan?.name

        // Assuming `session` is defined or fetched as needed for verification
        if (
          previousPlanId !== currentPlanId ||
          previousPlanName !== currentPlanName
        ) {
          setPaymentConfrim(true)
          localStorage.removeItem('userPlan')
          localStorage.removeItem('userId')
          localStorage.removeItem('sessionId')
          navigate(PATHNAME.PROFILE_SETTING)
        } else {
          setPaymentConfrim(false)
          dispatch(actions[TYPE_PROFILE.REDUCERS.SET_TAB_SETTING](4))
          navigate(PATHNAME.PLAN)
        }
      } catch (error: any) {
        setPaymentConfrim(false)
        console.error('Payment verification failed:', error.message)
        navigate(PATHNAME.PLAN)
      }
    }

    verifySession()
  }, [dispatch, navigate, userPlan])

  return (
    <div>
      <h1>Payment {paymentConfrim ? 'Confirmed' : 'UnConfirmed'}!</h1>
      <p>
        {paymentConfrim
          ? 'Thank you for your payment. Your transaction was successful.'
          : 'Unfortunately, your payment could not be confirmed. Please try again or contact support.'}
      </p>
    </div>
  )
}

export default PaymentSuccess
