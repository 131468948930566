import { gql } from '@apollo/client'

export const GET_LIST_CMS_PRODUCT = gql`
  query listCmsProduct {
    listCmsProduct {
      message
      statusCode
      data {
        monthlyPriceId
        yearlyPriceId
        planName
        monthlyPrice
        yearlyPrice
        currency
        remarks
        metadata
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_CURRENT_PLAN_AND_CARD_INFO = gql`
  query getCurrentPlanAndCardInfo {
    getCurrentPlanAndCardInfo {
      message
      statusCode
      data {
        product {
          name
          id
        }
        cardInfo {
          brand
          lastFour
        }
      }
      error {
        message
        errorCode
      }
    }
  }
`

export const GET_CMS_PRODUCT_BILLINGS = gql`
  query getCmsProductBillings($organizationId: String!, $pager: PagerInput) {
    getCmsProductBillings(organizationId: $organizationId, pager: $pager) {
      statusCode
      message
      data {
        billings {
          name {
            ja
            en
          }
          organizationStorage
          publishStorage
          views
          price
          status
          billingUrl
          createdAt
        }
      }
      error {
        message
        errorCode
      }
    }
  }
`
