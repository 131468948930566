import { createAsyncThunk } from '@reduxjs/toolkit'
import { graphqlApi } from '../../api/graphql'
import {
  CREATE_MEDIA_FOLDER,
  CREATE_PROJECT,
  DELETE_MEDIA_FILE,
  DELETE_MEDIA_FOLDER,
  DELETE_PROJECT,
  GET_MEDIA_FOLDERS,
  UPDATE_MEDIA_FILE,
  UPDATE_MEDIA_FOLDER,
  UPDATE_MEDIA_THUMBNAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_PUBLISH,
  UPLOAD_MEDIA_FILE,
} from '../../graphql/project/mutation'
import {
  GET_LIST_MEDIA,
  GET_LIST_PROJECT,
  GET_PROJECT_INFO,
  GET_PROJECT_PULISH,
  AVAILABLE_STREAM,
  GET_LIST_PROJECT_STORAGE,
} from '../../graphql/project/query'
import { API_TYPE_PROJECT } from '../../graphql/project/type'
import { GET_RESOURCE_AND_PROJECT } from '../../graphql/sceneSetting/query'
import { API_TYPE_SCENE_SETTING } from '../../graphql/sceneSetting/type'
import {
  IRequestCreateMediaFolder,
  IRequestDeleteMediaFolder,
  IRequestGetMediaFolders,
  IRequestUpdateMedia,
  IRequestUpdateMediaFolder,
  IRequestUpdateMediaThumbnail,
  IRequestUploadMedia,
  TYPE_MEDIA,
} from '../../models/media'
import {
  IRequestCreateProject,
  IRequestGetProjectPublish,
  IRequestListProject,
  IRequestUpdateProject,
  IRequestUpdateProjectPublish,
} from '../../models/project'
import { IRequestGetSpaceAndScene } from '../../models/sceneSetting'
import { TYPE_PROJECT } from './project.type'

export const createProjectAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.CREATE_PROJECT,
  async (data: IRequestCreateProject) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_PROJECT,
      API_TYPE_PROJECT.MUTATION.CREATE_PROJECT,
      data
    )
    return result
  }
)

export const getListProjectAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_LIST_PROJECT,
  async (data: IRequestListProject) => {
    const result = await graphqlApi.queryRequest(
      GET_LIST_PROJECT,
      API_TYPE_PROJECT.QUERY.GET_LIST_PROJECT,
      data
    )
    return result
  }
)

export const getListProjectStorageAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_LIST_PROJECT_STORAGE,
  async (data: IRequestListProject) => {
    const result = await graphqlApi.queryRequest(
      GET_LIST_PROJECT_STORAGE,
      API_TYPE_PROJECT.QUERY.GET_LIST_PROJECT,
      data
    )
    return result
  }
)

export const getInfoProjectAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_INFO_PROJECT,
  async (data: { projectId: number }) => {
    const result = await graphqlApi.queryRequest(
      GET_PROJECT_INFO,
      API_TYPE_PROJECT.QUERY.GET_INFO_PROJECT,
      data
    )
    return result
  }
)

export const updateProjectAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.UPDATE_PROJECT,
  async (data: {
    projectId: number
    updateProjectInput: IRequestUpdateProject
  }) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_PROJECT,
      API_TYPE_PROJECT.MUTATION.UPDATE_PROJECT,
      data
    )
    return result
  }
)

export const deleteProjectAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.DELETE_PROJECT,
  async (data: { projectId: number; organizationId: string }) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_PROJECT,
      API_TYPE_PROJECT.MUTATION.DELETE_PROJECT,
      data
    )
    return result
  }
)

export const uploadMediaAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.UPLOAD_MEDIA,
  async (data: IRequestUploadMedia) => {
    const result = await graphqlApi.mutationRequest(
      UPLOAD_MEDIA_FILE,
      API_TYPE_PROJECT.MUTATION.UPLOAD_MEDIA,
      data
    )
    return result
  }
)

export const updateMediaThumbnailAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.UPDATE_MEDIA_THUMBNAIL,
  async (data: IRequestUpdateMediaThumbnail) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_MEDIA_THUMBNAIL,
      API_TYPE_PROJECT.MUTATION.UPDATE_MEDIA_THUMBNAIL,
      data
    )
    return result
  }
)

export const getMediaAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_MEDIA_LIST,
  async (
    input: {
      projectId?: number
      organizationId?: string
      folderId?: number
      mediaType?: TYPE_MEDIA[]
      skip?: number
    },
    { rejectWithValue }
  ) => {
    if (!input.mediaType) {
      input.mediaType = [
        TYPE_MEDIA.VIDEO360,
        TYPE_MEDIA.IMAGE360,
        TYPE_MEDIA.VIDEO,
        TYPE_MEDIA.IMAGE,
        TYPE_MEDIA.PDF,
        TYPE_MEDIA.AUDIO,
        TYPE_MEDIA.GAUSSIAN_SPLATTING,
      ]
    }

    const result = await graphqlApi.queryRequest(
      GET_LIST_MEDIA,
      API_TYPE_PROJECT.QUERY.GET_MEDIA_LIST,
      { input }
    )
    if (result.error) {
      console.log(result.error)
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const deleteMediaAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.DELETE_MEDIA,
  async (
    input: {
      projectId: number
      organizationId: string
      mediaType: string
      resourceId: number
    },
    { rejectWithValue }
  ) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_MEDIA_FILE,
      API_TYPE_PROJECT.MUTATION.DELETE_MEDIA,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const updateMediaAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.UPDATE_MEDIA,
  async (input: IRequestUpdateMedia, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_MEDIA_FILE,
      API_TYPE_PROJECT.MUTATION.UPDATE_MEDIA,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const getProjectInfoAndListSpaceScene = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_INFO_AND_LIST_SPACE_SCENE,
  async (input: IRequestGetSpaceAndScene) => {
    const result = await graphqlApi.queryRequest(
      GET_RESOURCE_AND_PROJECT,
      [
        API_TYPE_PROJECT.QUERY.GET_INFO_PROJECT,
        API_TYPE_SCENE_SETTING.QUERY.GET_SPACE_AND_SCENE,
      ],
      { input, projectId: input.projectId }
    )
    return result
  }
)

export const getMediaFoldersAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_MEDIA_FOLDERS,
  async (input: IRequestGetMediaFolders, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      GET_MEDIA_FOLDERS,
      API_TYPE_PROJECT.MUTATION.GET_MEDIA_FOLDERS,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const createMediaFolderAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.CREATE_MEDIA_FOLDER,
  async (input: IRequestCreateMediaFolder, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_MEDIA_FOLDER,
      API_TYPE_PROJECT.MUTATION.CREATE_MEDIA_FOLDER,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const deleteMediaFolderAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.DELETE_MEDIA_FOLDER,
  async (input: IRequestDeleteMediaFolder, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_MEDIA_FOLDER,
      API_TYPE_PROJECT.MUTATION.DELETE_MEDIA_FOLDER,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const updateMediaFolderAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.UPDATE_MEDIA_FOLDER,
  async (input: IRequestUpdateMediaFolder, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_MEDIA_FOLDER,
      API_TYPE_PROJECT.MUTATION.UPDATE_MEDIA_FOLDER,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const getProjectPublishAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.GET_PROJECT_PUBLISH,
  async (input: IRequestGetProjectPublish) => {
    const result = await graphqlApi.queryRequest(
      GET_PROJECT_PULISH,
      API_TYPE_PROJECT.QUERY.GET_PROJECT_PUBLISH,
      { input }
    )
    return result
  }
)

export const updateProjectPublishAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.UPDATE_PROJECT_PUBLISH,
  async (input: IRequestUpdateProjectPublish) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_PROJECT_PUBLISH,
      API_TYPE_PROJECT.MUTATION.UPDATE_PROJECT_PUBLISH,
      { input }
    )
    return result
  }
)

export const availableStreamAction = createAsyncThunk(
  TYPE_PROJECT.ACTIONS.AVAILABLE_STREAM,
  async () => {
    const result = await graphqlApi.queryRequest(
      AVAILABLE_STREAM,
      API_TYPE_PROJECT.QUERY.AVAILABLE_STREAM
    )
    return result
  }
)
