import React from 'react'
import { IProject } from '@models/project'
import moment from 'moment'
import { LANGUAGE } from '@constants/common'
import { helper } from '@utils/helper/common'
import { PROJECT_SIZE_TYPE } from '@constants/plan'
import i18next from 'i18next'

interface IPropsType {
  project: IProject
}
const ThumbBottomInfo: React.FC<IPropsType> = (props) => {
  const lang = i18next.language
  const { project } = props
  return (
    <div className="min-w-0 flex-initial">
      <h1 className="font-semibold text-sm text-white__op-900 mb-1 truncate max-w-[12rem]">
        {project.projectName}
      </h1>
      <div className="w-full min-w-0 inline-flex flex-wrap text-xs leading-5 text-white__op-500 font-lato break-words">
        <div className="whitespace-normal w-full break-all">
          {moment(project.lastEditedAt).format(
            lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'YYYY/MM/DD'
          )}
          {', '}
          <span>
            {helper.formatBytes(
              Number(project.currentProjectStorage),
              2,
              PROJECT_SIZE_TYPE.GB
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ThumbBottomInfo)
