import { API_TYPE_PAYMENT } from './../../graphql/paymentCard/type'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GET_USER_PLAN_BY_USER_ID,
  CHANGE_USER_SUBSCRIPTION,
  CANCEL_CMS_SUBSCRIPTION,
  GET_DISCOUNT_DETAILS,
  UPDATE_CREDIT_CARD_DETAILS,
  GET_BILLING_HISTORY,
  GET_USER_PLAN_PLAN_PRICE,
  UPDATE_PLAN_BY_CHECKOUT_SESSION,
} from '../../graphql/userPlan/query'
import { API_TYPE_USER_PLAN } from '../../graphql/userPlan/type'
import { graphqlApi } from '../../api/graphql'
import { TYPE_USER_PLAN } from './userPlan.type'
import { CREATE_CHECKOUT_SESSION } from '../../graphql/paymentCard/mutation'

export const getUserPlanByUserIdAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.GET_USER_PLAN_BY_USER_ID,
  async (userId: number) => {
    const result = await graphqlApi.queryRequest(
      GET_USER_PLAN_BY_USER_ID,
      API_TYPE_USER_PLAN.QUERY.GET_USER_PLAN_BY_USER_ID,
      { userId }
    )
    return result
  }
)

export const createCheckoutSessionAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.CREATE_CHECKOUT_SESSION,
  async ({
    priceId,
    successUrl,
    cancelUrl,
  }: {
    priceId: string
    successUrl: string
    cancelUrl: string
  }) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_CHECKOUT_SESSION,
      API_TYPE_PAYMENT.MUTATION.CREATE_CHECKOUT_SESSION,
      {
        input: {
          priceId,
          successUrl,
          cancelUrl,
        },
      }
    )
    if (result.data.sessionId) {
      localStorage.setItem('sessionId', result.data.sessionId)
    }
    return result
  }
)

export const changeSubscriptionAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.CHANGE_SUBSCRIPTION,
  async ({ priceId }: { priceId: string }) => {
    const result = await graphqlApi.queryRequest(
      CHANGE_USER_SUBSCRIPTION,
      API_TYPE_USER_PLAN.MUTATION.CHANGE_USER_SUBSCRIPTION,
      {
        input: {
          priceId,
        },
      }
    )
    return result
  }
)

export const cancelCmsSubscriptionAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.CANCEL_CMS_SUBSCRIPTION,
  async () => {
    const result = await graphqlApi.queryRequest(
      CANCEL_CMS_SUBSCRIPTION,
      API_TYPE_USER_PLAN.MUTATION.CANCEL_CMS_SUBSCRIPTION
    )
    return result
  }
)

export const getDiscountDetailsAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.GET_USER_PLAN_DISCOUNT,
  async (priceId: string) => {
    const result = await graphqlApi.queryRequest(
      GET_DISCOUNT_DETAILS,
      API_TYPE_USER_PLAN.QUERY.GET_DISCOUNT_DETAILS,
      { priceId }
    )
    return result
  }
)

export const updateCreditCardDetailsAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.UPDATE_CREDIT_CARD_DETAILS,
  async (input: { successUrl: string; cancelUrl: string }) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_CREDIT_CARD_DETAILS,
      API_TYPE_USER_PLAN.MUTATION.UPDATE_CREDIT_CARD_DETAILS,
      { input }
    )
    return result
  }
)

export const getBillingHistory = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.GET_BILLING_HISTORY,
  async () => {
    const result = await graphqlApi.mutationRequest(
      GET_BILLING_HISTORY,
      API_TYPE_USER_PLAN.QUERY.GET_BILLING_HISTORY
    )
    return result
  }
)

export const getUserPlanPriceAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.GET_USER_PLAN_PLAN_PRICE,
  async () => {
    const result = await graphqlApi.mutationRequest(
      GET_USER_PLAN_PLAN_PRICE,
      API_TYPE_USER_PLAN.QUERY.GET_USER_PLAN_PLAN_PRICE
    )
    return result
  }
)

export const updatePlanByCheckoutSessionAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.UPDATE_PLAN_BY_CHECKOUT_SESSION,
  async (sessionId: string) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_PLAN_BY_CHECKOUT_SESSION,
      API_TYPE_USER_PLAN.QUERY.GET_USER_PLAN_BY_USER_ID,
      { checkoutSessionId: sessionId }
    )
    return result
  }
)
