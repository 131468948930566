//enum
export const enum TYPE_MEDIA_TABS {
  VIDEO360 = '360°',
  IMAGES = 'images',
  VIDEOS = 'videos',
  FILES = 'files',
  AUDIO = 'audio',
}

export const enum TYPE_MEDIA {
  VIDEO360 = 'video360',
  IMAGE360 = 'image360',
  IMAGE = 'image',
  VIDEO = 'video',
  PDF = 'pdf',
  AUDIO = 'audio',
  MAP = 'map',
  GAUSSIAN_SPLATTING = 'gs',
}

export const enum TYPE_MEDIA_360 {
  VIDEO360 = 'video360',
  IMAGE360 = 'image360',
  IMAGE = 'image',
  VIDEO = 'video',
  MAP = 'map',
}

export const enum TYPE_CONTEXT {
  IMAGE = 'image',
  VIDEO = 'video',
  GS = 'gs',
}

export const enum TYPE_FILE {
  IMAGES = 'image/png, image/jpg, image/jpeg, image/*',
  VIDEOS = 'video/mp4',
  FILES = 'application/pdf',
  AUDIO = '.mp3, .wav, .m4a, audio/*, audio/mpeg, audio/wav, audio/x-wav, audio/x-m4a, audio/mp4',
  VIDEOS360 = 'video/mp4, image/png, image/jpg, image/jpeg, image/*',
  IMAGE360 = 'image/png, image/jpg, image/jpeg, image/*',
  GAUSSIAN_SPLATTING = '.ply, .splat, .ksplat',
}

export const enum TYPE_OF_TABLE_MEDIA {
  URL = 'url',
  NAME = 'name',
  UPLOADEDBY = 'uploadedBy',
  CREATEDAT = 'createdAt',
  SIZE = 'size',
}

export const enum MODE_OF_MEDIA_LIB_POPUP {
  OFF,
  FILE_3D,
  BGM,
  IMAGE,
  VIDEO,
  OTHER_FILE_3D,
}

export enum CONVERTED_STATUS {
  'IN-PROGRESS' = 'IN-PROGRESS',
  'FAILED' = 'FAILED',
  'SUCCESS' = 'SUCCESS',
}

export const enum TYPE_STATUS_UPLOAD {
  WAITTING = 'WAITTING',
  UPLOADING = 'UPLOADING',
  CONVERTING = 'CONVERTING',
  COMPLETE = 'COMPLETE',
}

export const enum TYPE_MEDIA_LIB {
  ASSET,
  POPUP,
}

//request
export interface MediaFileUpload {
  name: string
  mediaType: string
  folderId?: number | null
  key: string
  bucket: string
}

export interface MediaThumbnailUpdate {
  resourceId: number
  mediaType: string
  key: string
  bucket: string
}

export interface IRequestUploadMedia {
  projectId: number
  fileInput: MediaFileUpload
}

export interface IRequestUpdateMediaThumbnail {
  projectId: number
  fileInput: MediaThumbnailUpdate
}

export interface IRequestDeleteMedia {
  organizationId: string
  projectId: number
  mediaType: string
  resourceId: number
}

export interface IRequestUpdateMedia {
  projectId: number
  mediaType: string
  resourceId: number
  name?: string
  folderId?: number | null
}

export interface IRequestGetMediaFolders {
  projectId: number
  folderId?: number
}

export interface IRequestCreateMediaFolder {
  projectId: number
  name: string
}

export interface IRequestDeleteMediaFolder {
  projectId: number
  folderId: number
}

export interface IRequestUpdateMediaFolder {
  projectId: number
  folderId: number
  name: string
}

//response
export interface IMedia {
  key: string
  resourceId?: number
  uploadedBy?: {
    userId: number
    fullName: string
    avatar: string
  }
  mediaType: TYPE_MEDIA
  url?: string
  thumbnail?: string
  name: string
  size?: string
  createdAt?: Date
  status: TYPE_STATUS_UPLOAD
  progress?: number
}

export interface IMediaFolder {
  id: number
  name: string
  children: IMedia[]
}

export interface ISelectedMedia {
  folderId?: number | null
  key: string
  resourceId?: number
  mediaType?: TYPE_MEDIA
}

//other
export interface IProcessUploadFile {
  session: string
  listDataFile: Array<IDataFileInProcess>
}

export interface IUploadFile {
  session: string
  dataFile: {
    fileName: string
    lastModified: number
    status: TYPE_STATUS_UPLOAD
  }
}

export interface IDataFileInProcess {
  fileName: string
  fileType: string
  status: TYPE_STATUS_UPLOAD
  lastModified: number
}

export interface IUploadingMediasGlobal {
  projectId: number
  medias: IMedia[]
  folderId: number | null
}

export interface IDragOverFolder {
  type: TYPE_MEDIA_LIB
  folderId: number | null
}

export interface IMediaProcess {
  organizationId: string
  projectId: number
  layerId: number
  spaceId: number
  sceneId: number
  folderId: number | null
  status: TYPE_STATUS_UPLOAD
  progress: number
  key: string
  resourceId: number
  contextUrl: IContextUrl
  thumbnail: string
  name: string
  mediaType: TYPE_MEDIA
}

export interface IContextUrl {
  vp9: string
  h265Dash: string
  h265Hls: string
}
