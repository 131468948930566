import React from 'react'
import { IProject } from '@models/project'
import { ProjectPublishStatusName } from '@models/organization'
import { useTranslation } from 'react-i18next'

interface IPropsType {
  project: IProject
}
const ThumbBottomActions: React.FC<IPropsType> = (props) => {
  const { project } = props
  const { t } = useTranslation()
  return (
    <div className="flex justify-center items-center">
      {project.publishStatus === ProjectPublishStatusName.PUBLISH && (
        <div className="px-3 py-[2px] text-xs leading-5 bg-white__op-50 rounded-full whitespace-nowrap text-white__op-600 visible group-hover:invisible font-lato">
          {t('common.public')}
        </div>
      )}
      {project.publishStatus === ProjectPublishStatusName.PASSWORD && (
        <div className="px-3 py-[2px] text-xs leading-5 bg-white__op-50 rounded-full whitespace-nowrap text-white__op-600 visible group-hover:invisible font-lato">
          {t('common.protected')}
        </div>
      )}
    </div>
  )
}

export default React.memo(ThumbBottomActions)
