import React from 'react'
import { IProject } from '@models/project'
import Icon from '../common/Icon'
import IconMore from '@assets/icons/common/more-icon.svg'
import classNames from 'classnames'

interface IPropsType {
  project: IProject
  index: number
  projectEdit: IProject | null
  handleOpenMoreSettingProject: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    indexOpen: number,
    project: IProject
  ) => void
}
const ThumbBottomActions: React.FC<IPropsType> = (props) => {
  const { project, index, handleOpenMoreSettingProject, projectEdit } = props
  return (
    <div className="flex justify-center items-center">
      <div id={`arrow${index}`}>
        <Icon.ButtonIcon
          icon={<img src={IconMore} alt="" />}
          size="xs"
          onClick={(e) => handleOpenMoreSettingProject(e, index, project)}
          customClass={classNames('focus:bg-transparent', {
            '!bg-blue-700': projectEdit?.projectId === project.projectId,
            'hover:bg-white__op-50':
              projectEdit?.projectId !== project.projectId,
          })}
        />
      </div>
    </div>
  )
}

export default React.memo(ThumbBottomActions)
