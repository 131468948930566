import { createSlice } from '@reduxjs/toolkit'
import { TYPE_USER_PLAN } from './userPlan.type'
import { IResponseFeatures } from '@models/userPlan'
import {
  getUserPlanByUserIdAction,
  createCheckoutSessionAction,
} from './userPlan.action'

export interface UserPlanState {
  features: IResponseFeatures[] | null
  createProject: number | null
  sessionId: string | null
}
const initialState: UserPlanState = {
  features: [],
  createProject: null,
  sessionId: null,
}

export const userPlanReducer = createSlice({
  name: 'userPlan',
  initialState,
  reducers: {
    [TYPE_USER_PLAN.REDUCERS.SET_USER_PLAN_BY_USER_ID]: (state, action) => {
      state.features = action.payload.plan?.planFeatures?.map(
        (planFeature: { id: string; feature: IResponseFeatures }) => {
          return planFeature.feature
        }
      )
      state.createProject =
        action.payload.plan?.metadata?.limited?.createProject
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserPlanByUserIdAction.fulfilled,
      (state, { payload }) => {
        const data = payload
        if (data) {
          return {
            ...state,
            features: data?.plan?.planFeatures?.map(
              (planFeature: { id: string; feature: IResponseFeatures }) => {
                return planFeature.feature
              }
            ),
            createProject: data?.plan?.metadata?.limited?.createProject,
          }
        }
        return state
      }
    )
    builder.addCase(
      createCheckoutSessionAction.fulfilled,
      (state, { payload }) => {
        state.sessionId = payload.data.sessionId
      }
    )
  },
})

export const { actions } = userPlanReducer
export default userPlanReducer.reducer
