import classNames from 'classnames'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import Icon from './Icon'

type ButtonTypeColor = 'transparent' | 'ghost' | 'solid' | 'secondary'
interface PropsPopupButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode
  title?: string
  color?: ButtonTypeColor
  onClick?: React.MouseEventHandler<HTMLElement>
  children?: React.ReactNode
  classNameContainer?: string
  isTransition?: boolean
}
const MenuItem: React.FC<PropsPopupButton> = ({
  icon,
  title,
  onClick,
  children,
  className,
  ...rest
}): React.ReactElement => {
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    ;(
      onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    )?.(e)
  }
  return (
    <button
      className={classNames(
        'text-sm flex items-center group px-4 py-1.5 hover:bg-white__op-50 active:bg-white__op-100 focus-visible:bg-blue-700 focus-visible:outline-blue-700 disabled:!text-white__op-300',
        className
      )}
      onClick={handleClick}
      {...rest}
    >
      {icon && <Icon.ActionIcon icon={icon} customClass="mr-3" />}
      {children ? (
        children
      ) : (
        <span className="text-left text-white__op-900 font-normal text-sm truncate group-disabled:text-white__op-300">
          {title}
        </span>
      )}
    </button>
  )
}

const Normal: React.FC<PropsPopupButton> = ({
  icon,
  title,
  color = 'solid',
  onClick,
  className,
  classNameContainer,
  isTransition = false,
  children,
  ...rest
}): React.ReactElement => {
  const genColorButton = () => {
    switch (color) {
      case 'secondary':
        return 'bg-white text-blue-500 group-hover:text-blue-300 group-active:text-blue-300 group-focus:text-blue-300 group-disabled:text-blue-300 group-disabled:text-gray-300'
      case 'ghost':
        return 'bg-white__op-50 text-blue-300 group-hover:bg-white__op-100 group-active:bg-white__op-200 group-focus:outline-blue-500'
      case 'solid':
        return 'bg-blue-500 text-white__op-900 group-hover:bg-blue-450 group-active:bg-blue-400 group-focus:!outline-blue-400 group-disabled:bg-white__op-50 group-disabled:text-white__op-300'
      case 'transparent':
        return 'text-blue-300 bg-transparent group-hover:bg-white__op-50 group-active:bg-white__op-100 group-active:outline-transparent group-focus:outline-blue-500 group-target:!bg-blue-500 group-disabled:text-white__op-300'
      default:
        break
    }
  }
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    ;(
      onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    )?.(e)
  }
  return (
    <button
      className={classNames('w-full py-1.5 relative group', classNameContainer)}
      onClick={handleClick}
      {...rest}
    >
      <div
        className={classNames(
          'w-full flex items-center justify-center rounded-2xl px-4 py-2 truncate outline outline-2 outline-transparent text-sm',
          { 'transition-all duration-0.12 ease-0.3': isTransition },
          genColorButton(),
          className
        )}
      >
        {icon && <Icon.ActionIcon icon={icon} customClass="mr-2" />}
        <span>{title}</span>
        {children}
      </div>
    </button>
  )
}

const BlueBg: React.FC<PropsPopupButton> = ({
  icon,
  title,
  color = 'solid',
  onClick,
  className,
  classNameContainer,
  isTransition = false,
  children,
  ...rest
}): React.ReactElement => {
  const genColorButton = () => {
    switch (color) {
      case 'ghost':
        return 'bg-white__op-50 text-blue-200 font-semibold group-hover:bg-white__op-100 group-active:bg-white__op-200 group-focus:outline-blue-500 group-disabled:!bg-white__op-50 group-disabled:text-white__op-300'
      case 'solid':
        return 'bg-blue-50 text-blue-600 font-semibold group-hover:bg-blue-100 group-active:bg-blue-200 group-focus:outline-blue-500 group-disabled:!bg-blue-50 group-disabled:opacity-30'
      default:
        break
    }
  }
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    ;(
      onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    )?.(e)
  }
  return (
    <button
      className={classNames('w-full py-1.5 relative group', classNameContainer)}
      onClick={handleClick}
      {...rest}
    >
      <div
        className={classNames(
          'w-full flex items-center justify-center rounded-2xl px-4 py-2 truncate outline outline-2 outline-transparent text-sm',
          { 'transition-all duration-0.12 ease-0.3': isTransition },
          genColorButton(),
          className
        )}
      >
        {icon && <Icon.ActionIcon icon={icon} customClass="mr-2" />}
        <span>{title}</span>
        {children}
      </div>
    </button>
  )
}

const Button = { MenuItem, Normal, BlueBg }
export default Button
