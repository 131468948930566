import { gql } from '@apollo/client'

export const CREATE_SPACE = gql`
  mutation createSpace($createSpaceInput: CreateSpaceInput!) {
    createSpace(createSpaceInput: $createSpaceInput) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        spaceId
        title
        layerId
        isLayer
        version
      }
    }
  }
`
export const CREATE_SCENE = gql`
  mutation addScene($input: AddSceneInput!) {
    addScene(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        spaceId
        sceneId
        title
        thumbnailUrl
        version
      }
    }
  }
`

export const UPDATE_SPACE = gql`
  mutation updateSpace($updateSpaceInput: UpdateSpaceInput!) {
    updateSpace(updateSpaceInput: $updateSpaceInput) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        id
        layerId
        title
        isLayer
        thumbnailUrl
        version
      }
    }
  }
`

export const DELETE_SPACE = gql`
  mutation deleteSpaces($input: DeleteSpaceInput!) {
    deleteSpaces(input: $input) {
      statusCode
      message
      data {
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const DELETE_SCENE = gql`
  mutation deleteScene($input: DeleteSceneInput!) {
    deleteScene(input: $input) {
      statusCode
      message
      data {
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const UPDATE_SCENE = gql`
  mutation updateScene($input: UpdateSceneInput!) {
    updateScene(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        sceneId
        spaceId
        bgmUrl
        bgmName
        contextType
        contextName
        contextUrl
        contextThumbnailUrl
        contextFormat
        description
        title
        metadata {
          isHide
        }
        information
        thumbnailUrl
        version
        initialCameraState
        initialScenePreviewImageUrl
      }
    }
  }
`

export const SET_MARKER = gql`
  mutation setMarkers($input: SetMarkersInput) {
    setMarkers(input: $input) {
      message
      statusCode
      data {
        id
        coordinates {
          xAxis
          yAxis
          zAxis
        }
        linkedSceneId
        linkedSceneName
        name
        markerMesh
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const REMOVE_MARKER = gql`
  mutation removeMarker($input: RemoveMarkerInput) {
    removeMarker(input: $input) {
      statusCode
      message
      data {
        version
      }
      error {
        message
        errorCode
      }
    }
  }
`

export const SET_INFO_TAG = gql`
  mutation setInfoTags($input: SetInfoTagInput!) {
    setInfoTags(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        id
        coordinates {
          xAxis
          yAxis
          zAxis
        }
        tagType
        color
        description
        langCode
        media {
          mediaType
          mediaUrl
          mediaName
          mediaThumbnailUrl
        }
        size
        name
        title
        credit
        version
      }
    }
  }
`

export const REMOVE_INFO_TAG = gql`
  mutation removeInfoTag($input: RemoveInfoTagInput!) {
    removeInfoTag(input: $input) {
      statusCode
      message
      data {
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const ROLLBACK_VERSION = gql`
  mutation rollbackVersion($input: RollbackVersionInput!) {
    rollbackVersion(input: $input) {
      message
      statusCode
      data {
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`
