import { useTranslation } from 'react-i18next'
import {
  getUserPlanByUserIdAction,
  updateCreditCardDetailsAction,
  getBillingHistory,
  getUserPlanPriceAction,
  updatePlanByCheckoutSessionAction,
} from '@stores/userPlan/userPlan.action'
import { getCurrentPlanAndCardInfoAction } from '@stores/organization/organization.action'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PATHNAME } from '@constants/common'
import IconEdit from '@assets/icons/common/edit-icon.svg'
import SpinComponent from '@components/common/SpinComponent'
import { loadStripe } from '@stripe/stripe-js'
import BillingHistoryTable from './BillingHistoryTable'
import Button from '@components/common/Button'

function PlanBillingComponent() {
  const { t, i18n } = useTranslation()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY as string)
  const [userPlan, setUserPlan] = useState<any>(null)
  const [userPlanPrice, setUserPlanPrice] = useState<any>(null)
  const [userCards, setUserCards] = useState<any>(null)
  const [billingHistory, setBillingHistory] = useState<any>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const session_id = searchParams.get('session_id')

  useEffect(() => {
    const getUserPlanByUserId = async () => {
      if (typeof user?.userId === 'undefined') {
        return
      }
      try {
        if (session_id) {
          await dispatch(updatePlanByCheckoutSessionAction(session_id))
          navigate(PATHNAME.PROFILE_SETTING) // only changes url, without reload
        }

        // Fetch `getUserPlanByUserIdAction` and `getUserPlanPriceAction` in parallel
        const [userPlanData, userPlanPriceData] = await Promise.all([
          dispatch(getUserPlanByUserIdAction(user?.userId)).unwrap(),
          dispatch(getUserPlanPriceAction()).unwrap(),
        ])

        // Set data for both actions
        setUserPlan(userPlanData)

        if (userPlanPriceData.statusCode === '200') {
          setUserPlanPrice(userPlanPriceData.data)
        }

        // Fetch other data sequentially
        const cardInfoData = await dispatch(
          getCurrentPlanAndCardInfoAction({
            organizationId: user?.organizationId,
            isCurrentPlanOnly: true,
            isGetCardOnly: true,
          })
        ).unwrap()
        setUserCards(cardInfoData)

        const billingHistoryData = await dispatch(getBillingHistory()).unwrap()
        if (billingHistoryData.statusCode === '200') {
          setBillingHistory(billingHistoryData.data)
        }
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getUserPlanByUserId',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      } finally {
      }
    }

    getUserPlanByUserId()
  }, [dispatch, user])

  const formatExpirationDate = (dateString: string): string => {
    if (!dateString) return '' // Return null for invalid or empty inputs

    const timestamp =
      dateString.toString().length === 10
        ? Number(dateString) * 1000
        : Number(dateString)

    const date = new Date(timestamp)

    if (isNaN(date.getTime())) {
      console.error(`Invalid date input:`, dateString)
      return 'Invalid Date'
    }
    const locale = i18n?.language || 'en-US'
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
    }
    const formattedDate = new Intl.DateTimeFormat(locale, options).format(date)
    return formattedDate
  }

  const calculateAndFormatPrice = (
    price: string,
    billingPeriodType: string,
    currency: any
  ): string => {
    const language = i18n.language

    // Validate required parameters
    if (!price || !billingPeriodType || !currency || !language) {
      console.error('Missing required parameters.')
      return '' // Default to empty string if any parameter is missing
    }
    // Parse price into a number for calculations
    let adjustedPrice = parseFloat(price)
    if (isNaN(adjustedPrice)) {
      console.error('Invalid price value.')
      return ''
    }

    // Adjust price based on currency
    if (currency.toUpperCase() === 'USD') {
      adjustedPrice /= 100
    }

    // Adjust price for billing period
    if (billingPeriodType === 'byYear') {
      adjustedPrice /= 12
    }

    // Format price to avoid unnecessary decimals
    const formattedPrice = Number.isInteger(adjustedPrice)
      ? adjustedPrice.toString()
      : adjustedPrice.toFixed(2)

    // Format price based on language and currency
    switch (currency.toUpperCase()) {
      case 'USD':
        return language === 'ja'
          ? `${formattedPrice} 米ドル/月` // USD in Japanese
          : `${formattedPrice} USD/month` // USD in English
      case 'JPY':
        return language === 'ja'
          ? `${formattedPrice} 円/月` // JPY in Japanese
          : `${formattedPrice} JPY/month` // JPY in English;
      default:
        // Default fallback for unsupported currencies
        return `${formattedPrice} ${currency}/month`
    }
  }

  const handleEditCardClick = async () => {
    try {
      const stripe = await stripePromise
      if (!stripe) {
        console.error('Stripe failed to load')
        return
      }

      const cancelUrl = `${window.location.origin}${PATHNAME.PROFILE_SETTING}`
      const successUrl = `${window.location.origin}${PATHNAME.PROFILE_SETTING}?session_id={CHECKOUT_SESSION_ID}`

      const response = await dispatch(
        updateCreditCardDetailsAction({ successUrl, cancelUrl })
      ).unwrap()

      if (!response?.data?.sessionId) {
        console.error('Failed to get session ID')
        return
      }

      await stripe.redirectToCheckout({ sessionId: response.data.sessionId })
    } catch (error) {
      console.error('Error during edit card process:', error)
      // Optionally, show an error notification to the user
    }
  }

  const handelChangePlan = () => {
    if (checkUnlimitedPlan(userPlan.plan.name)) {
      window.open('https://hello.whereness.io/#inquire', '_blank')
    } else {
      navigate(PATHNAME.CHANGE_PLAN)
    }
  }

  function formatUsedStorage(
    usedStorageInput: string,
    decimalPlaces = 2
  ): string {
    // Convert the input to a number
    let usedStorage: number = parseFloat(usedStorageInput)

    // Validate the input: Return '0 Bytes' if the input is not a valid number or less than or equal to 0
    if (isNaN(usedStorage) || usedStorage <= 0) return '0'

    // Define the storage units
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    let index = 0

    // Convert the storage size to the appropriate unit
    while (usedStorage >= 1024 && index < units.length - 1) {
      usedStorage /= 1024
      index++
    }

    // Format the result with the specified number of decimal places
    return decimalPlaces === 0
      ? `${Math.round(usedStorage)} ${units[index]}`
      : `${usedStorage.toFixed(decimalPlaces)} ${units[index]}`
  }

  const checkUnlimitedPlan = (planName: string): boolean => {
    if (planName === 'UNLIMITED') {
      return true
    }
    return false
  }

  return (
    <>
      {userPlan ? (
        <>
          <div className="flex w-[80%] mx-auto max-w-[90rem] min-w-[320px] lg:min-w-[60rem]">
            <div className="lg:w-[960px] h-[581px] p-[32px] pl-0 pr-[160px]">
              {/* Plan Details Section */}

              <div className="px-10">
                <div className="px-4 rounded-[16px] bg-[rgba(61,61,61,1)] max-w-[736px] gap-y-4 w-full flex flex-col pt-3 pb-4">
                  {/* Studio Plan and Price */}
                  <div className="font-montserrat text-[20px] font-[600]">
                    <div className="flex justify-between items-center">
                      <h2 className="py-2">
                        {t('profileSetting.billing.nextPaymentPlan')} :{' '}
                        {/* {t(`plan.${userPlan?.plan?.name?.toLowerCase()}`)} */}
                        {i18n.language === 'en'
                          ? `${userPlan?.plan?.remarks?.nameEn}`
                          : `${userPlan?.plan?.remarks?.name}`}
                      </h2>
                      <h2 className="font-lato font-normal text-lg">
                        {userPlan?.plan?.name?.toLowerCase() === 'free'
                          ? `${t('plan.freePrice')}`
                          : `${calculateAndFormatPrice(
                              userPlanPrice?.price,
                              userPlanPrice?.billingPeriodType,
                              userPlanPrice?.currency
                            )}`}
                      </h2>
                    </div>

                    {/* Plan Details if userPlanPrice exists */}
                    <div>
                      {userPlanPrice?.upcoming?.planName && (
                        <p className="font-lato text-[14px] font-normal leading-[24px] text-[rgba(255,255,255,0.6)]">
                          {userPlanPrice?.upcoming.planName && (
                            <>
                              {i18n.language === 'en' ? (
                                <>
                                  {t(
                                    `plan.${userPlanPrice?.upcoming?.planName?.toLowerCase()}`
                                  )}{' '}
                                  {t('plan.name')} (
                                  {userPlanPrice?.upcoming?.price
                                    ? calculateAndFormatPrice(
                                        userPlanPrice?.upcoming?.price,
                                        userPlanPrice?.upcoming
                                          ?.billingPeriodType,
                                        userPlanPrice?.currency
                                      )
                                    : 'N/A'}
                                  ) {t('plan.effective')}{' '}
                                  {formatExpirationDate(
                                    userPlanPrice?.upcoming?.startTimestamp
                                  )}
                                </>
                              ) : (
                                <>
                                  {formatExpirationDate(
                                    userPlanPrice?.upcoming?.startTimestamp,
                                    'ja' // Pass Japanese date format
                                  )}
                                  より
                                  {t(
                                    `plan.${userPlanPrice?.upcoming?.planName?.toLowerCase()}`
                                  )}{' '}
                                  -{' '}
                                  {userPlanPrice?.upcoming?.price
                                    ? calculateAndFormatPrice(
                                        userPlanPrice?.upcoming?.price,
                                        userPlanPrice?.upcoming
                                          ?.billingPeriodType,
                                        userPlanPrice?.currency,
                                        'ja' // Pass Japanese currency format
                                      )
                                    : 'N/A'}
                                  に変更予定
                                </>
                              )}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Storage and Storage Used */}
                  <div>
                    {userProfile && (
                      <div className="flex justify-between items-center text-sm">
                        <p className="font-lato text-[16px] font-normal leading-[28px]">
                          {t('plan.storage')}
                          <span className="ml-2">
                            {i18n.language === 'en'
                              ? `${userPlan?.plan?.remarks?.storageEn}`
                              : `${userPlan?.plan?.remarks?.storage}`}
                          </span>
                        </p>

                        <p className="font-lato text-[14px] text-[rgba(255,255,255,0.6)]">
                          {formatUsedStorage(
                            userProfile?.info.currentOrganizationStorage
                          )}{' '}
                          {userPlan.plan.name !== 'UNLIMITED' && (
                            <>
                              /{' '}
                              {formatUsedStorage(
                                userPlan?.plan?.metadata?.limited.storage
                              )}{' '}
                            </>
                          )}
                          {t('plan.used')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Change Plan Button */}
              <div className="flex items-center px-10">
                <div className="w-[800px] py-[8px] flex justify-end mt-1">
                  <Button.Normal
                    classNameContainer="!w-fit"
                    className="font-semibold text-sm"
                    color="solid"
                    title={
                      checkUnlimitedPlan(userPlan.plan.name)
                        ? t('profileSetting.billing.upgradeContact')
                        : t('profileSetting.billing.changePlan')
                    }
                    onClick={handelChangePlan}
                  />
                </div>
              </div>

              {/* Payment Info Section */}
              {userCards?.cardInfo?.brand && (
                <>
                  <hr className="mx-10 my-6 border-white__op-100" />
                  <div className="w-[800px] h-[64px] p-[8px] px-[32px] gap-[10px] flex">
                    <div className="w-[240px] h-[48px] p-[6px] pl-[12px] pr-[8px] font-montserrat font-semibold">
                      {t('profileSetting.billing.paymentInfo')}
                    </div>
                    <div className="w-[480px] h-auto relative">
                      <div className="w-[480px] h-[36px] p-[6px_8px_6px_12px] gap-0 rounded-[8px] bg-[rgba(255,255,255,0.1)] text-[rgba(255,255,255,0.32)] flex items-center justify-between">
                        <span className="font-normal text-sm leading-6 font-lato">
                          {userCards?.cardInfo?.brand &&
                            userCards.cardInfo.lastFour &&
                            `${userCards.cardInfo.brand} ****${userCards.cardInfo.lastFour}`}
                        </span>
                        <img
                          onClick={handleEditCardClick}
                          className="cursor-pointer hover:opacity-80"
                          src={IconEdit} // Replace with the edit icon source
                          alt="Edit"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Billing History Section */}
              {billingHistory && (
                <>
                  <hr className="mx-10 my-6 border-white__op-100" />
                  <div className="py-3 px-10 flex items-center">
                    <div className="text-base font-semibold text-white__op-900">
                      {t('profileSetting.billing.billingHistory')}
                    </div>
                  </div>
                  <div className="mx-10 my-6">
                    <BillingHistoryTable billingHistory={billingHistory} />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <SpinComponent />
      )}
    </>
  )
}

export default PlanBillingComponent
