import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, combineReducers, Reducer } from 'redux'
import authReducer from './auth/auth.reducer'
import commonReducer from './common/common.reducer'
import profileReducer from './profile/profile.reducer'
import projectReducer from './project/project.reducer'
import sceneReducer from './scene/scene.reducer'
import { TYPES_AUTH } from './auth/auth.type'
import { removeAllTokenCookie } from '../utils/cookies'
import organizationReducer from './organization/organization.reducer'
import mapReducer from './map/map.reducer'
import userPlanReducer from './userPlan/userPlan.reducer'
import DropReducer from '@stores/DragDrop/dragDropSlice'

const reducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  profile: profileReducer,
  project: projectReducer,
  scene: sceneReducer,
  organization: organizationReducer,
  map: mapReducer,
  userPlan: userPlanReducer,
  drag: DropReducer,
})

const rootReducer = (state: ReturnType<typeof reducers>, action: AnyAction) => {
  if (action.type === `auth/${TYPES_AUTH.REDUCERS.SET_CLEAR_DATA}`) {
    removeAllTokenCookie()
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

export const store = configureStore({
  reducer: rootReducer as Reducer<ReturnType<typeof reducers>>,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
