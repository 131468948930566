import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from '@components/common/Button'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  cancelCmsSubscriptionAction,
  downgradeAddOnAction,
  downgradeServicePackageAction,
  getCurrentPlanAndCardInfoAction,
  getListCmsProductAction,
} from '@stores/organization/organization.action'
import {
  IBillingPeriodType,
  IResCurrentPlanAndCardInfo,
  IResListCmsProduct,
  IUnitPriceCmsProduct,
} from '@models/organization'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import ListServicePackageComponent from './ListServicePackageComponent'
import { DETAIL_PLAN_KEY, LANGUAGE, PATHNAME } from '@constants/common'
import {
  IUnitAddOnDetail,
  organizationActions,
} from '@stores/organization/organization.reducer'
import { TYPE_ORGANIZATION } from '@stores/organization/organization.type'
import { removeLocalCookie } from '@utils/cookies'
import { TypeMoney } from '@models/project'
import SpinComponent from '@components/common/SpinComponent'
import PopupConfirm from '@components/common/PopupConfirm'
import { actions as profileActions } from '@stores/profile/profile.reducer'
import { TYPE_PROFILE } from '@stores/profile/profile.type'
import { TypeProfileSetting } from '@models/profile'
import moment from 'moment'
import { IProductType, IReqUpAndDownPlan, IStateNavigate } from '@models/plan'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import {
  LIST_PER_SIZE_ADD_ON,
  LIST_SERVICE_PACKAGE,
  PROJECT_VIEW_KEY,
} from '@constants/plan'
import i18next from 'i18next'
import { ILanguageType } from '@stores/common/common.reducer'
import { helper } from '@utils/helper/common'

const SelectPlanComponent: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const lang = i18next.language
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { newServicePackage, newAddOn } = useAppSelector(
    (state: RootState) => state.organization
  )
  const [peroidTypePackage, setPeroidTypePackage] =
    useState<IBillingPeriodType>(IBillingPeriodType.BY_YEAR)
  const [peroidTypeAddOn, setPeroidTypeAddOn] = useState<IBillingPeriodType>(
    newAddOn?.billingPeriodType || IBillingPeriodType.BY_YEAR
  )
  const [listCmsProduct, setListCmsProduct] = useState<
    Array<IResListCmsProduct>
  >([])
  const [listAddOn, setListAddOn] = useState<Array<IResListCmsProduct>>([])
  const [priceAddOnSelect, setPriceAddOnSelect] = useState<{
    byMonth: IUnitPriceCmsProduct
    byYear: IUnitPriceCmsProduct
  } | null>(null)
  const [dataCurrentPlan, setDataCurrentPlan] =
    useState<IResCurrentPlanAndCardInfo | null>(null)
  const [dataCurrentAddOn, setDataCurrentAddOn] = useState<{
    size: string
    view: string
  } | null>(null)
  const [isLoadingGetCmsProduct, setIsLoadingGetCmsProduct] =
    useState<boolean>(false)
  const [isOpenConfirmCancelSub, setIsOpenConfirmCancelSub] =
    useState<boolean>(false)
  const [isDisabledCancelSub, setIsDisabledCancelSub] = useState<boolean>(false)
  const [isShowPopupDowngrade, setIsShowPopupDowngrade] =
    useState<boolean>(false)
  const [isShowContactView, setIsShowContactView] = useState<boolean>(false)
  const [isMonthToYear, setIsMonthToYear] = useState<boolean>(false)

  useEffect(() => {
    getListCmsProduct()
    handleGetCurrentPlan()
    if (newAddOn && newServicePackage) {
      setPeroidTypeAddOn(newAddOn.billingPeriodType)
      setPeroidTypePackage(newServicePackage.billingPeriodType)
    }
  }, [])

  useEffect(() => {
    if (
      newAddOn &&
      dataCurrentAddOn &&
      dataCurrentPlan &&
      newAddOn.keySize === dataCurrentAddOn.size &&
      newAddOn.keyView === dataCurrentAddOn.view &&
      newAddOn.billingPeriodType === dataCurrentPlan.addOn.billingPeriodType
    ) {
      dispatch(
        organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](null)
      )
    }
  }, [newAddOn])

  useEffect(() => {
    if (
      newServicePackage &&
      dataCurrentPlan &&
      newServicePackage.servicePackageId ===
        dataCurrentPlan.servicePackage.productId &&
      newServicePackage.billingPeriodType ===
        dataCurrentPlan.servicePackage.billingPeriodType
    ) {
      dispatch(
        organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_SERVICE_PACKAGE](
          null
        )
      )
    }
  }, [newServicePackage])

  useEffect(() => {
    initPriceAddOnSelected()
  }, [listAddOn])

  useEffect(() => {
    if (priceAddOnSelect && newAddOn) {
      const dataAddOn: IUnitAddOnDetail = {
        ...newAddOn,
        billingPeriodType: peroidTypeAddOn,
        price: priceAddOnSelect[peroidTypeAddOn],
      }
      dispatch(
        organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](
          dataAddOn
        )
      )
    } else {
      const currentAddOn = _.find(listAddOn, {
        productId: dataCurrentPlan?.addOn.productId,
      })
      if (currentAddOn && dataCurrentAddOn && dataCurrentPlan) {
        if (dataCurrentPlan.addOn.billingPeriodType !== peroidTypeAddOn) {
          const { productId, productName } = dataCurrentPlan.addOn
          const { prices } = currentAddOn
          const productCurrency = TypeMoney.JPY.toUpperCase()
          const price = _.find(prices[peroidTypeAddOn], { productCurrency })
          const newAddOn: IUnitAddOnDetail = {
            id: productId,
            name: productName[lang as ILanguageType],
            keySize: dataCurrentAddOn.size,
            keyView: dataCurrentAddOn.view,
            price: price as IUnitPriceCmsProduct,
            billingPeriodType: peroidTypeAddOn,
          }
          dispatch(
            organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](
              newAddOn
            )
          )
        } else {
          dispatch(
            organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](null)
          )
        }
      }
    }
  }, [peroidTypeAddOn])

  const initPriceAddOnSelected = () => {
    if (newAddOn) {
      const addOn = _.find(listAddOn, {
        productId: newAddOn.id,
      })
      if (addOn) {
        const { prices } = addOn
        const productCurrency = TypeMoney.JPY.toUpperCase()
        const priceYears = _.find(prices.byYear, {
          productCurrency,
        })
        const priceMonths = _.find(prices.byMonth, {
          productCurrency,
        })
        if (priceMonths && priceYears) {
          setPriceAddOnSelect({
            byMonth: priceMonths as IUnitPriceCmsProduct,
            byYear: priceYears as IUnitPriceCmsProduct,
          })
        }
      }
    }
  }

  const getListCmsProduct = async () => {
    setIsLoadingGetCmsProduct(true)
    try {
      if (user) {
        const { organizationId } = user
        const res = await dispatch(
          getListCmsProductAction({ organizationId })
        ).unwrap()
        if (res.data) {
          const response = res.data as Array<IResListCmsProduct>
          const listCmsProduct = response.filter(
            (product) => product.productType === 'SERVICE_PACKAGE'
          )
          const listAddOn = response.filter(
            (product) => product.productType !== 'SERVICE_PACKAGE'
          )
          // setListCmsProduct(listCmsProduct)
          setListCmsProduct(response)
          setListAddOn(listAddOn)
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'getListCmsProduct',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }
      }
      setIsLoadingGetCmsProduct(false)
    } catch {
      setIsLoadingGetCmsProduct(false)
      openNotification({
        type: TYPE.ERROR,
        key: 'getListCmsProduct',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleGetCurrentPlan = async () => {
    try {
      if (!user) {
        throw new Error('OrganizationId is required')
      }
      const { organizationId } = user
      const res = await dispatch(
        getCurrentPlanAndCardInfoAction({
          organizationId,
          isCurrentPlanOnly: true,
        })
      ).unwrap()
      if (res) {
        setDataCurrentPlan(res)
        const addOnKey = res.addOn.productKey
        const key = addOnKey.split('_')
        setDataCurrentAddOn({ size: key[2], view: key[3] })
        if (!res.servicePackage.nextPaymentDate && !res.addOn.nextPaymentDate) {
          setIsDisabledCancelSub(true)
        }
      }
    } catch {
      setIsDisabledCancelSub(true)
    }
  }

  const switchModePackage = (type: IBillingPeriodType) => {
    setPeroidTypePackage(type)
  }

  const clearAllSelect = () => {
    dispatch(
      organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_SERVICE_PACKAGE](
        null
      )
    )
    dispatch(
      organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](null)
    )
    removeLocalCookie(DETAIL_PLAN_KEY)
  }

  const handleSetNewAddOn = (keySize?: string, keyView?: string) => {
    const addOn = _.find(listAddOn, {
      productKey: `ADD_ON_${keySize ?? dataCurrentAddOn?.size}_${
        keyView ?? dataCurrentAddOn?.view
      }`,
    })
    if (addOn) {
      const { prices } = addOn
      const productCurrency = TypeMoney.JPY.toUpperCase()
      const newPrice = _.find(prices[peroidTypeAddOn], { productCurrency })
      const priceYears = _.find(prices.byYear, {
        productCurrency,
      })
      const priceMonths = _.find(prices.byMonth, {
        productCurrency,
      })
      if (priceMonths && priceYears) {
        setPriceAddOnSelect({
          byMonth: priceMonths as IUnitPriceCmsProduct,
          byYear: priceYears as IUnitPriceCmsProduct,
        })
      }
      if (newPrice) {
        const newAddOn: IUnitAddOnDetail = {
          id: addOn.productId,
          name: addOn.productName[lang as ILanguageType],
          keySize: keySize ?? dataCurrentAddOn?.size ?? '',
          keyView: keyView ?? dataCurrentAddOn?.view ?? '',
          price: newPrice as IUnitPriceCmsProduct,
          billingPeriodType: peroidTypeAddOn,
        }
        dispatch(
          organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](
            newAddOn
          )
        )
      }
    }
  }

  // const handleOpenFeatureComparison = () => {
  //   window.open(LINK_FEATURE_COMPARISION)
  // }

  const handleCancelSubscription = async () => {
    try {
      if (!user) {
        throw new Error('organizationId is required')
      }
      const { organizationId } = user
      const res = await dispatch(
        cancelCmsSubscriptionAction(organizationId)
      ).unwrap()
      if (res) {
        setIsOpenConfirmCancelSub(false)
        setIsDisabledCancelSub(true)
        handleRedirectToBilling()
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getListCmsProduct',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleRedirectToBilling = () => {
    clearAllSelect()
    dispatch(
      profileActions[TYPE_PROFILE.REDUCERS.SET_TAB_SETTING](
        TypeProfileSetting.BILLING
      )
    )
    navigate(PATHNAME.PROFILE_SETTING)
  }

  const handleConfirmPayment = async () => {
    if (!dataCurrentPlan) {
      if (
        location.state &&
        (location.state as { from: string }).from === PATHNAME.SCENE_SETTING
      ) {
        navigate(PATHNAME.CONFIRM_PAYMENT, {
          state: {
            from: PATHNAME.SCENE_SETTING,
          },
        })
      } else {
        navigate(PATHNAME.CONFIRM_PAYMENT)
      }
    } else {
      const { servicePackage, addOn } = dataCurrentPlan
      if (newServicePackage && !newAddOn) {
        if (checkUpgradeServicePackage()) {
          let prorationPrice = sceneSettingHelper.chargeProration(
            servicePackage,
            newServicePackage,
            IProductType.SERVICE_PACKAGE
          )
          const nextCharge = sceneSettingHelper.getNextMonth(
            newServicePackage.billingPeriodType
          )
          if (
            servicePackage.billingPeriodType === IBillingPeriodType.BY_YEAR &&
            newServicePackage.billingPeriodType === IBillingPeriodType.BY_MONTH
          ) {
            prorationPrice = newServicePackage.price.productPrice
          }
          navigate(PATHNAME.CONFIRM_PAYMENT, {
            state: {
              upgradeSP: {
                prorationPrice,
                nextCharge,
                isMonthToYear:
                  servicePackage.billingPeriodType ===
                    IBillingPeriodType.BY_MONTH &&
                  newServicePackage.billingPeriodType ===
                    IBillingPeriodType.BY_YEAR
                    ? true
                    : false,
              },
            } as IStateNavigate,
          })
        } else {
          if (
            newServicePackage.servicePackageId === servicePackage.productId &&
            newServicePackage.billingPeriodType === IBillingPeriodType.BY_YEAR
          ) {
            setIsMonthToYear(true)
          }
          setIsShowPopupDowngrade(true)
        }
      }
      if (newAddOn && dataCurrentAddOn && !newServicePackage) {
        if (
          newAddOn.keySize === dataCurrentAddOn.size &&
          dataCurrentAddOn.view !== PROJECT_VIEW_KEY._100VIEWS &&
          newAddOn.keyView !== dataCurrentAddOn.view
        ) {
          setIsShowContactView(true)
          return
        }
        if (
          checkUpgradeAddOn() ||
          (dataCurrentAddOn.view === PROJECT_VIEW_KEY._100VIEWS &&
            newAddOn.keyView !== PROJECT_VIEW_KEY._100VIEWS)
        ) {
          let prorationPrice = newAddOn.price.productPrice
          const nextCharge = sceneSettingHelper.getNextMonth(
            newAddOn.billingPeriodType
          )
          if (
            addOn.billingPeriodType === IBillingPeriodType.BY_YEAR &&
            addOn.billingPeriodType === newAddOn.billingPeriodType &&
            addOn.price !== 0
          ) {
            prorationPrice = sceneSettingHelper.chargeProration(
              addOn,
              newAddOn,
              IProductType.ADD_ON
            )
          }
          navigate(PATHNAME.CONFIRM_PAYMENT, {
            state: {
              upgradeAddOn: {
                prorationPrice,
                nextCharge: nextCharge,
                isMonthToYear:
                  addOn.billingPeriodType === IBillingPeriodType.BY_MONTH &&
                  newAddOn.billingPeriodType === IBillingPeriodType.BY_YEAR
                    ? true
                    : false,
              },
            } as IStateNavigate,
          })
        } else {
          if (
            newAddOn.keySize === dataCurrentAddOn.size &&
            newAddOn.keyView === dataCurrentAddOn.view &&
            newAddOn.billingPeriodType === IBillingPeriodType.BY_YEAR
          ) {
            setIsMonthToYear(true)
          }
          setIsShowPopupDowngrade(true)
        }
      }
      if (newAddOn && newServicePackage && dataCurrentAddOn) {
        if (
          (checkUpgradeAddOn() ||
            (dataCurrentAddOn.view === PROJECT_VIEW_KEY._100VIEWS &&
              newAddOn.keyView !== PROJECT_VIEW_KEY._100VIEWS)) &&
          checkUpgradeServicePackage()
        ) {
          let prorationPriceSP = sceneSettingHelper.chargeProration(
            servicePackage,
            newServicePackage,
            IProductType.SERVICE_PACKAGE
          )
          let prorationPriceAddOn = newAddOn.price.productPrice
          const nextChargeSP = sceneSettingHelper.getNextMonth(
            newServicePackage.billingPeriodType
          )
          const nextChargeAddOn = sceneSettingHelper.getNextMonth(
            newAddOn.billingPeriodType
          )
          if (
            servicePackage.billingPeriodType === IBillingPeriodType.BY_YEAR &&
            newServicePackage.billingPeriodType === IBillingPeriodType.BY_MONTH
          ) {
            prorationPriceSP = newServicePackage.price.productPrice
          }
          if (
            addOn.billingPeriodType === IBillingPeriodType.BY_YEAR &&
            addOn.billingPeriodType === newAddOn.billingPeriodType &&
            addOn.price !== 0
          ) {
            prorationPriceAddOn = sceneSettingHelper.chargeProration(
              addOn,
              newAddOn,
              IProductType.ADD_ON
            )
          }
          navigate(PATHNAME.CONFIRM_PAYMENT, {
            state: {
              upgradeSP: {
                prorationPrice: prorationPriceSP,
                nextCharge: nextChargeSP,
                isMonthToYear:
                  servicePackage.billingPeriodType ===
                    IBillingPeriodType.BY_MONTH &&
                  newServicePackage.billingPeriodType ===
                    IBillingPeriodType.BY_YEAR
                    ? true
                    : false,
              },
              upgradeAddOn: {
                prorationPrice: prorationPriceAddOn,
                nextCharge: nextChargeAddOn,
                isMonthToYear:
                  addOn.billingPeriodType === IBillingPeriodType.BY_MONTH &&
                  newAddOn.billingPeriodType === IBillingPeriodType.BY_YEAR
                    ? true
                    : false,
              },
            } as IStateNavigate,
          })
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'downgradePlan',
            message: t('plan.errorUpDownSameTime'),
          })
        }
      }
    }
  }

  const checkUpgradeServicePackage = () => {
    const indexNewService = _.findIndex(LIST_SERVICE_PACKAGE, {
      key: newServicePackage?.servicePackageKey,
    })
    const indexCurrentService = _.findIndex(LIST_SERVICE_PACKAGE, {
      key: dataCurrentPlan?.servicePackage?.productKey,
    })
    if (indexNewService > indexCurrentService) {
      return true
    }
    return false
  }

  const checkUpgradeAddOn = () => {
    if (dataCurrentAddOn && dataCurrentPlan && newAddOn) {
      const { size } = dataCurrentAddOn
      const newSizeKey = newAddOn.keySize
      const indexNewAddOn = _.indexOf(LIST_PER_SIZE_ADD_ON, newSizeKey)
      const indexCurrentAddOn = _.indexOf(LIST_PER_SIZE_ADD_ON, size)
      if (indexNewAddOn > indexCurrentAddOn) {
        return true
      }
      return false
    }
  }

  const handleDowngradeServicePackage = async () => {
    try {
      if (!user) {
        throw new Error('organizationId is required')
      }
      if (!newServicePackage) {
        throw new Error('service package is required')
      }
      const { organizationId } = user
      const { billingPeriodType, servicePackageId } = newServicePackage
      const req: IReqUpAndDownPlan = {
        organizationId,
        id: servicePackageId,
        billingPeriodType,
      }
      const res = await dispatch(downgradeServicePackageAction(req)).unwrap()
      if (res) {
        setIsShowPopupDowngrade(false)
        clearAllSelect()
        if (location.state && (location.state as { from: string }).from) {
          if (
            (location.state as { from: string }).from ===
            PATHNAME.PROFILE_SETTING
          ) {
            dispatch(
              profileActions[TYPE_PROFILE.REDUCERS.SET_TAB_SETTING](
                TypeProfileSetting.BILLING
              )
            )
          }
          window.history.back()
        }
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'downgradeServicePackage',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleDowngradeAddOn = async () => {
    try {
      if (!user) {
        throw new Error('organizationId is required')
      }
      if (!newAddOn) {
        throw new Error('add-on is required')
      }
      const { organizationId } = user
      const { billingPeriodType, id } = newAddOn
      const req: IReqUpAndDownPlan = {
        organizationId,
        id,
        billingPeriodType,
      }
      const res = await dispatch(downgradeAddOnAction(req)).unwrap()
      if (res) {
        setIsShowPopupDowngrade(false)
        clearAllSelect()
        if (location.state && (location.state as { from: string }).from) {
          if (
            (location.state as { from: string }).from ===
            PATHNAME.PROFILE_SETTING
          ) {
            dispatch(
              profileActions[TYPE_PROFILE.REDUCERS.SET_TAB_SETTING](
                TypeProfileSetting.BILLING
              )
            )
          }
          window.history.back()
        }
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'downgradeServicePackage',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const PriceComponent = (): React.ReactElement => {
    if (
      newAddOn &&
      newServicePackage &&
      newAddOn.billingPeriodType === newServicePackage.billingPeriodType
    ) {
      return (
        <div className="flex items-center mr-8 w-full justify-end">
          <div className="mr-4 font-lato leading-6">
            {t('plan.sumMoneySamePeriod', {
              sub: newServicePackage.price.productPrice.toLocaleString('en-US'),
              addOn: newAddOn.price.productPrice.toLocaleString('en-US'),
            })}
          </div>

          <span className="text-xl font-semibold">
            {t('plan.totalSamePeriod', {
              number: (
                newServicePackage.price.productPrice +
                newAddOn.price.productPrice
              ).toLocaleString('en-US'),
              period:
                newServicePackage.billingPeriodType ===
                IBillingPeriodType.BY_MONTH
                  ? t('common.month')
                  : t('common.year'),
            })}
          </span>
        </div>
      )
    }
    if (
      newAddOn &&
      newServicePackage &&
      newAddOn.billingPeriodType !== newServicePackage.billingPeriodType
    ) {
      return (
        <div className="flex items-center mr-8 w-full justify-end">
          <div className="mr-4 flex items-center">
            <span className="font-lato leading-6">
              {t('common.plan')}{' '}
              <span className="font-semibold text-xl">
                {t('plan.totalSamePeriod', {
                  number:
                    newServicePackage.price.productPrice.toLocaleString(
                      'en-US'
                    ),
                  period:
                    newServicePackage.billingPeriodType ===
                    IBillingPeriodType.BY_MONTH
                      ? t('common.month')
                      : t('common.year'),
                })}
              </span>
            </span>
            <span className="text-xl w-4 h-4 flex items-center justify-center mx-1">
              +
            </span>
            <span className="font-lato leading-6">
              {t('plan.pubOption')}{' '}
              <span className="font-semibold text-xl">
                {t('plan.totalSamePeriod', {
                  number: newAddOn.price.productPrice.toLocaleString('en-US'),
                  period:
                    newAddOn.billingPeriodType === IBillingPeriodType.BY_MONTH
                      ? t('common.month')
                      : t('common.year'),
                })}
              </span>
            </span>
          </div>
        </div>
      )
    }
    return (
      <>
        {newAddOn && !newServicePackage && (
          <div className="flex items-center mr-8 w-full justify-end">
            <div className="mr-4 flex items-center">
              <span className="font-lato leading-6">
                {t('plan.pubOption')}{' '}
                <span className="font-semibold text-xl">
                  {t('plan.totalSamePeriod', {
                    number: newAddOn.price.productPrice.toLocaleString('en-US'),
                    period:
                      newAddOn.billingPeriodType === IBillingPeriodType.BY_MONTH
                        ? t('common.month')
                        : t('common.year'),
                  })}
                </span>
              </span>
            </div>
          </div>
        )}
        {!newAddOn && newServicePackage && (
          <div className="flex items-center mr-8 w-full justify-end">
            <div className="mr-4 flex items-center">
              <span className="font-lato leading-6">
                {t('common.plan')}{' '}
                <span className="font-semibold text-xl">
                  {t('plan.totalSamePeriod', {
                    number:
                      newServicePackage.price.productPrice.toLocaleString(
                        'en-US'
                      ),
                    period:
                      newServicePackage.billingPeriodType ===
                      IBillingPeriodType.BY_MONTH
                        ? t('common.month')
                        : t('common.year'),
                  })}
                </span>
              </span>
            </div>
          </div>
        )}
      </>
    )
  }

  const genLinkLargeScale = () => {
    if (isShowContactView) {
      setIsShowContactView(!isShowContactView)
    }
    helper.redirectToContact()
  }

  const handleCancelDowngrade = useCallback(() => {
    setIsShowPopupDowngrade(false)
  }, [])

  const handleClosePopupChangeView = useCallback(() => {
    setIsShowContactView(false)
  }, [])

  if (isLoadingGetCmsProduct) {
    return <SpinComponent />
  }

  return (
    <>
      <div className="relative">
        <div className="px-4 flex items-center justify-center">
          <div className="py-6">
            <p className="text-[36px] text-white font-semibold ml-4 font-Montserrat">
              {t('plan.changePlan')}
            </p>
            <div className="flex items-center flex-row py-5 px-4 mt-5">
              <div className="leading-9 text-2xl-p text-white font-semibold flex-shrink-0 mr-6 ">
                {t('plan.selectStudioPlan')}
              </div>
              <div className="w-full flex flex-row items-start justify-between">
                <div className="flex">
                  <Button.Normal
                    title={t('plan.annual')}
                    className={classNames(
                      '!py-1.5 !px-4 font-semibold leading-5 !text-white',
                      {
                        '!bg-blue-700 !opacity-[.97]':
                          peroidTypePackage === IBillingPeriodType.BY_YEAR,
                      },
                      {
                        '!opacity-[.76]':
                          peroidTypePackage !== IBillingPeriodType.BY_YEAR,
                      }
                    )}
                    classNameContainer="!py-2"
                    color="transparent"
                    onClick={() =>
                      switchModePackage(IBillingPeriodType.BY_YEAR)
                    }
                  />
                  <Button.Normal
                    title={t('plan.monthly')}
                    className={classNames(
                      '!py-1.5 !px-4 font-semibold leading-5 !text-white',
                      {
                        '!bg-blue-700 !opacity-[.97]':
                          peroidTypePackage === IBillingPeriodType.BY_MONTH,
                      },
                      {
                        '!opacity-[.76]':
                          peroidTypePackage !== IBillingPeriodType.BY_MONTH,
                      }
                    )}
                    classNameContainer="!py-2"
                    color="transparent"
                    onClick={() =>
                      switchModePackage(IBillingPeriodType.BY_MONTH)
                    }
                  />
                </div>
                <div className="flex">
                  {/* <Button.Normal
                  title={t('plan.contactForLarge')}
                  className="font-semibold leading-5"
                  color="transparent"
                  onClick={genLinkLargeScale}
                /> */}
                  {/* <Button.Normal
                  title={t('plan.featureComparison')}
                  className="font-semibold leading-5"
                  color="transparent"
                  onClick={handleOpenFeatureComparison}
                /> */}
                </div>
              </div>
            </div>
            {listCmsProduct.length > 0 && (
              <ListServicePackageComponent
                listCmsProduct={listCmsProduct}
                currentPlan={dataCurrentPlan?.servicePackage}
                handleSetNewAddOn={handleSetNewAddOn}
                peroidTypePackage={peroidTypePackage}
              />
            )}
          </div>
        </div>
        {/* <hr className="my-2.5 mx-4 border-white__op-100" /> */}
        {/* <div className="py-6 mb-14">
          <div className="py-2 px-4 flex items-center">
            <div className="font-semibold text-2xl-p mr-6">
              {t('plan.selectDis')}
            </div>
            <div className="flex">
              <Button.Normal
                title={t('plan.annual')}
                className={classNames('!py-1.5 !px-4 font-semibold leading-5', {
                  '!bg-blue-700':
                    peroidTypeAddOn === IBillingPeriodType.BY_YEAR,
                })}
                classNameContainer="!py-2"
                color="transparent"
                onClick={() => switchModeAddOn(IBillingPeriodType.BY_YEAR)}
              />
              <Button.Normal
                title={t('plan.monthly')}
                className={classNames('!py-1.5 !px-4 font-semibold leading-5', {
                  '!bg-blue-700':
                    peroidTypeAddOn === IBillingPeriodType.BY_MONTH,
                })}
                classNameContainer="!py-2"
                color="transparent"
                onClick={() => switchModeAddOn(IBillingPeriodType.BY_MONTH)}
              />
            </div>
          </div>
          <div className="py-2">
            <div className="py-2 px-4">
              <div className="px-6 py-3 flex items-center bg-white__op-50 rounded-2xl">
                <div className="text-xl font-semibold mr-2.5 w-full">
                  {t('plan.maxDis')}
                </div>
                <div className="mr-6 w-[25rem] flex-shrink-0">
                  <DisSizePerProjectSelect
                    handleSetNewAddOn={handleSetNewAddOn}
                    currentSize={dataCurrentAddOn?.size}
                  />
                </div>
              </div>
            </div>
            <div className="py-2 px-4">
              <div className="px-6 py-3 flex items-center bg-white__op-50 rounded-2xl">
                <div className="text-xl font-semibold mr-2.5 w-full">
                  {t('plan.maxNumberView')}
                </div>
                <div className="mr-6 w-[25rem] flex-shrink-0">
                  <ViewPerMonthSelect
                    handleSetNewAddOn={handleSetNewAddOn}
                    currentView={dataCurrentAddOn?.view}
                  />
                </div>
              </div>
            </div>
            {newAddOn &&
              dataCurrentAddOn &&
              !checkUpgradeAddOn() &&
              (dataCurrentAddOn.view !== PROJECT_VIEW_KEY._100VIEWS ||
                (dataCurrentAddOn.view === PROJECT_VIEW_KEY._100VIEWS &&
                  dataCurrentAddOn.view === newAddOn.keyView)) && (
                <div className="flex items-center px-4">
                  <div className="w-6 h-6 flex items-center justify-center mr-2.5">
                    <img src={IconInfo} alt="" />
                  </div>
                  <div className="font-lato leading-5 text-white__op-600">
                    {t('plan.infoChangeView')}
                  </div>
                  <Button.Normal
                    classNameContainer="!w-auto ml-auto mr-0"
                    className="font-semibold"
                    title={t('common.contact')}
                    color="transparent"
                    onClick={genLinkLargeScale}
                  />
                </div>
              )}
          </div>
        </div> */}
        {/* <div className="fixed bottom-0 inset-x-0 ml-16 pl-[5.5rem] pr-[6.25rem] bg-gray-800 border border-t border-black__op-300">
          <div className="flex py-2 px-4 w-full justify-between">
            <Button.Normal
              title={t('plan.cancelAllPlan')}
              className="font-semibold bg-blue-600"
              classNameContainer="mr-2 !w-auto"
              color="transparent"
              onClick={() => setIsOpenConfirmCancelSub(true)}
              disabled={isDisabledCancelSub}
            />
            <PriceComponent />
            <div className="flex">
              <Button.Normal
                title={t('common.cancel')}
                className="!py-2.5 !px-4 font-semibold text-base bg-blue-600"
                classNameContainer="mr-2"
                color="ghost"
                onClick={handleClearSubscription}
              />
              <Button.Normal
                title={t('plan.conrirmPayment')}
                className="!py-2.5 !px-4 font-semibold text-base bg-blue-600"
                disabled={!newServicePackage && !newAddOn}
                onClick={handleConfirmPayment}
              />
            </div>
          </div>
        </div> */}
      </div>
      {isOpenConfirmCancelSub && (
        <PopupConfirm
          title={t('popup.cancelSubscription.title')}
          visible={isOpenConfirmCancelSub}
          messageFirst={t('popup.cancelSubscription.messageFirst')}
          okLabel={t('popup.cancelSubscription.okLabel')}
          cancelLabel={t('common.cancel')}
          handleCancel={() => setIsOpenConfirmCancelSub(false)}
          handleOk={handleCancelSubscription}
        />
      )}
      {isShowPopupDowngrade && dataCurrentPlan && (
        <PopupConfirm
          title={
            isMonthToYear
              ? ''
              : t('popup.confirm.downgradeServicePackage.title')
          }
          visible={isShowPopupDowngrade}
          messageFirst={
            isMonthToYear
              ? t('popup.confirm.downMonthToYear.description', {
                  date: moment(
                    newServicePackage
                      ? dataCurrentPlan.servicePackage.currentPaymentExpireDate
                      : dataCurrentPlan.addOn.currentPaymentExpireDate
                  ).format(lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'll'),
                })
              : decodeURIComponent(
                  t('popup.confirm.downgradeServicePackage.description', {
                    date: moment(
                      newServicePackage
                        ? dataCurrentPlan.servicePackage
                            .currentPaymentExpireDate
                        : dataCurrentPlan.addOn.currentPaymentExpireDate
                    ).format(lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'll'),
                  })
                )
          }
          okLabel={
            isMonthToYear
              ? t('popup.confirm.downMonthToYear.okLabel')
              : t('popup.confirm.downgradeServicePackage.okLabel')
          }
          cancelLabel={t('common.cancel')}
          handleCancel={handleCancelDowngrade}
          handleOk={
            newServicePackage
              ? handleDowngradeServicePackage
              : handleDowngradeAddOn
          }
        />
      )}
      {isShowContactView && dataCurrentPlan && (
        <PopupConfirm
          visible={isShowContactView}
          messageFirst={t('plan.infoChangeView')}
          okLabel={t('common.contact')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleClosePopupChangeView}
          handleOk={genLinkLargeScale}
        />
      )}
    </>
  )
}

export default SelectPlanComponent
