import { createAsyncThunk } from '@reduxjs/toolkit'
import { GET_MAP_INFOS } from '../../graphql/map/query'
import { API_TYPE_MAP } from '../../graphql/map/type'
import { graphqlApi } from '../../api/graphql'
import { TYPE_MAP } from './map.type'
import {
  IRequestCreateMapInfo,
  IRequestUpdateMapInfo,
  IRequestDeleteMap,
} from '../../models/map'
import {
  CREATE_MAP_INFO,
  UPDATE_MAP_INFO,
  DELETE_MAP,
} from '../../graphql/map/mutation'

export const getMapInfosAction = createAsyncThunk(
  TYPE_MAP.ACTIONS.GET_MAP_INFOS,
  async (projectId: number) => {
    const result = await graphqlApi.queryRequest(
      GET_MAP_INFOS,
      API_TYPE_MAP.QUERY.GET_MAP_INFOS,
      { projectId }
    )
    return result
  }
)

export const createMapInfoAction = createAsyncThunk(
  TYPE_MAP.ACTIONS.CREATE_MAP_INFO,
  async (createMapInfoInput: IRequestCreateMapInfo[]) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_MAP_INFO,
      API_TYPE_MAP.MUTATION.CREATE_MAP_INFO,
      { createMapInfoInput }
    )
    return result
  }
)

export const updateMapInfoAction = createAsyncThunk(
  TYPE_MAP.ACTIONS.UPDATE_MAP_INFO,
  async (updateMapInfoInput: IRequestUpdateMapInfo) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_MAP_INFO,
      API_TYPE_MAP.MUTATION.UPDATE_MAP_INFO,
      { updateMapInfoInput }
    )
    return result
  }
)

export const deleteMapAction = createAsyncThunk(
  TYPE_MAP.ACTIONS.DELETE_MAP,
  async (deleteMapInput: IRequestDeleteMap) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_MAP,
      API_TYPE_MAP.MUTATION.DELETE_MAP,
      { deleteMapInput }
    )
    return result
  }
)
