import { TYPE_FILE, TYPE_MEDIA } from '../../models/media'

class Helper {
  public detectMediaType(type: string, is360?: boolean) {
    if (TYPE_FILE.AUDIO.includes(type)) return TYPE_MEDIA.AUDIO
    if (TYPE_FILE.FILES.includes(type)) return TYPE_MEDIA.PDF
    if (TYPE_FILE.IMAGES.includes(type))
      return is360 ? TYPE_MEDIA.IMAGE360 : TYPE_MEDIA.IMAGE
    if (TYPE_FILE.VIDEOS.includes(type))
      return is360 ? TYPE_MEDIA.VIDEO360 : TYPE_MEDIA.VIDEO
    if (TYPE_FILE.GAUSSIAN_SPLATTING.includes(type))
      return TYPE_MEDIA.GAUSSIAN_SPLATTING
    throw new Error('Type is not valid')
  }

  public detectFileType(file: File | Blob) {
    let fileType = ''
    if (file.type) {
      fileType = file.type
    } else if (file instanceof File) {
      fileType = file.name.split('.').pop()?.toLowerCase() || ''
    }

    return fileType
  }

  public appendGSToFileType(mediaType: TYPE_MEDIA, fileType: string) {
    if (mediaType === TYPE_MEDIA.GAUSSIAN_SPLATTING) {
      return `gaussian-splatting/${fileType}`
    }

    return fileType
  }
}
export const mediaLibHelper = new Helper()
