import React, { useCallback, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@components/common/Icon'
import { openNotification } from '@utils/notification'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  createMediaFolderAction,
  uploadMediaAction,
} from '@stores/project/project.action'
import Popup, { PopupPlacement } from '@components/common/Popup'
import { TYPE, TYPE_ERROR } from '@models/common'
import Button from '@components/common/Button'
import UploadIcon from '@assets/icons/mediaIcon/upload.svg'
import FolderIcon from '@assets/icons/mediaIcon/folder.svg'
import videoPhotoIcon from '@assets/icons/leftSidebar/360.svg'
import IconAdd from '@assets/icons/sceneSetting/add.svg'
import { actions as projectActions } from '../../../store/project/project.reducer'
import { useStorage } from '../../../../src/hooks/useStorage'
import {
  IMedia,
  IRequestUploadMedia,
  MODE_OF_MEDIA_LIB_POPUP,
  TYPE_FILE,
  TYPE_MEDIA,
  TYPE_STATUS_UPLOAD,
} from '@models/media'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { mediaLibHelper } from '@utils/helper/mediaLib'
import { IOnProgressFnEvent } from '@models/s3'
import { S3MultipleUploader } from '../../../../src/api/s3'

interface PropTypes {
  openFileUploadWindow: () => void
  position?: PopupPlacement
}

const UploadMediaButton: React.FC<PropTypes> = ({
  openFileUploadWindow,
  position,
}) => {
  const { t } = useTranslation()
  const [isOpenAddMediaPopup, setIsOpenAddMediaPopup] = useState(false)
  const refInputFileUpload: React.LegacyRef<HTMLInputElement> = useRef(null)
  const {
    projectInfo,
    mediaFolders,
    modeOfMediaLibPopup,
    selectedFolderInMediaLibPopup,
  } = useAppSelector((state: RootState) => state.project)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const dispatch = useAppDispatch()
  const { updateCurrentStorage } = useStorage()

  const handleCreateMediaFolder = useCallback(async () => {
    try {
      const projectId = projectInfo?.projectId
      if (!projectId) {
        throw new Error('ProjectId is invalid')
      }
      const res = await dispatch(
        createMediaFolderAction({
          projectId: projectInfo?.projectId,
          name: `Folder${mediaFolders?.length + 1}`,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'createMediaFolder',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsOpenAddMediaPopup(false)
    }
  }, [mediaFolders?.length])

  const handleClickFile = () => {
    if (!refInputFileUpload.current) {
      return
    }
    refInputFileUpload.current?.click()
    setIsOpenAddMediaPopup(false)
  }

  const acceptedFileTypes = useMemo(
    () =>
      [TYPE_FILE.VIDEOS, TYPE_FILE.IMAGES, TYPE_FILE.GAUSSIAN_SPLATTING].join(
        ', '
      ),
    []
  )

  const mediaTypes = useMemo(() => {
    switch (modeOfMediaLibPopup) {
      case MODE_OF_MEDIA_LIB_POPUP.BGM:
        return [TYPE_MEDIA.AUDIO]
      case MODE_OF_MEDIA_LIB_POPUP.IMAGE:
        return [TYPE_MEDIA.IMAGE]
      case MODE_OF_MEDIA_LIB_POPUP.OTHER_FILE_3D:
        return [
          TYPE_MEDIA.IMAGE,
          TYPE_MEDIA.VIDEO,
          TYPE_MEDIA.AUDIO,
          TYPE_MEDIA.PDF,
        ]
      default:
        return []
    }
  }, [modeOfMediaLibPopup])

  const uploadMediasToS3 = useCallback(
    async (files: FileList, projectId: number) => {
      for (const item of Array.from(files)) {
        const uploader = new S3MultipleUploader({
          file: item,
          projectId,
          is360: true,
        })
        const resSignedUrl = await uploader.initialize()
        if (resSignedUrl.data) {
          const { key, bucket } = uploader.getFileInfo()
          if (!key || !bucket) {
            throw new Error('Key or bucket invalid')
          }
          const mediaType = mediaLibHelper.detectMediaType(
            mediaLibHelper.detectFileType(item),
            true
          )
          const media: IMedia = {
            key,
            mediaType,
            name: item.name,
            status: TYPE_STATUS_UPLOAD.UPLOADING,
            progress: 0,
          }
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.ADD_MEDIA]({
              projectId,
              folderId: selectedFolderInMediaLibPopup,
              media,
            })
          )
          uploader.onProgress((event: IOnProgressFnEvent) => {
            dispatch(
              projectActions[TYPE_PROJECT.REDUCERS.UPDATE_MEDIA]({
                folderId: selectedFolderInMediaLibPopup,
                media: {
                  ...media,
                  progress: event.percentage,
                },
              })
            )
          })
          await uploader.start()

          await createRecordsOfMedias({
            projectId,
            fileInput: {
              name: item.name,
              mediaType,
              folderId: selectedFolderInMediaLibPopup,
              key,
              bucket,
            },
          })

          dispatch(
            projectActions[
              TYPE_PROJECT.REDUCERS.REMOVE_UPLOADING_MEDIAS_GLOBAL
            ]({
              projectId,
              media,
              folderId: selectedFolderInMediaLibPopup,
            })
          )
        } else {
          setShowErrorUpload(resSignedUrl.error.message)
        }
      }
    },
    [selectedFolderInMediaLibPopup, modeOfMediaLibPopup]
  )

  const createRecordsOfMedias = async (data: IRequestUploadMedia) => {
    const res = await dispatch(uploadMediaAction(data)).unwrap()
    if (res.error) {
      setShowErrorUpload(res.error.message)
    }

    if (res.data && mediaTypes.includes(res.data.mediaType)) {
      const id = res.data.resourceId
      const type = res.data.mediaType
      const url = res.data.url
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT]({
          id,
          type,
          url,
        })
      )
    }
  }
  const setShowErrorUpload = (key: string) => {
    updateCurrentStorage()
    switch (key) {
      case TYPE_ERROR.RESOURCE_IS_LOCKED:
        if (userProfile) {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_OVER_SPACE_S3](
              true
            )
          )
          break
        }
      case TYPE_ERROR.PUBLISH_PROJECT_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_PRJ_STORAGE_OVER_LIMIT](
            true
          )
        )
        break
      case TYPE_ERROR.ORGANIZATION_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_ORG_STORAGE_OVER_LIMIT](
            true
          )
        )
        break

      default:
        throw new Error()
    }
  }
  const handle360Degree = useCallback(
    async (files: FileList) => {
      const projectId = projectInfo?.projectId
      if (!files || !projectId) {
        return
      }

      const filteredFiles = Array.from(files).filter(
        (file) =>
          file.type !== 'application/pdf' && !file.type.startsWith('audio/')
      )
      if (filteredFiles.length === 0) {
        return
      }

      try {
        await uploadMediasToS3(files, projectId)
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getMapInfos',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    },
    [projectInfo, dispatch, t]
  )

  return (
    <>
      <Popup
        open={isOpenAddMediaPopup}
        close={() => setIsOpenAddMediaPopup(false)}
        claassNamePopup="w-[150px] mr-2 rounded-lg"
        position={position}
        content={
          <div className="flex flex-col font-lato">
            {position === 'leftMediaPopup' && (
              <>
                <Button.MenuItem
                  icon={<img src={videoPhotoIcon} alt="" />}
                  title={t('popup.mediaLib.upload360video/image')}
                  onClick={handleClickFile}
                />
              </>
            )}
            <input
              type="file"
              ref={refInputFileUpload}
              accept={acceptedFileTypes}
              onChange={(e) => {
                if (!e.target.files) {
                  return
                }
                handle360Degree(e.target.files)
                e.target.value = ''
              }}
              hidden
              multiple
            />

            <Button.MenuItem
              icon={
                <img
                  src={
                    position === 'leftMediaPopup'
                      ? UploadIcon
                      : modeOfMediaLibPopup === 1
                      ? videoPhotoIcon
                      : UploadIcon
                  }
                  alt=""
                />
              }
              title={
                position === 'leftMediaPopup'
                  ? t('popup.mediaLib.uploadMediaFile')
                  : modeOfMediaLibPopup === 1
                  ? t('popup.mediaLib.upload360video/image')
                  : t('popup.mediaLib.uploadFiles')
              }
              onClick={openFileUploadWindow}
            />
            {position === 'leftMediaPopup' && (
              <>
                <div className="p-2">
                  <hr className="h-px border-none bg-[#FFFFFF] bg-opacity-10" />
                </div>
              </>
            )}
            <Button.MenuItem
              icon={<img src={FolderIcon} alt="" />}
              title={t('popup.mediaLib.newFolder')}
              onClick={handleCreateMediaFolder}
            />
          </div>
        }
      >
        <Icon.ButtonIcon
          icon={<img src={IconAdd} alt="" />}
          onClick={() => setIsOpenAddMediaPopup(true)}
        />
      </Popup>
    </>
  )
}

export default UploadMediaButton
