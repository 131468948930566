import { createSlice } from '@reduxjs/toolkit'
import {
  IMediaFolder,
  TYPE_MEDIA_TABS,
  MODE_OF_MEDIA_LIB_POPUP,
  IMedia,
  ISelectedMedia,
  IProcessUploadFile,
  IUploadFile,
  IUploadingMediasGlobal,
  IDragOverFolder,
} from '@models/media'
import {
  IResponseListProject,
  IResponseListProjectStorage,
  IResponseProjectInfo,
  IResponseProjectPublish,
  IWaitingS3Uploader,
  TAB,
  TypeFilterProject,
} from '@models/project'
import { helper } from '../../utils/helper/common'
import {
  createMediaFolderAction,
  createProjectAction,
  deleteMediaAction,
  deleteMediaFolderAction,
  getInfoProjectAction,
  getListProjectAction,
  getListProjectStorageAction,
  getMediaAction,
  getMediaFoldersAction,
  getProjectPublishAction,
  updateMediaAction,
  updateMediaFolderAction,
  updateProjectAction,
  updateProjectPublishAction,
  uploadMediaAction,
  availableStreamAction,
  updateMediaThumbnailAction,
} from './project.action'
import { TYPE_PROJECT } from './project.type'
import { projectHelper } from '@utils/helper/project'
import { localStorageUtils } from '@utils/localStorage'
import { MODE_VIEW_PROJECT } from '@constants/modeViewProject'

export interface ProjectState {
  isShowPopupCreateNewProject: boolean
  onTab: TAB
  isShowPopupAddMember: boolean
  isShowPopupMediaLib: boolean
  isLoading: boolean
  listProject: IResponseListProject
  isShowPopupSharingUrl: boolean
  projectInfo: IResponseProjectInfo | null
  listMedia: IMedia[]
  mediaFolders: IMediaFolder[]
  tabMedia: TYPE_MEDIA_TABS
  mediaSelected: {
    id: number
    type: string
    url: string
  } | null
  modeViewProject: boolean
  selectedFolderInMediaLibPopup: number | null
  selectedFolderInAsset: number | null
  selectedMediaInAsset: ISelectedMedia | null
  editedItemNameInAsset: string
  modeOfMediaLibPopup: MODE_OF_MEDIA_LIB_POPUP
  isShowMediaLibPopUp: boolean
  typeFilterListProject: string
  processListFileUpload: Array<IProcessUploadFile> | null
  fileUploading: IUploadFile | null
  sessionComplete: string | null
  uploadingMediasGlobal: IUploadingMediasGlobal[]
  dragOverFolder: IDragOverFolder | null
  isSettingPublish: boolean
  projectPublishInfo: IResponseProjectPublish | null
  isShowAlertOverSpaceS3: boolean
  isShowAlertExpiredTimeTrial: boolean
  isOrgStorageOverLimit: boolean
  isPrjStorageOverLimit: boolean
  waitingS3Uploader: IWaitingS3Uploader
  folderCreateNewRecord: number | null
  isStorageChanged: boolean
  isShowPublishSuccess: boolean
}

const initialState: ProjectState = {
  isShowPopupCreateNewProject: false,
  onTab: TAB.TAB_GENERAL_SETTING,
  isShowPopupAddMember: false,
  isShowPopupMediaLib: false,
  isLoading: false,
  listProject: {
    projects: [],
    pager: null,
  },
  isShowPopupSharingUrl: false,
  projectInfo: null,
  listMedia: [],
  tabMedia: TYPE_MEDIA_TABS.VIDEO360,
  mediaSelected: null,
  modeViewProject:
    localStorageUtils.getValueFromLocalStorage(MODE_VIEW_PROJECT.KEY) ===
      null ||
    localStorageUtils.getValueFromLocalStorage(MODE_VIEW_PROJECT.KEY) ===
      MODE_VIEW_PROJECT.TRUE,
  mediaFolders: [],
  selectedFolderInMediaLibPopup: null,
  selectedFolderInAsset: null,
  selectedMediaInAsset: null,
  editedItemNameInAsset: '',
  typeFilterListProject: TypeFilterProject.YOUR_PROJECT,
  modeOfMediaLibPopup: MODE_OF_MEDIA_LIB_POPUP.OFF,
  isShowMediaLibPopUp: false,
  processListFileUpload: null,
  fileUploading: null,
  sessionComplete: null,
  uploadingMediasGlobal: [],
  dragOverFolder: null,
  isSettingPublish: false,
  projectPublishInfo: null,
  isShowAlertOverSpaceS3: false,
  isShowAlertExpiredTimeTrial: false,
  isOrgStorageOverLimit: false,
  isPrjStorageOverLimit: false,
  waitingS3Uploader: {
    isUploading: false,
    uploaders: [],
  },
  folderCreateNewRecord: null,
  isStorageChanged: false,
  isShowPublishSuccess: false,
}

export const projectReducer = createSlice({
  name: 'project',
  initialState,
  reducers: {
    [TYPE_PROJECT.REDUCERS.SET_STATUS_POPUP_CREATE_NEW_PROJECT]: (state) => {
      state.isShowPopupCreateNewProject = !state.isShowPopupCreateNewProject
    },
    [TYPE_PROJECT.REDUCERS.SET_TAB]: (state, action) => {
      state.onTab = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_ADD_MEMBER]: (state) => {
      state.isShowPopupAddMember = !state.isShowPopupAddMember
    },
    [TYPE_PROJECT.REDUCERS.SET_STATUS_POPUP_MEDIA_LIB]: (state) => {
      state.isShowPopupMediaLib = !state.isShowPopupMediaLib
    },
    [TYPE_PROJECT.REDUCERS.SET_STATUS_POPUP_SHARING_URL]: (state) => {
      state.isShowPopupSharingUrl = !state.isShowPopupSharingUrl
    },
    [TYPE_PROJECT.REDUCERS.SET_LIST_PROJECT]: (state, action) => {
      state.listProject = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT]: (state, action) => {
      state.mediaSelected = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_MEDIA_LIB_POPUP]: (
      state,
      action
    ) => {
      state.selectedFolderInMediaLibPopup = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_ASSET]: (state, action) => {
      state.selectedMediaInAsset = null
      state.selectedFolderInAsset = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_SELECTED_MEDIA_IN_ASSET]: (state, action) => {
      state.selectedFolderInAsset = null
      state.selectedMediaInAsset = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_EDITED_ITEM_NAME_IN_ASSET]: (state, action) => {
      state.editedItemNameInAsset = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP]: (state, action) => {
      state.modeOfMediaLibPopup = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_MODE_VIEW_PROJECT]: (state, action) => {
      state.modeViewProject = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_TYPE_FILTER_LIST_PROJECT]: (state, action) => {
      state.typeFilterListProject = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_LIST_FILE_UPLOAD_WAITTING]: (state, action) => {
      state.processListFileUpload = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_FILE_UPLOADING]: (state, action) => {
      state.fileUploading = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_FILE_COMPLETE]: (state, action) => {
      state.sessionComplete = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_IS_SHOW_PUBLISH_SUCCESS]: (state, action) => {
      state.isShowPublishSuccess = action.payload
    },
    [TYPE_PROJECT.REDUCERS.ADD_MEDIA]: (state, action) => {
      const { projectId, folderId = null, media } = action.payload
      state.uploadingMediasGlobal = helper.addUploadingMediasGlobal(
        state.uploadingMediasGlobal,
        {
          projectId,
          medias: [media],
          folderId,
        }
      )
      if (folderId) {
        const mediaFolders = helper.addChildrenToMediaFolders(
          state.mediaFolders,
          folderId,
          [media]
        )
        state.mediaFolders = mediaFolders
      } else {
        const sortedData = [media, ...state.listMedia].sort(helper.sortMedias)
        state.listMedia = sortedData
      }
    },
    [TYPE_PROJECT.REDUCERS.UPDATE_MEDIA]: (state, action) => {
      const { folderId, media } = action.payload

      if (folderId) {
        state.mediaFolders = helper.updateMediasInMediaFolders(
          state.mediaFolders,
          [media]
        )
      } else {
        state.listMedia = helper.updateMediasInListMedias(state.listMedia, [
          media,
        ])
      }
    },
    [TYPE_PROJECT.REDUCERS.REMOVE_MEDIA]: (state, action) => {
      const media = action.payload

      if (media?.folderId) {
        const newMediaFolders = helper.removeMediaFromFolders(
          state.mediaFolders,
          media
        )
        state.mediaFolders = newMediaFolders
      } else {
        const newListMedia = state.listMedia.filter(
          (data) =>
            data.key.replace('360/', '') !== media?.key.replace('360/', '')
        )

        state.listMedia = newListMedia
      }
    },
    [TYPE_PROJECT.REDUCERS.REMOVE_UPLOADING_MEDIAS_GLOBAL]: (state, action) => {
      const { projectId, media, folderId = null } = action.payload
      state.uploadingMediasGlobal = helper.removeUploadingMediasGlobal(
        state.uploadingMediasGlobal,
        {
          projectId,
          medias: [media],
          folderId,
        }
      )
    },
    [TYPE_PROJECT.REDUCERS.MOVE_MEDIA]: (state, action) => {
      const media: ISelectedMedia = action.payload.media
      const folderIdTarget: number | null = action.payload.folderIdTarget
      let movingMedia: IMedia | undefined

      if (media.folderId) {
        const folder = state.mediaFolders.find(
          (folder) => folder.id === media.folderId
        )
        if (!folder) {
          throw new Error('Folder not found')
        }

        movingMedia = folder.children.find((data) => data.key === media.key)

        folder.children = folder.children.filter(
          (child) => child.key !== media.key
        )
      } else {
        movingMedia = state.listMedia.find((data) => data.key === media.key)
        state.listMedia = state.listMedia.filter(
          (data) => data.key !== media.key
        )
      }

      if (!movingMedia) {
        throw new Error('Media not found')
      }

      if (folderIdTarget) {
        const folder = state.mediaFolders.find(
          (folder) => folder.id === folderIdTarget
        )
        if (!folder) {
          throw new Error('Folder not found')
        }

        folder.children = [...(folder?.children ?? []), movingMedia]
        folder.children.sort(helper.sortMedias)
      } else {
        state.listMedia = [...state.listMedia, movingMedia]
        state.listMedia.sort(helper.sortMedias)
      }
    },
    [TYPE_PROJECT.REDUCERS.SET_DRAG_OVER_FOLDER]: (state, action) => {
      state.dragOverFolder = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_OPEN_SETTING_PUBLISH]: (state, action) => {
      state.isSettingPublish = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_OVER_SPACE_S3]: (state, action) => {
      state.isShowAlertOverSpaceS3 = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL]: (state, action) => {
      state.isShowAlertExpiredTimeTrial = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_IS_ORG_STORAGE_OVER_LIMIT]: (state, action) => {
      state.isOrgStorageOverLimit = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_IS_PRJ_STORAGE_OVER_LIMIT]: (state, action) => {
      state.isPrjStorageOverLimit = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_FOLDER_CREATE_NERW_RECORD]: (state, action) => {
      state.folderCreateNewRecord = action.payload
    },
    [TYPE_PROJECT.REDUCERS.SET_IS_STORAGE_CHANGED]: (state, action) => {
      state.isStorageChanged = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProjectAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(createProjectAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        if (state.listProject.pager) {
          return {
            ...state,
            isLoading: false,
            projectInfo: data,
          }
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(createProjectAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(getListProjectAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getListProjectAction.fulfilled, (state, { payload }) => {
      const data = payload.data as IResponseListProject
      const { projects, pager } = data
      if (data && projects && pager) {
        if (pager.skip === 0) {
          return {
            ...state,
            isLoading: false,
            listProject: {
              projects: projects,
              pager: pager,
            },
          }
        }

        return {
          ...state,
          isLoading: false,
          listProject: {
            projects: [...state.listProject.projects, ...projects],
            pager: pager,
          },
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getListProjectAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(getListProjectStorageAction.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(
      getListProjectStorageAction.fulfilled,
      (state, { payload }) => {
        const data = payload.data as IResponseListProjectStorage
        const { projects } = data
        const oldProjects = state.listProject.projects
        if (data && projects) {
          const newProjects = oldProjects.map((oldProject) => {
            const project = projects.find(
              (project) =>
                project.projectId === oldProject.projectId &&
                project.currentProjectStorage !==
                  oldProject.currentProjectStorage
            )
            if (project) {
              return {
                ...oldProject,
                currentProjectStorage: project.currentProjectStorage,
              }
            }
            return oldProject
          })
          return {
            ...state,
            isLoading: false,
            listProject: {
              projects: newProjects,
              pager: state.listProject.pager,
            },
          }
        }
        return {
          ...state,
          isLoading: false,
        }
      }
    )
    builder.addCase(getListProjectStorageAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(getInfoProjectAction.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getInfoProjectAction.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        projectInfo: payload.data && payload.data,
      }
    })
    builder.addCase(getInfoProjectAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(updateProjectAction.pending, (state) => ({
      ...state,
    }))
    builder.addCase(updateProjectAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        const newListProject = helper.updateListProject(state.listProject, {
          projectId: data.projectId as number,
          publishStatus: data.publishStatus,
          description: data.projectDescription,
          projectName: data.projectName,
        })
        return {
          ...state,
          projectInfo: data,
          listProject: newListProject,
        }
      }
    })
    builder.addCase(updateProjectAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    //get list media
    builder.addCase(getMediaAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getMediaAction.fulfilled, (state, { payload }) => {
      const { data: medias, folderId = null } = payload.data
      medias.sort(helper.sortMedias)
      if (state.projectInfo) {
        const mixedMedias = helper.mixUploadingMediasWithMedias(
          state.uploadingMediasGlobal,
          {
            projectId: state.projectInfo.projectId,
            medias: medias,
            folderId,
          }
        )

        if (folderId) {
          const mediaFolders = helper.replaceChildrenInMediaFolders(
            state.mediaFolders,
            folderId,
            mixedMedias
          )

          return {
            ...state,
            mediaFolders,
            isLoading: false,
          }
        }

        return {
          ...state,
          isLoading: false,
          listMedia: mixedMedias,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getMediaAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    //delete media
    builder.addCase(deleteMediaAction.pending, (state) => ({
      ...state,
    }))
    builder.addCase(deleteMediaAction.fulfilled, (state) => {
      const { listMedia, selectedMediaInAsset } = state
      if (selectedMediaInAsset?.folderId) {
        const newMediaFolders = helper.removeMediaFromFolders(
          state.mediaFolders,
          selectedMediaInAsset
        )
        return {
          ...state,
          mediaFolders: newMediaFolders,
          selectedMediaInAsset: null,
        }
      }

      const newData = listMedia.filter(
        (data) => data.key !== selectedMediaInAsset?.key
      )

      return {
        ...state,
        listMedia: newData,
        selectedMediaInAsset: null,
      }
    })
    builder.addCase(deleteMediaAction.rejected, (state) => {
      return {
        ...state,
      }
    })

    //update media
    builder.addCase(updateMediaAction.pending, (state) => ({
      ...state,
    }))
    builder.addCase(updateMediaAction.fulfilled, (state, { payload }) => {
      const data = payload.data

      if (state.selectedMediaInAsset?.folderId) {
        const newMediaFolders = helper.updateMediasInMediaFolders(
          state.mediaFolders,
          [data]
        )
        return {
          ...state,
          mediaFolders: newMediaFolders,
        }
      }

      const newMedias = helper.updateMediasInListMedias(state.listMedia, [data])
      return {
        ...state,
        listMedia: [...newMedias],
      }
    })
    builder.addCase(updateMediaAction.rejected, (state) => {
      return {
        ...state,
      }
    })

    //upload media files
    builder.addCase(uploadMediaAction.pending, (state) => ({
      ...state,
    }))
    builder.addCase(uploadMediaAction.fulfilled, (state, { payload }) => {
      const markedMedia = helper.markStatusForMedias(payload.data)
      if (payload.data?.folderId) {
        const newMediaFolders = helper.updateMediasInMediaFolders(
          state.mediaFolders,
          [markedMedia]
        )
        return {
          ...state,
          mediaFolders: newMediaFolders,
        }
      }

      const newMedias = helper.updateMediasInListMedias(state.listMedia, [
        markedMedia,
      ])

      return {
        ...state,
        listMedia: [...newMedias],
      }
    })
    builder.addCase(uploadMediaAction.rejected, (state) => {
      return {
        ...state,
      }
    })

    builder.addCase(
      updateMediaThumbnailAction.fulfilled,
      (state, { payload }) => {
        const data = payload.data

        return {
          ...state,
          listMedia: state.listMedia.map((media) => {
            if (
              media.resourceId === data.resourceId &&
              media.mediaType === data.mediaType
            ) {
              return {
                ...media,
                thumbnail: data.thumbnail,
              }
            }
            return media
          }),
        }
      }
    )

    builder.addCase(getMediaFoldersAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getMediaFoldersAction.fulfilled, (state, { payload }) => {
      const mediaFolders = payload.data.sort(
        (a: IMediaFolder, b: IMediaFolder) => a.name.localeCompare(b.name)
      )

      return {
        ...state,
        mediaFolders,
        isLoading: false,
      }
    })
    builder.addCase(getMediaFoldersAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(createMediaFolderAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(createMediaFolderAction.fulfilled, (state, { payload }) => {
      const mediaFolders = [...state.mediaFolders, payload.data]
      mediaFolders.sort((a: IMediaFolder, b: IMediaFolder) =>
        a.name.localeCompare(b.name)
      )

      return {
        ...state,
        mediaFolders: [...state.mediaFolders, payload.data],
        isLoading: false,
      }
    })
    builder.addCase(createMediaFolderAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(deleteMediaFolderAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(deleteMediaFolderAction.fulfilled, (state) => {
      const newMediaFolders = state.mediaFolders.filter(
        (folder) => folder.id !== state.selectedFolderInAsset
      )

      return {
        ...state,
        mediaFolders: [...newMediaFolders],
        isLoading: false,
        selectedFolderInAsset: null,
      }
    })
    builder.addCase(deleteMediaFolderAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })

    builder.addCase(updateMediaFolderAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(updateMediaFolderAction.fulfilled, (state, { payload }) => {
      const { id, name } = payload.data
      const newMediaFolders = helper.updateMediaFolder(
        state.mediaFolders,
        id,
        name
      )

      return {
        ...state,
        mediaFolders: [...newMediaFolders],
        isLoading: false,
      }
    })
    builder.addCase(updateMediaFolderAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //get project publish
    builder.addCase(getProjectPublishAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getProjectPublishAction.fulfilled, (state, { payload }) => {
      if (payload.data) {
        return {
          ...state,
          projectPublishInfo: payload.data,
          isLoading: false,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getProjectPublishAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //update project publish
    builder.addCase(updateProjectPublishAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(
      updateProjectPublishAction.fulfilled,
      (state, { payload }) => {
        if (payload.data) {
          const data = payload.data as IResponseProjectPublish
          const newListProject = projectHelper.updateStatusProjectOfList(
            state.listProject.projects,
            data.projectId,
            data.status
          )
          return {
            ...state,
            projectPublishInfo: payload.data,
            listProject: {
              ...state.listProject,
              projects: newListProject,
            },
            isLoading: false,
          }
        }
        return {
          ...state,
          isLoading: false,
        }
      }
    )
    builder.addCase(updateProjectPublishAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //available stream
    builder.addCase(availableStreamAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(availableStreamAction.fulfilled, (state, { payload }) => {
      if (payload.data) {
        return {
          ...state,
          isLoading: false,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(availableStreamAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
  },
})

export const { actions } = projectReducer

export default projectReducer.reducer
