import React, { useMemo } from 'react'
import { useTable, Column } from 'react-table'
import moment from 'moment'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '@utils/helper/storage'
import classNames from 'classnames'

interface IInvoice {
  createdAt: string
  planName: string
  planNameEn: string
  storage: string
  total: number
  currency: string
  invoiceUrl?: string
  billingPeriodType?: string
}

interface IBillingHistoryData {
  invoices: IInvoice[]
  upcomingInvoices?: IInvoice
}

interface IBillingTableProps {
  billingHistory: IBillingHistoryData
}

const BillingHistoryTable: React.FC<IBillingTableProps> = ({
  billingHistory,
}) => {
  const { t } = useTranslation()
  const lang = i18next.language

  const data = useMemo(() => {
    const allInvoices = billingHistory?.invoices || []
    const upcomingInvoices = billingHistory?.upcomingInvoices || []

    // Transform upcoming invoices (ensure createdAt is null and other adjustments)
    const transformedUpcomingInvoices = upcomingInvoices.map((invoice) => ({
      ...invoice,
      createdAt: null, // No date for upcoming invoices
      invoiceUrl: invoice.invoiceUrl || null, // Ensure this field exists
    }))

    // Merge upcoming invoices with past invoices
    return [...transformedUpcomingInvoices, ...allInvoices]
  }, [billingHistory])

  // Define table columns
  const tableColumns = useMemo<Column<IInvoice>[]>(
    () => [
      {
        Header: t('profileSetting.billing.date'),
        accessor: 'startTimestamp',
        Cell: ({ cell: { value } }) =>
          value
            ? moment
                .unix(Number(value))
                .format(lang === 'ja' ? 'YYYY年MM月DD日' : 'MMM DD, YYYY')
            : '-',
      },
      {
        Header: t('common.plan'),
        accessor: lang === 'ja' ? 'planName' : 'planNameEn',
        Cell: ({ cell: { value } }) => <>{capitalizeFirstLetter(value)}</>,
      },
      {
        Header: t('profileSetting.billing.storage'),
        accessor: 'storage',
        Cell: ({ cell: { value } }) => <>{value || '__'}</>,
      },
      {
        Header: t('profileSetting.billing.price'),
        accessor: 'total',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const isJPY = original.currency.toLowerCase() === 'jpy'
          const price = isJPY
            ? original.total // JPY doesn't need adjustment
            : original.total / 100 // USD requires division by 100

          const formattedAmount = isJPY
            ? `¥ ${price.toLocaleString('ja-JP')}` // No decimals for JPY
            : `$${Number.isInteger(price) ? price : price.toFixed(2)}` // Remove .00 for integer USD

          return <>{formattedAmount}</>
        },
      },

      {
        accessor: 'invoiceUrl',
        Cell: ({ cell: { value }, row: { original } }) => {
          if (value) {
            // "View Invoice" with text color applied
            return (
              <div className="flex justify-center items-center">
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold text-xs leading-5 text-center font-montserrat"
                  style={{
                    color: 'var(--dark-blue-70, rgba(134, 189, 231, 1))',
                  }}
                >
                  {t('profileSetting.billing.invoiceDetail')}
                </a>
              </div>
            )
          } else if (original.billingPeriodType) {
            // Handle the "Upcoming" display side by side
            const periodText =
              original.billingPeriodType === 'byYear'
                ? t('profileSetting.billing.annual')
                : t('profileSetting.billing.monthly')
            return (
              <div className="flex justify-center items-center space-x-2 ">
                <span className="font-normal text-xs leading-5 text-center font-lato text-[#c2c2c2]">
                  {t('profileSetting.billing.upcoming')}
                  {periodText}
                </span>
              </div>
            )
          } else {
            return (
              <div className="flex justify-center items-center">
                <span className="font-semibold text-xs leading-5 text-center font-lato text-[#c2c2c2]">
                  {t('profileSetting.billing.upcoming')}
                </span>
              </div>
            )
          }
        },
      },
    ],
    [lang, t]
  )

  const tableInstance = useTable({
    columns: tableColumns,
    data,
  })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="w-full text-white__op-900">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              className="text-left border-b border-white__op-50 text-sm"
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  className={`py-3 ${
                    ['storage', 'total'].includes(column.id)
                      ? 'text-right'
                      : 'text-left'
                  }`}
                  key={index}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="font-normal border-t border-white__op-50 text-sm leading-6"
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className={classNames(
                        'py-3',
                        {
                          'text-right': ['storage', 'total'].includes(
                            cell.column.id
                          ),
                        },
                        {
                          'text-left': !['storage', 'total'].includes(
                            cell.column.id
                          ),
                        },
                        {
                          'font-lato': cell.column.id !== 'invoiceUrl',
                        }
                      )}
                      key={index}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })
          ) : (
            <tr>
              <td
                colSpan={tableColumns.length}
                className="py-4 text-center text-white__op-900"
              >
                {t('profileSetting.billing.noData')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default BillingHistoryTable
