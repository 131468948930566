import { gql } from '@apollo/client'

export const UPDATE_PAYMENT_CARD = gql`
  mutation updatePaymentCard($input: UpdatePaymentCardInput!) {
    updatePaymentCard(input: $input) {
      message
      statusCode
      error {
        errorCode
        message
      }
    }
  }
`

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input) {
      message
      statusCode
      data {
        sessionId
      }
      error {
        errorCode
        message
      }
    }
  }
`
