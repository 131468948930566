import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ILayer,
  IRequestGetScene,
  IRequestUpdateScene,
  IScene,
  ISpace,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { getSceneAction, updateSceneAction } from '@stores/scene/scene.action'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { IRequestUpdateProject } from '@models/project'
import { updateProjectAction } from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE, TypeLanguage } from '@models/common'
import { helper } from '@utils/helper/common'
import Input from '@components/common/Input'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { actions } from '@stores/DragDrop/dragDropSlice'
import { v4 as uuidv4 } from 'uuid'

interface IPropsType {
  itemLayer: ILayer
  itemSpace: ISpace
  itemScene: IScene
  isEditing: boolean
  closeUpdateName: () => void
  isSelected: boolean
}
const SceneComponent: React.FC<IPropsType> = (props) => {
  const {
    itemLayer,
    itemSpace,
    itemScene,
    isEditing,
    closeUpdateName,
    isSelected,
  } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    activeView,
    dataEditContextMenuLSS,
    dataSpaceAndScene,
    dataTypeEdit,
    contextCodec,
  } = useAppSelector((state: RootState) => state.scene)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [sceneName, setSceneName] = useState<string>(
    dataEditContextMenuLSS?.data.title ?? ''
  )
  const [isDragging, setIsDragging] = useState(false)

  const handleDragStart = (e: React.DragEvent) => {
    const uniquePart = uuidv4()
    const uniqueId = uniquePart
    e.dataTransfer.setData('text/plain', uniqueId)
    const dragImage = document.createElement('img')
    dragImage.src = itemScene.info.thumbnailUrl
    dragImage.classList.add('h-28', 'w-28', 'border-2', 'rounded-lg')
    dragImage.style.position = 'absolute'
    dragImage.style.top = '-9999px'
    document.body.appendChild(dragImage)
    setTimeout(() => {
      document.body.removeChild(dragImage)
    }, 0)
    e.dataTransfer.setDragImage(dragImage, 0, 0)
    document.body.appendChild(dragImage)
    dispatch(actions.startDrag(itemScene.info))
    dispatch(actions.isInfoItem(itemScene.info))
    dispatch(actions.dropId(uniqueId))
    dispatch(actions.isDragging(true))
  }

  const handleDragEnd = () => {
    dispatch(actions.isDragging(false))
    dispatch(actions.clearDrop(null))
  }

  useEffect(() => {
    if (
      dataEditContextMenuLSS &&
      dataEditContextMenuLSS.type === TYPE_SETTING.SCENE
    ) {
      setSceneName(dataEditContextMenuLSS.data.title)
    }
  }, [dataEditContextMenuLSS])

  const handleSelectScene = async (
    layer: ILayer,
    space: ISpace,
    scene: IScene
  ) => {
    if (
      projectInfo &&
      (scene.info.id !== activeView.scene?.info.id ||
        dataTypeEdit.isTypeEdit !== TYPE_SETTING.SCENE)
    ) {
      const dataGetScene: IRequestGetScene = {
        projectId: projectInfo.projectId,
        sceneId: scene.info.id,
        contextCodec,
      }
      try {
        const resScene = await dispatch(getSceneAction(dataGetScene)).unwrap()
        if (resScene.data) {
          const dataTypeEdit = {
            isTypeEdit: TYPE_SETTING.SCENE,
            data: resScene.data,
          }
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](dataTypeEdit)
          )
          setActiveScene(layer, space, scene)
        }
      } catch {
        openNotification({
          type: TYPE.ERROR,
          key: 'getScene',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const setActiveScene = async (
    layer: ILayer,
    space: ISpace,
    scene: IScene
  ) => {
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
        type: TYPE_RESOURCE_OF_PROJECT.SCENE,
        layer,
        space,
        scene,
      })
    )
    if (projectInfo) {
      await updateProject(projectInfo.projectId, {
        defaultSceneSet: {
          layerId: layer.info.id,
          spaceId: space.info.id,
          sceneId: scene.info.id,
        },
      })
    }
  }

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const onHandleShowRightCLick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    scene: IScene
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const xPos = event.pageX + 'px'
    const yPos = event.pageY + 'px'
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_POSITION_CONTEXT_MENU_LSS]({
        xPos,
        yPos,
      })
    )
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_OPEN_CONTEXT_MENU_LSS](true))
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_EDIT_CONTEXT_MENU_LSS]({
        type: TYPE_SETTING.SCENE,
        data: {
          id: scene.info.id,
          title: scene.info.title,
        },
      })
    )
  }

  const handleChangeSceneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSceneName(e.target.value)
  }

  const handleKeyDownSceneName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateSceneName()
    }
  }

  const handleUpdateSceneName = async () => {
    if (
      helper.removeWhiteSpaceStart(sceneName) &&
      dataEditContextMenuLSS &&
      sceneName !== dataEditContextMenuLSS.data.title &&
      user &&
      projectInfo
    ) {
      const data: IRequestUpdateScene = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataEditContextMenuLSS.data.id,
        title: helper.removeWhiteSpaceStart(sceneName),
        langCode: TypeLanguage.ENLISH,
        contextCodec,
      }
      const res = await updateScene(data)
      if (res && dataSpaceAndScene) {
        const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
          projectId: projectInfo.projectId,
          dataVersion: {
            version: res.version,
            data: {
              type: TYPE_SETTING.SCENE,
              id: res.sceneId,
              action: TypeActionUndoRedo.UPDATE,
              listLayer: dataSpaceAndScene,
            },
          },
        })
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
            isUndo,
            isRedo,
          })
        )
      }
    }
    setSceneName(dataEditContextMenuLSS?.data.title || '')
    closeUpdateName()
  }

  const updateScene = async (dataReq: IRequestUpdateScene) => {
    const res = await dispatch(updateSceneAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data
    }
  }

  const genColor = () => {
    if (
      projectInfo &&
      projectInfo.defaultSceneSet.spaceId === itemSpace.info.id
    ) {
      if (projectInfo.defaultSceneSet.sceneId !== itemScene.info.id) {
        return 'bg-blue-800'
      }
      return 'bg-blue-700'
    } else {
      if (
        activeView.type === TYPE_RESOURCE_OF_PROJECT.SCENE &&
        activeView.scene?.info.id === itemScene.info.id
      ) {
        return 'bg-blue-700'
      }
      if (
        activeView.type !== TYPE_RESOURCE_OF_PROJECT.SCENE ||
        activeView.scene?.info.id !== itemScene.info.id
      ) {
        return 'hover:bg-white__op-50'
      }
    }
  }

  return (
    <div className="relative cursor-pointer">
      <div
        className={classNames(
          'py-2 pl-10 pr-4 group text-white flex justify-between',
          genColor(),
          {
            ' bg-blue-700': isSelected,
          }
        )}
        id={`h${TYPE_RESOURCE_OF_PROJECT.SCENE}${itemScene.info.id}`}
        onMouseUp={() => handleSelectScene(itemLayer, itemSpace, itemScene)}
        onContextMenu={(event) => onHandleShowRightCLick(event, itemScene)}
        onDragStart={handleDragStart}
        draggable
        onDragOver={(event) => event.preventDefault()}
        onDragEnd={handleDragEnd}
        ata-index={itemScene.info.id}
      >
        <div className="flex items-center w-full">
          <div className="w-4 h-4 bg-white__op-300 rounded-sm overflow-hidden">
            {itemScene.info.thumbnailUrl && (
              <img
                src={itemScene.info.thumbnailUrl}
                className="W-full h-full object-cover"
                alt="scene thumbnail"
              />
            )}
          </div>
          {isEditing ? (
            <div className="w-full ml-2">
              <Input
                className="!py-0 !pl-2 !pr-3 box-border !leading-5 !text-xs"
                customClassContainer="!py-0 !leading-4"
                color="transparent"
                type="text"
                value={sceneName}
                onChange={handleChangeSceneName}
                onBlur={handleUpdateSceneName}
                handleKeyDown={handleKeyDownSceneName}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                isValid={helper.removeWhiteSpaceStart(sceneName) ? false : true}
              />
            </div>
          ) : (
            <div className="text-white text-sm cursor-pointer w-full pl-2 font-lato truncate">
              {itemScene.info.title}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SceneComponent
