import React, { useEffect, useState } from 'react'
import { actions as projectActions } from '@stores/project/project.reducer'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP, TYPE_CONTEXT } from '@models/media'
import { RootState } from '@stores/store'
import { helper } from '@utils/helper/common'
import { S3MultipleUploader } from '../../../../api/s3'
import Icon from '@components/common/Icon'
import IconFolder from '@assets/icons/sceneSetting/folder.svg'
import IconCamera from '@assets/icons/sceneSetting/camera.svg'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import {
  DEFAULT_WIDTH_RIGHT_SETTING,
  REF_SELECT_MEDIA,
} from '@constants/sceneSetting'
import { useTranslation } from 'react-i18next'
import { usePlayerActions } from '../../../../../src/hooks/usePlayerActions'

interface IPropsTypes {
  projectThumb: string
  updateProjectThumb: (mediaKey: number | string) => void
  contextType?: string
}
const ThumbProjectComponent: React.FC<IPropsTypes> = (props) => {
  const { projectThumb, updateProjectThumb, contextType } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { mediaSelected, projectInfo, modeOfMediaLibPopup } = useAppSelector(
    (state: RootState) => state.project
  )
  const [isUpdateThumb, setIsUpdateThumb] = useState<boolean>(false)
  const [previewThumb, setPreviewThumb] = useState<string>('')
  const { playerActions } = usePlayerActions()

  useEffect(() => {
    if (mediaSelected && isUpdateThumb && projectInfo) {
      updateProjectThumb(mediaSelected.id)
      setPreviewThumb(mediaSelected.url)
      setIsUpdateThumb(false)
      dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
    }
  }, [mediaSelected, isUpdateThumb])

  const handleOpenImageAsset = () => {
    if (modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OFF) {
      const thumbProjectElm = document.getElementById(
        REF_SELECT_MEDIA.THUMB_OF_PROJECT
      )
      if (thumbProjectElm) {
        let offsetTop = thumbProjectElm.offsetTop
        if (offsetTop + 480 > window.innerHeight) {
          offsetTop = window.innerHeight - 480
        }
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
            top: offsetTop,
            right: DEFAULT_WIDTH_RIGHT_SETTING.PROJECT + 4,
          })
        )
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.IMAGE
        )
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_REF_BUTTON_SELECT_MEDIA](
          REF_SELECT_MEDIA.THUMB_OF_PROJECT
        )
      )
      setIsUpdateThumb(true)
    } else {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
      dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
      setIsUpdateThumb(false)
    }
  }

  const capScreenShot = async () => {
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_IS_LOADING](true))
    let file
    if (contextType === TYPE_CONTEXT.GS && playerActions.current) {
      const base = playerActions.current.getScreenShot()
      if (!base) {
        throw new Error('screenShot is empty')
      }
      file = helper.base64toBlob(base)
    } else {
      const base = await helper.captureSceenShot('body-preview-scene')
      file = base as Blob
    }
    if (projectInfo?.projectId) {
      await getSignedUrld(file, projectInfo.projectId)
    }
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_IS_LOADING](false))
  }

  const getSignedUrld = async (file: File | Blob, projectId: number) => {
    const uploader = new S3MultipleUploader({
      file,
      projectId,
    })
    await uploader.start()
    const { key, bucket } = uploader.getFileInfo()
    if (!key || !bucket) {
      throw new Error('Key or bucket invalid')
    }
    if (key) {
      const url = URL.createObjectURL(file)
      updateProjectThumb(key)
      setPreviewThumb(url)
    }
  }

  const ButtonOpenAsset = () => (
    <button className="p-1" onClick={capScreenShot}>
      <div className="py-1.5 pl-2 pr-3 flex items-center justify-center">
        <Icon.ActionIcon
          customClass="mr-3"
          icon={<img src={IconCamera} alt="" />}
        />
        <span className="text-xs leading-5 font-semibold text-blue-300">
          {t('projectSetting.capture')}
        </span>
      </div>
    </button>
  )

  return (
    <div className="py-1.5 h-[13.75rem]" id={REF_SELECT_MEDIA.THUMB_OF_PROJECT}>
      {projectThumb || previewThumb ? (
        <div className="w-full h-full relative group rounded-lg overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={previewThumb || projectThumb}
            alt=""
          />
          <div className="absolute inset-0 bg-black__op-500 justify-between items-end w-full hidden group-hover:!flex">
            <div className="w-full flex items-center justify-between">
              <ButtonOpenAsset />
              <Icon.ButtonIcon
                icon={<img src={IconFolder} alt="" />}
                size="xs"
                onClick={handleOpenImageAsset}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-end bg-white__op-50 rounded-lg group-one">
          <div className="justify-between items-center w-full hidden group-one-hover:!flex">
            <ButtonOpenAsset />
            <Icon.ButtonIcon
              icon={<img src={IconFolder} alt="" />}
              size="sm"
              onClick={handleOpenImageAsset}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(ThumbProjectComponent)
