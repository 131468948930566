import { IPlayerActions } from '@actualinc/whereness_player_package'
import {
  createContext,
  MutableRefObject,
  useRef,
  ReactNode,
  useContext,
} from 'react'

export const PlayerActionsContext = createContext<{
  playerActions: MutableRefObject<IPlayerActions | null>
  setActionsRef: (actionsRef: MutableRefObject<IPlayerActions | null>) => void
}>({
  playerActions: { current: null },
  setActionsRef: () => {
    // do nothing
  },
})

export const PlayerActionsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const playerActions = useRef<IPlayerActions | null>(null)
  const setActionsRef = (
    actionsRef: MutableRefObject<IPlayerActions | null>
  ) => {
    playerActions.current = actionsRef.current
  }

  return (
    <PlayerActionsContext.Provider value={{ playerActions, setActionsRef }}>
      {children}
    </PlayerActionsContext.Provider>
  )
}

export const usePlayerActions = () => {
  const { playerActions, setActionsRef } = useContext(PlayerActionsContext)
  return { playerActions, setActionsRef }
}
