import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps {
  children: React.ReactNode
  container?: Element | DocumentFragment | string
}

const Portal: React.FC<PortalProps> = ({ children, container }) => {
  const [mountNode, setMountNode] = useState<Element | DocumentFragment | null>(
    null
  )

  useEffect(() => {
    if (typeof container === 'string') {
      setMountNode(document.querySelector(container))
    } else {
      setMountNode(container || document.body)
    }
    return () => {
      setMountNode(null)
    }
  }, [container])

  return mountNode ? ReactDOM.createPortal(children, mountNode) : null
}

export default Portal
