import { useTranslation } from 'react-i18next'
import {
  getUserPlanByUserIdAction,
  createCheckoutSessionAction,
  changeSubscriptionAction,
  cancelCmsSubscriptionAction,
  getDiscountDetailsAction,
} from '@stores/userPlan/userPlan.action'
import { getCurrentPlanAndCardInfoAction } from '@stores/organization/organization.action'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
// import { useDispatch } from 'react-redux'
import { openNotification } from '@utils/notification'
import { loadStripe } from '@stripe/stripe-js'
import { TYPE } from '@models/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { PATHNAME } from '@constants/common'
import IconTick from '@assets/icons/plan/tick.svg'
import i18next from 'i18next'
import SpinComponent from '@components/common/SpinComponent'
import Button from '@components/common/Button'

function PaymentConfirmation() {
  const { t, i18n } = useTranslation()
  const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY as string)
  const navigate = useNavigate()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [userPlan, setUserPlan] = useState<Record<string, any> | null>(null)
  const [priceDetails, setPriceDetails] = useState<Record<string, any> | null>(
    null
  )
  const [userCards, setUserCards] = useState<Record<string, any> | null>(null)
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const getUserPlanByUserId = async () => {
      if (typeof user?.userId === 'undefined') {
        return
      }
      try {
        const data = await dispatch(
          getUserPlanByUserIdAction(user?.userId)
        ).unwrap()
        setUserPlan(data)
        const data2 = await dispatch(
          getCurrentPlanAndCardInfoAction({
            organizationId: user?.organizationId,
            isCurrentPlanOnly: true,
            isGetCardOnly: true,
          })
        ).unwrap()
        setUserCards(data2)
        localStorage.setItem('userPlan', JSON.stringify(data))
        localStorage.setItem('userId', JSON.stringify(user?.userId))
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getUserPlanByUserId',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
    const getPirceDetails = async () => {
      try {
        const { service, peroidTypePackage } = location.state as {
          service: any
          peroidTypePackage: string
        }
        const priceId = getPriceId(service, peroidTypePackage)
        if (priceId === null || priceId === undefined) {
          const data = {
            currency: 'usd',
            discount: 0,
            total: 0,
            totalWithoutDiscount: 0,
          }
          setPriceDetails(data)
          return
        }
        const data = await dispatch(getDiscountDetailsAction(priceId)).unwrap()
        if (data?.error) {
          console.log('error', data.error)
          openNotification({
            type: TYPE.ERROR,
            key: 'getPirceDetails',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
          return
        }
        setPriceDetails(data.data)
      } catch (error) {
        console.log('error', error)
        openNotification({
          type: TYPE.ERROR,
          key: 'getPirceDetails',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
    getUserPlanByUserId()
    if (location.state) {
      getPirceDetails()
    }
  }, [dispatch, user, location])

  const handleCancle = () => {
    navigate(PATHNAME.PROFILE_SETTING)
  }

  function getPriceId(product: any, periodTypePackage: string) {
    console.log(product, periodTypePackage)
    if (periodTypePackage === 'monthlyPrice') {
      return product.monthlyPriceId
    } else if (periodTypePackage === 'yearlyPrice') {
      return product.yearlyPriceId
    } else {
      throw new Error('Invalid periodTypePackage')
    }
  }

  const handleCheckout = async () => {
    const stripe = await stripePromise
    if (!stripe) {
      throw new Error('Stripe failed to load')
    }

    const { service, peroidTypePackage } = location.state as {
      service: any
      peroidTypePackage: string
    }

    // Free plan with no card: Redirect to Stripe for checkout
    if (
      service?.planName !== 'Free' &&
      ['Free', 'TRIAL'].includes(userPlan?.plan.name) &&
      (!userCards?.cardInfo ||
        !userCards.cardInfo.brand ||
        !userCards.cardInfo.lastFour)
    ) {
      const priceId = getPriceId(service, peroidTypePackage)
      const cancelUrl = `${window.location.origin}${PATHNAME.PLAN}`
      const successUrl = `${window.location.origin}${PATHNAME.PAYMENT_SUCCESS}?session_id={CHECKOUT_SESSION_ID}`

      const data = await dispatch(
        createCheckoutSessionAction({ priceId, successUrl, cancelUrl })
      ).unwrap()

      return stripe.redirectToCheckout({ sessionId: data?.data.sessionId })
    }

    // Free plan with a card: Change the subscription directly (no redirect)
    if (
      service?.planName !== 'Free' &&
      userPlan?.plan.name === 'Free' &&
      userCards?.cardInfo &&
      userCards?.cardInfo.brand &&
      userCards?.cardInfo.lastFour
    ) {
      const priceId = getPriceId(service, peroidTypePackage)
      const data = await dispatch(
        changeSubscriptionAction({ priceId })
      ).unwrap()

      if (data.statusCode === 200 || data.message === 'Success') {
        navigate(PATHNAME.PROFILE_SETTING)
        return
      }
    }
    // Paid plan to Free plan: Cancel the subscription
    if (service?.planName === 'Free' && userPlan?.plan.name !== 'Free') {
      const data = await dispatch(cancelCmsSubscriptionAction()).unwrap()
      if (data.statusCode === 200 || data.message === 'Success') {
        navigate(PATHNAME.PROFILE_SETTING)
        return
      }
    }

    // Paid plan to Paid plan (upgrade or downgrade): Change the subscription
    if (service?.planName !== 'Free' && userPlan?.plan.name !== 'Free') {
      const priceId = getPriceId(service, peroidTypePackage)
      const data = await dispatch(
        changeSubscriptionAction({ priceId })
      ).unwrap()

      if (data.statusCode === 200 || data.message === 'Success') {
        navigate(PATHNAME.PROFILE_SETTING)
        return
      }
    }
  }

  const lang = i18next.language
  const checkoutDes = t('confirmPayment.CheckoutDes')
  const isEnglish = lang === 'en'
  const termsOfService = isEnglish ? 'Terms of Service' : '利用規約'
  const commercialTransactions = isEnglish
    ? 'Notice concerning the Act on Specified Commercial Transactions'
    : '特定商取引法に関する表示'

  const { service, peroidTypePackage } = location.state as {
    service: any
    peroidTypePackage: string
  }
  const currentDate = new Date()
  const endDate = new Date(currentDate)
  if (peroidTypePackage === 'monthlyPrice') {
    endDate.setMonth(currentDate.getMonth() + 1)
  } else if (peroidTypePackage === 'yearlyPrice') {
    endDate.setFullYear(currentDate.getFullYear() + 1)
  }

  const planName = service.planName
  const planDuration =
    peroidTypePackage === 'monthlyPrice' ? 'Monthly' : 'Yearly'

  const formatPrice = (amount: any, currency: string) => {
    const language = i18n.language
    const translatedCurrency = t(`currency.${currency}`, {
      defaultValue: currency,
    })

    // Adjust currency translation for Japanese (e.g., USD -> 米ドル)
    const currencyDisplay =
      language === 'ja' && currency === 'usd'
        ? translatedCurrency // e.g., 米ドル (Japanese for USD)
        : translatedCurrency.toUpperCase() // Default behavior

    const adjustedAmount = currency === 'usd' ? amount / 100 : amount

    // Determine the maximum fraction digits based on the currency and value
    const hasDecimals = adjustedAmount % 1 !== 0
    const fractionDigits = hasDecimals ? 2 : 0 // If there are decimals, show up to 2; otherwise, show 0.

    // Format the number
    const formattedAmount = new Intl.NumberFormat(
      language === 'ja' ? 'ja-JP' : 'en-US',
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionDigits,
      }
    ).format(adjustedAmount)

    return `${formattedAmount} ${currencyDisplay}`
  }

  const formatExpirationDate = (dateString: string) => {
    if (!dateString) return null // Return null for invalid inputs

    // Ensure the input is treated as a number (e.g., timestamp in milliseconds)
    const date = new Date(Number(dateString))
    if (isNaN(date.getTime())) {
      console.error(`Invalid date input:`, dateString)
      return 'Invalid Date'
    }

    // Use Intl.DateTimeFormat to customize the format
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    return new Intl.DateTimeFormat(i18n.language || 'en-US', options).format(
      date
    )
  }
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleButtonClick = async () => {
    setIsDisabled(true)
    setIsLoading(true) // Show loader
    await handleCheckout() // Call the checkout handler
    setIsLoading(false)
  }

  return (
    <>
      {priceDetails ? (
        <>
          <div className="flex justify-center items-center px-4">
            <div className="w-full max-w-[1200px] h-auto min-h-[900px] pb-0 p-0 gap-0 text-[rgba(255,255,255,0.97)]">
              <h2 className="font-montserrat font-semibold text-[36px] leading-[44px] tracking-[-0.01em] mb-4">
                {t('confirmPayment.detail')}
              </h2>
              <div className="flex flex-col md:flex-row md:justify-evenly py-2">
                <div className="w-full md:w-[720px] mb-4 md:mb-0">
                  <div className="mb-4">
                    <div className="w-full py-4 flex flex-col text-[rgba(255,255,255,0.97)] rounded-lg h-auto">
                      <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
                        {service?.planName !== 'Free' ? (
                          <>
                            <p className="font-montserrat text-[24px] md:text-[28px] font-semibold leading-[36px] flex justify-between md:gap-5">
                              {t(`plan.planDuration.${planDuration}`)}
                              <span className="font-lato text-[14px] font-normal leading-[24px] text-[rgba(255,255,255,0.97)] mt-2">
                                {formatExpirationDate(
                                  priceDetails?.startTimestamp
                                )}{' '}
                                -{' '}
                                {formatExpirationDate(
                                  priceDetails?.endTimestamp
                                )}
                              </span>
                            </p>
                          </>
                        ) : (
                          <p className="font-montserrat text-[24px] md:text-[28px] font-semibold leading-[36px] flex justify-between md:gap-5">
                            {t(`plan.planDuration.changePlanFree`)}
                            {/* <span className="font-lato text-[14px] font-normal leading-[24px] text-[rgba(255,255,255,0.97)] mt-2">
                            {formatExpirationDate(priceDetails?.startTimestamp)}{' '}
                            - {formatExpirationDate(priceDetails?.endTimestamp)}
                          </span> */}
                          </p>
                        )}
                        <p className="text-lg font-normal font-lato">
                          {service?.planName !== 'Free' ? (
                            <>
                              {formatPrice(
                                priceDetails?.totalWithoutDiscount,
                                priceDetails?.currency
                              )}
                            </>
                          ) : (
                            <>{t(`plan.${planName.toLowerCase()}`)}</>
                          )}
                        </p>
                      </div>
                      <div className="flex flex-col md:flex-row py-5 gap-3 md:gap-8">
                        <p className="font-lato text-[16px] font-normal leading-[28px] text-left">
                          {service?.planName !== 'Free' ? (
                            <>
                              {t(`plan.${planName.toLowerCase()}`)}
                              {t(
                                `plan.planDuration.${planDuration.toLowerCase()}`
                              )}
                            </>
                          ) : (
                            <>{t(`plan.${planName.toLowerCase()}`)}</>
                          )}
                        </p>
                        <p className="font-lato text-base font-normal leading-7 text-left">
                          {service?.planName !== 'Free' && (
                            <>
                              {formatPrice(
                                priceDetails?.totalWithoutDiscount,
                                priceDetails?.currency
                              )}
                            </>
                          )}
                        </p>
                      </div>
                      <hr className="border-white__op-50" />
                      <div className="max-w-full md:max-w-[720px] py-6 text-sm leading-relaxed">
                        <p className="font-lato text-[14px] font-normal leading-[24px] text-left text-white opacity-[.76]">
                          {checkoutDes.split(termsOfService)[0]}
                          <a
                            href={t('confirmPayment.termsOfServiceLink')}
                            target="_blank"
                            className="underline"
                            rel="noreferrer"
                          >
                            {termsOfService}
                          </a>
                          {
                            checkoutDes
                              .split(termsOfService)[1]
                              ?.split(commercialTransactions)[0]
                          }
                          <a
                            href={t(
                              'confirmPayment.commercialTransactionsLink'
                            )}
                            target="_blank"
                            className="underline"
                            rel="noreferrer"
                          >
                            {commercialTransactions}
                          </a>
                          {checkoutDes.split(commercialTransactions)[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-[470px] min-h-[144px] p-[12px_20px] md:p-[12px_40px]">
                  {priceDetails?.discount !== 0 && (
                    <div className="w-[470] h-[96px] opacity-100">
                      <p className="font-montserrat text-[16px] font-semibold leading-[24px] tracking-[-0.01em] text-left">
                        {t('confirmPayment.upgradeAdjuest')}
                      </p>
                      <div className="w-[390px] h-auto py-2 flex justify-between">
                        <div className="text-left font-lato text-[18px] font-normal leading-[32px]">
                          -
                          {formatPrice(
                            priceDetails?.discount,
                            priceDetails?.currency
                          )}
                        </div>
                        {/* <div className="text-left font-lato text-[12px] font-normal leading-[20px] mt-1">
                          1 month of Personal ({planDuration})
                        </div> */}
                      </div>
                    </div>
                  )}

                  {service?.planName !== 'Free' && (
                    <>
                      <div className="w-[470] h-[96px] opacity-100">
                        <p className="font-montserrat text-[16px] font-semibold leading-[24px] tracking-[-0.01em] text-left">
                          {t('confirmPayment.TotalAmount')}
                        </p>
                        <div className="w-[390px] h-auto py-2 flex justify-between">
                          <div className="text-left font-lato text-[18px] font-normal leading-[32px]">
                            {formatPrice(
                              priceDetails?.total,
                              priceDetails?.currency
                            )}
                          </div>
                          {priceDetails?.discount !== 0 && (
                            <>
                              <div className="text-left font-lato text-[12px] font-normal leading-[20px] mt-1">
                                {formatPrice(
                                  priceDetails?.totalWithoutDiscount,
                                  priceDetails?.currency
                                )}{' '}
                                -{' '}
                                {formatPrice(
                                  priceDetails?.discount,
                                  priceDetails?.currency
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <hr className="my-3 border-white__op-50 -mt-2" />
                    </>
                  )}

                  <div className="mt-6">
                    <Button.Normal
                      className="py-3 font-montserrat !text-base font-semibold rounded-full"
                      onClick={handleButtonClick}
                      disabled={isDisabled}
                      color="solid"
                    >
                      {isLoading ? (
                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      ) : (
                        <>
                          <img src={IconTick} alt="" className="mr-2" />
                          {t('confirmPayment.checkout')}
                        </>
                      )}
                    </Button.Normal>
                    <div className="mt-4">
                      <button
                        className="h-[44px] w-full md:w-[390px] py-3 bg-[rgba(255,255,255,0.1)] text-[rgba(142,194,245,1)] rounded-full font-montserrat text-[16px] font-semibold leading-[24px] hover:bg-white__op-100 active:bg-white__op-200"
                        onClick={handleCancle}
                      >
                        {t('common.cancel')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <SpinComponent />
        </>
      )}
    </>
  )
}

export default PaymentConfirmation
