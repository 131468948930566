//enum
export const enum ERROR_PROFILE {
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
}

export const enum TypeProfileSetting {
  ACCOUNT,
  NOTIFICATION,
  BILLING,
  REVENUE,
  PLAN_BILLING,
}

export const enum ERROR_PARTNER_CODE {
  INVALID_PARTNER_CODE = 'INVALID_PARTNER_CODE',
}

//request
export interface IDataEditProfile {
  fullName?: string
  avatar?: File | string | null
  langCode?: string
  password?: string
  email?: string
}

export interface IRequestPartnerCode {
  userId: number
  partnerCode: string
}

//response
export interface IInfo {
  fullName: string
  avatar: string
  langCode: string
  email: string
  userId: number
  status: number
  stepRegistration: number
  createdAt: Date
  updatedAt: Date
  organizationId: string
  organizationName: string
  organizationRole: string
  isRegisteredByGoogle: boolean
  organizationRemainingDays: number
  limitedOrganizationStorage: number
  currentOrganizationStorage: number
  partnerCode: string
  partnerContactUrl: string
}
export interface ILastActive {
  lastLogin: Date
  lastIp: string
  totalProjects: number
  totalOrganizations: number
}
export interface IResponseUserProfile {
  info: IInfo
  lastActivities: ILastActive
}

export interface IStorage {
  info: {
    limitedOrganizationStorage: number
    currentOrganizationStorage: number
  }
}
