import { Home } from '../pages/Home'
import { Error } from '../pages/Error'
import MainLayout from '../layouts/MainLayout'
import ProfileSetting from '../pages/user/ProfileSetting'

import ForgotPassword from '../pages/auth/ForgotPassword'
import ResetPassword from '../pages/auth/ResetPassword'
import Login from '../pages/auth/Login'
import Dashboard from '../pages/Dashboard'
import SceneSetting from '../pages/scene/SceneSetting'
import UnAuthLayout from '../layouts/UnAuthLayout'
import SignUp from '../pages/auth/SignUp'
import { PATHNAME } from '@constants/common'
import SelectPlanComponent from '@components/billing/selectPlan/SelectPlanComponent'
import PaymentConfirmation from '@components/profileSetting/billing/PaymentComponent'
import PaymentSuccess from '@components/profileSetting/billing/PaymentSuccess'

export const route = [
  {
    path: '/',
    component: Home,
    layout: UnAuthLayout,
    isExtraLayout: false,
    isAuthen: false,
  },
  {
    path: PATHNAME.SIGN_UP,
    component: SignUp,
    layout: UnAuthLayout,
    isExtraLayout: false,
    isAuthen: false,
  },
  {
    path: PATHNAME.SIGN_IN,
    component: Login,
    layout: UnAuthLayout,
    isExtraLayout: false,
    isAuthen: false,
  },
  {
    path: PATHNAME.DASHBOARD,
    component: Dashboard,
    layout: MainLayout,
    isExtraLayout: true,
    isAuthen: true,
  },
  {
    path: PATHNAME.FORGOT_PASSWORD,
    component: ForgotPassword,
    layout: UnAuthLayout,
    isExtraLayout: false,
    isAuthen: false,
  },
  {
    path: PATHNAME.RESET_PASSWORD,
    component: ResetPassword,
    layout: UnAuthLayout,
    isExtraLayout: false,
    isAuthen: false,
  },
  {
    path: PATHNAME.PROFILE_SETTING,
    component: ProfileSetting,
    layout: MainLayout,
    isExtraLayout: true,
    isAuthen: true,
  },
  {
    path: PATHNAME.CHANGE_PLAN,
    component: SelectPlanComponent,
    layout: MainLayout,
    isExtraLayout: true,
    isAuthen: true,
  },
  {
    path: PATHNAME.PAYMENT_CONFIRMATION,
    component: PaymentConfirmation,
    layout: MainLayout,
    isExtraLayout: true,
    isAuthen: true,
  },
  {
    path: `${PATHNAME.SCENE_SETTING}/:id`,
    component: SceneSetting,
    isExtraLayout: true,
    isAuthen: true,
  },
  // {
  //   path: PATHNAME.PLAN,
  //   component: PlanComponent,
  //   layout: PackageLayout,
  //   isExtraLayout: true,
  //   isAuthen: true,
  // },
  {
    path: '/payment-success',
    component: PaymentSuccess,
    isExtraLayout: true,
    isAuthen: true,
  },
  {
    path: '*',
    component: Error,
  },
]
