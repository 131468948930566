import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { RootState } from '../../../store/store'
import Media from './Media'
import Folder from './Folder'
import { actions } from '../../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../../store/project/project.type'
import { IMedia, TYPE_MEDIA } from '../../../models/media'
import NoMedia from './NoMedia'
import { IResponseSceneInfo } from '@models/sceneSetting'

interface PropTypes {
  medias: IMedia[]
  mediaTypes: TYPE_MEDIA[]
}
const ListMedias: React.FC<PropTypes> = ({ medias, mediaTypes }) => {
  const { mediaFolders } = useAppSelector((state: RootState) => state.project)
  const dataScene = useAppSelector(
    (state: RootState) => state.scene.dataTypeEdit?.data as IResponseSceneInfo
  )
  const dispatch = useAppDispatch()

  const handleClickOutsideMedias = () =>
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_MEDIA_LIB_POPUP](
        null
      )
    )

  return (
    <div className="h-[432px]" onMouseDown={() => handleClickOutsideMedias()}>
      {/* <div className="relative px-2 mb-2">
        <img
          src={IconSearch}
          className="absolute top-1/2 -translate-y-1/2 left-4"
          alt=""
        />

        <input
          className="w-full py-1.5 pl-10 pr-2 rounded-lg bg-white__op-50 text-white leading-6 focus-visible:outline-none font-lato"
          type="text"
          placeholder="Search"
        />
      </div> */}

      <div className="text-white max-h-[388px] overflow-y-auto scrollbar-hide">
        {dataScene?.bgmName && <NoMedia />}

        {mediaFolders?.map((mediaFolder, index) => (
          <Folder key={index} folder={mediaFolder} mediaTypes={mediaTypes} />
        ))}

        {medias?.length > 0 && mediaFolders?.length > 0 && (
          <hr className="mx-4 my-2 border-white/10" />
        )}

        {medias &&
          medias.map((media, index) => <Media key={index} media={media} />)}
      </div>
    </div>
  )
}

export default ListMedias
