import Button from '@components/common/Button'
import { usePlayerActions } from '../../../../../src/hooks/usePlayerActions'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { TYPE_MEDIA } from '@models/media'
import { S3MultipleUploader } from '../../../../api/s3'
import { RootState } from '@stores/store'
import { updateMediaThumbnailAction } from '@stores/project/project.action'
import {
  IRequestUpdateScene,
  IResponseSceneInfo,
  IResSceneOfGetSpaceAndScene,
} from '@models/sceneSetting'
import { useEffect, useState } from 'react'
import Icon from '@components/common/Icon'
import IconEdit from '@assets/icons/sceneSetting/edit.svg'
import IconClose from '@assets/icons/icon-close.svg'
import Portal from '@components/popups/Portal'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { useTranslation } from 'react-i18next'
import { helper } from '@utils/helper/common'

type InitialViewComponentProps = {
  dataScene: IResponseSceneInfo
  updateScene: (dataReq: IRequestUpdateScene) => Promise<unknown>
  dataSceneInfo?: IResSceneOfGetSpaceAndScene
}

export const InitialViewComponent: React.FC<InitialViewComponentProps> = ({
  dataScene,
  updateScene,
  dataSceneInfo,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { contextCodec } = useAppSelector((state: RootState) => state.scene)
  const { playerActions } = usePlayerActions()
  const [isLoading, setIsLoading] = useState(false)

  const [isBottomBarOpen, setIsBottomBarOpen] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const setInitialCameraState = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const screenShot = playerActions.current?.getScreenShot()
      if (!screenShot) {
        throw new Error('screenShot is empty')
      }
      const file = helper.base64toBlob(screenShot)
      const { fileKey, resourceId } = await saveImageToS3(file)
      const cameraState = playerActions.current?.getCameraState()
      await updateScene({
        organizationId: user!.organizationId,
        projectId: projectInfo!.projectId,
        sceneId: dataScene.sceneId,
        initialScenePreviewImageUrl: fileKey,
        contextCodec: contextCodec,
        initialCameraState: cameraState,
        thumbnailId: resourceId,
      })
      openNotification({
        type: TYPE.SUCCESS,
        message: t(
          'sceneSetting.rightSideBar.initialViewpoint.notification.setSuccess'
        ),
      })
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        message: t(
          'sceneSetting.rightSideBar.initialViewpoint.notification.setFailed'
        ),
      })
    } finally {
      setIsLoading(false)
      setIsBottomBarOpen(false)
    }
  }

  const resetInitialState = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      await updateScene({
        organizationId: user!.organizationId,
        projectId: projectInfo!.projectId,
        sceneId: dataScene.sceneId,
        initialCameraState: null,
        initialScenePreviewImageUrl: null,
        contextCodec: contextCodec,
      })
      openNotification({
        type: TYPE.SUCCESS,
        message: t(
          'sceneSetting.rightSideBar.initialViewpoint.notification.resetSuccess'
        ),
      })
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        message: t(
          'sceneSetting.rightSideBar.initialViewpoint.notification.setFailed'
        ),
      })
    } finally {
      setIsLoading(false)
      setIsBottomBarOpen(false)
    }
  }

  const saveImageToS3 = async (file: File | Blob) => {
    try {
      const projectId = projectInfo?.projectId
      if (!projectId) {
        throw new Error('projectId not found')
      }

      const uploader = new S3MultipleUploader({
        file,
        projectId,
        mediaType: TYPE_MEDIA.IMAGE,
        chunkSize: 1024 * 1024 * 5,
        threadsQuantity: 5,
      })

      await uploader.initialize()
      const fileKey = await uploader.start()

      if (!fileKey) {
        throw new Error('Upload failed')
      }
      const { bucket } = uploader.getFileInfo()

      if (dataSceneInfo?.imageThumbnailId) {
        const res = await dispatch(
          updateMediaThumbnailAction({
            projectId,
            fileInput: {
              resourceId: dataSceneInfo?.imageThumbnailId,
              mediaType: TYPE_MEDIA.GAUSSIAN_SPLATTING,
              key: fileKey,
              bucket: bucket!,
            },
          })
        ).unwrap()

        if (res.error) {
          throw new Error('Failed to create media record')
        }

        const { resourceId } = res.data
        return { fileKey, resourceId }
      } else {
        throw new Error('Image thumbnail not found')
      }
    } catch (error) {
      throw error
    }
  }

  const handleOpenBottomBar = () => {
    setIsBottomBarOpen((prev) => !prev)
    setIsTooltipOpen(!dataScene.initialScenePreviewImageUrl)
  }

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false)
  }

  useEffect(() => {
    setIsBottomBarOpen(false)
  }, [dataScene.sceneId])

  return (
    <div>
      <div className="group w-full rounded-lg overflow-hidden relative hover:after:content-[''] hover:after:absolute hover:after:inset-0 hover:after:bg-[hsla(0,0%,10%,0.88)]  ">
        <img
          className="w-full aspect-[5/3]  object-cover"
          alt=""
          src={
            dataScene.initialScenePreviewImageUrl ||
            dataScene.contextThumbnailUrl
          }
        />
        <div className="absolute bottom-0 left-0 right-0 p-2  z-10 group-hover:flex hidden cursor-pointer">
          <Icon.ActionIcon
            customClass="mr-1"
            icon={<img src={IconEdit} className="w-3 h-3" alt="" />}
          />
          <span
            className="text-xs leading-5 font-semibold text-blue-300"
            onClick={handleOpenBottomBar}
          >
            {t('sceneSetting.rightSideBar.initialViewpoint.change')}
          </span>
        </div>
      </div>

      {isBottomBarOpen && (
        <Portal container="#body-preview-scene">
          <div className="absolute bottom-8 left-0 right-0 flex justify-center pointer-events-none">
            <div className="pointer-events-auto flex gap-4 relative">
              <Button.Normal
                onClick={resetInitialState}
                className="rounded-xl bg-clip-padding border-2 border-[hsla(0,0%,0%,0.2)] font-semibold"
                color="secondary"
                disabled={isLoading}
              >
                {t('sceneSetting.rightSideBar.initialViewpoint.reset')}
              </Button.Normal>
              <div className="relative">
                <Button.Normal
                  className="rounded-xl bg-clip-padding border-2 border-[hsla(0,0%,0%,0.2)] font-semibold relative group-disabled:!bg-gray-300"
                  onClick={setInitialCameraState}
                  disabled={isLoading}
                >
                  {t(
                    'sceneSetting.rightSideBar.initialViewpoint.setInitialViewpoint'
                  )}
                </Button.Normal>
                {isTooltipOpen && (
                  <div className="absolute inset-0 pointer-events-none [--bg-color:#1d1d1d]">
                    <div className="pointer-events-auto bg-[var(--bg-color)] w-[320px] bottom-[calc(100%+16px)] rounded-[8px] left-1/2 -translate-x-1/2 absolute after:content-[''] after:absolute after:w-[12px]  after:h-[12px] after:bottom-[-5px] after:left-1/2 after:-translate-x-1/2 after:rotate-45  after:rounded-[1px] after:bg-[var(--bg-color)] p-4  font-lato text-[hsla(0,0%,97%,1)]">
                      <div className="text-[12px] font-bold leading-5 mb-1">
                        {t(
                          'sceneSetting.rightSideBar.initialViewpoint.tooltipTitle'
                        )}
                      </div>
                      <div className="text-[12px] leading-4">
                        {t(
                          'sceneSetting.rightSideBar.initialViewpoint.tooltipDes'
                        )}
                      </div>
                      <Icon.ActionIcon
                        customClass="absolute top-[13px] right-[16px] cursor-pointer"
                        icon={
                          <img
                            src={IconClose}
                            className="w-3 h-3"
                            alt=""
                            onClick={handleCloseTooltip}
                          />
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}
