import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  VideoPlayer,
  ImagePlayer,
  GSplatPlayer,
  IPlayerActions,
} from '@actualinc/whereness_player_package'
import { TYPE_CONTEXT } from '@models/media'
import { Coordinate3d } from '@actualinc/whereness_player_package/dist/types/coordinate3d'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { setInfoTagAction, setMarkerAction } from '@stores/scene/scene.action'
import {
  IEditSpot,
  ICoordinates,
  IRequestSetMarker,
  ISpot,
  TYPE_SPOT_SCENE,
  IRequestSetInfoTag,
  TypeContentInfoTag,
  TypeSizeInfoTag,
  TypeColorInfoTag,
  IMarkerResponse,
  IInfoTagResponse,
  IDataSelectMarkerInfoTag,
  IContextFormat,
} from '@models/sceneSetting'
import {
  actions as sceneActions,
  TYPE_SETTING,
  TYPE_TOOL_SCENE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { helper } from '@utils/helper/common'
import { ReturnSpot } from '@actualinc/whereness_player_package/dist/types/returnspot'
import _ from 'lodash'
import { MARKER_MESH_Y } from '@constants/sceneSetting'
import { usePlayerActions } from '../../../src/hooks/usePlayerActions'

interface IPropsType {
  url: string
  contextFormat?: IContextFormat
  contextType: string
  bgmUrl: string | undefined
  isMute: boolean
  setIsMute: React.Dispatch<React.SetStateAction<boolean>>
}
const Context360: React.FC<IPropsType> = ({
  url,
  contextFormat,
  contextType,
  bgmUrl,
  isMute,
  setIsMute,
}) => {
  const dispatch = useAppDispatch()
  const { dataScenePreview, toolSceneSetting, dataTypeEdit } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [coordinate, setCoordinate] = useState<ICoordinates | null>(null)
  const [listDataMarkerInfoTag, setListDataMarkerInfoTag] = useState<
    Array<ISpot>
  >([])
  const [editSpot, setEditSpot] = useState<IEditSpot | null>(null)
  const { setActionsRef } = usePlayerActions()
  const playerActionsRef: MutableRefObject<IPlayerActions | null> = useRef(null)

  useEffect(() => {
    if (dataTypeEdit.isTypeEdit === TYPE_SETTING.INFO_TAG) {
      const id = (dataTypeEdit.data as IInfoTagResponse).id
      mappingData({ type: TYPE_SETTING.INFO_TAG, id })
    } else if (dataTypeEdit.isTypeEdit === TYPE_SETTING.MARKER) {
      const id = (dataTypeEdit.data as IMarkerResponse).id
      mappingData({ type: TYPE_SETTING.MARKER, id })
    } else mappingData()
  }, [dataScenePreview])

  useEffect(() => {
    if (
      dataTypeEdit.isTypeEdit === TYPE_SETTING.INFO_TAG ||
      dataTypeEdit.isTypeEdit === TYPE_SETTING.MARKER
    ) {
      let id: number | null = null
      if (dataTypeEdit.isTypeEdit === TYPE_SETTING.INFO_TAG) {
        id = (dataTypeEdit.data as IInfoTagResponse).id
        mappingData({ type: TYPE_SETTING.INFO_TAG, id })
      } else {
        id = (dataTypeEdit.data as IMarkerResponse).id
        mappingData({ type: TYPE_SETTING.MARKER, id })
      }
    }
  }, [dataTypeEdit])

  useEffect(() => {
    if (dataScenePreview) {
      switch (toolSceneSetting) {
        case TYPE_TOOL_SCENE_SETTING.MARKER:
          setEditSpot({
            type: TYPE_SPOT_SCENE.MARKER,
          })
          break
        case TYPE_TOOL_SCENE_SETTING.INFO_TAG:
          setEditSpot({
            type: TYPE_SPOT_SCENE.INFO_TAG,
          })
          break

        default:
          setEditSpot(null)
          break
      }
    }
  }, [toolSceneSetting, dataScenePreview?.markers])

  const mappingData = (dataTypeSelect?: IDataSelectMarkerInfoTag) => {
    if (
      dataScenePreview &&
      dataScenePreview.markers &&
      dataScenePreview.infoTags
    ) {
      const { markers, infoTags } = dataScenePreview
      const listData = helper.mappingMarkerInfoTag(
        markers,
        infoTags,
        dataTypeSelect
      )
      setListDataMarkerInfoTag(listData)
    }
    if (
      dataScenePreview &&
      dataScenePreview.markers &&
      !dataScenePreview.infoTags
    ) {
      const { markers } = dataScenePreview
      const listData = helper.mappingMarker(markers, dataTypeSelect)
      setListDataMarkerInfoTag(listData)
    }
    if (
      dataScenePreview &&
      !dataScenePreview.markers &&
      dataScenePreview.infoTags
    ) {
      const { infoTags } = dataScenePreview
      const listData = helper.mappingInfoTag(infoTags, dataTypeSelect)
      setListDataMarkerInfoTag(listData)
    }
  }

  const onClickSpot = (e: ReturnSpot) => {
    const { spotType, id } = e
    if (spotType === TYPE_SPOT_SCENE.MARKER) {
      const data = _.find(dataScenePreview?.markers, {
        id,
      })
      const dataTypeEdit = {
        isTypeEdit: TYPE_SETTING.MARKER,
        data,
      }
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](dataTypeEdit)
      )
    }
    if (spotType === TYPE_SPOT_SCENE.INFO_TAG) {
      const data = _.find(dataScenePreview?.infoTags, {
        id,
      })
      const dataTypeEdit = {
        isTypeEdit: TYPE_SETTING.INFO_TAG,
        data,
      }
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](dataTypeEdit)
      )
    }
    const newListMarkerInfoTag = listDataMarkerInfoTag.map((spot) => {
      if (spot.id === id) {
        return {
          ...spot,
          isSelected: true,
        }
      }
      return {
        ...spot,
        isSelected: false,
      }
    })
    setListDataMarkerInfoTag(newListMarkerInfoTag)
  }

  const handleGetCoordinates = (e: Coordinate3d) => {
    const { x, y, z } = e
    setCoordinate({ xAxis: x, yAxis: y, zAxis: z })
  }

  useEffect(() => {
    if (toolSceneSetting === TYPE_TOOL_SCENE_SETTING.OFF) {
      setCoordinate(null)
    } else {
      handleSetMarkerOrInfoTag()
    }
  }, [toolSceneSetting, coordinate])

  const handleSetMarkerOrInfoTag = useCallback(() => {
    if (coordinate && dataScenePreview) {
      switch (toolSceneSetting) {
        case TYPE_TOOL_SCENE_SETTING.MARKER:
          handleSetMarker(coordinate)
          break
        case TYPE_TOOL_SCENE_SETTING.INFO_TAG:
          handleSetInfoTag(coordinate)
          break
        default:
          throw new Error('Something bug')
      }
    }
  }, [coordinate, toolSceneSetting, dataScenePreview])

  const handleSetMarker = async (coordinates: ICoordinates) => {
    if (user && projectInfo && dataScenePreview) {
      const req: IRequestSetMarker = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        marker: {
          coordinates,
          linkedSceneId: null,
          markerMesh:
            !dataScenePreview.markers?.length || !dataScenePreview.markers
              ? MARKER_MESH_Y
              : _.last(dataScenePreview.markers)?.markerMesh,
          name: 'Link',
        },
      }
      const resSetMarker = await dispatch(setMarkerAction(req)).unwrap()
      if (resSetMarker.data) {
        setCoordinate(null)
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING](
            TYPE_TOOL_SCENE_SETTING.OFF
          )
        )
      }
    }
  }

  const handleSetInfoTag = async (coordinates: ICoordinates) => {
    if (user && projectInfo && dataScenePreview) {
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          coordinates,
          name: 'Tag',
          tagType: TypeContentInfoTag.TEXT,
          size: TypeSizeInfoTag.LARGE,
          color: TypeColorInfoTag.BLUE,
        },
      }
      const resSetInfoTag = await dispatch(setInfoTagAction(req)).unwrap()
      if (resSetInfoTag.data) {
        setCoordinate(null)
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING](
            TYPE_TOOL_SCENE_SETTING.OFF
          )
        )
      }
    }
  }

  const handleMute = useCallback((isMute: boolean) => {
    setIsMute(isMute)
  }, [])

  useEffect(() => {
    if (contextType === TYPE_CONTEXT.GS) {
      if (playerActionsRef.current) {
        setActionsRef(playerActionsRef)
      }
    }
  }, [contextType])

  switch (contextType) {
    case TYPE_CONTEXT.VIDEO:
      return (
        <VideoPlayer
          src={url}
          muted={isMute}
          autoPlay={true}
          edit={editSpot}
          type="cms"
          bgm={bgmUrl}
          data={listDataMarkerInfoTag}
          onClickSpot={onClickSpot}
          onCoordinates={handleGetCoordinates}
          onMute={handleMute}
        />
      )
    case TYPE_CONTEXT.IMAGE:
      return (
        <ImagePlayer
          src={url}
          muted={isMute}
          type="cms"
          bgm={bgmUrl}
          edit={editSpot}
          data={listDataMarkerInfoTag}
          onClickSpot={onClickSpot}
          onCoordinates={handleGetCoordinates}
          onMute={handleMute}
        />
      )
    case TYPE_CONTEXT.GS:
      return (
        <GSplatPlayer
          actionsRef={playerActionsRef}
          src={url}
          format={contextFormat}
          muted={isMute}
          type="cms"
          bgm={bgmUrl}
          edit={editSpot}
          data={listDataMarkerInfoTag}
          onClickSpot={onClickSpot}
          onCoordinates={handleGetCoordinates}
          onMute={handleMute}
          initialCameraState={dataScenePreview?.initialCameraState}
        />
      )
    default:
      return null
  }
}

export default React.memo(Context360)
