import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  IBillingPeriodType,
  IProduct,
  IResListCmsProduct,
  IUnitPriceCmsProduct,
} from '@models/organization'

import { parseStorageToBytes } from '@utils/helper/storage'

import Icon from '@components/common/Icon'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import {
  IUnitServicePackageDetail,
  organizationActions,
} from '@stores/organization/organization.reducer'
import { TYPE_ORGANIZATION } from '@stores/organization/organization.type'
import { RootState } from '@stores/store'
import { setLocalCookie } from '@utils/cookies'
import { DETAIL_PLAN_KEY, PATHNAME } from '@constants/common'
import { TypeMoney } from '@models/project'
import IconTick from '@assets/icons/plan/tick.svg'
import i18next from 'i18next'
import { ILanguageType } from '@stores/common/common.reducer'
import {
  getUserPlanByUserIdAction,
  getUserPlanPriceAction,
} from '@stores/userPlan/userPlan.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import Button from '@components/common/Button'
import { helper } from '@utils/helper/common'

interface IPropsType {
  listCmsProduct: Array<IResListCmsProduct>
  handleSetNewAddOn: (keyView: string, keySize: string) => void
  peroidTypePackage: IBillingPeriodType
  currentPlan: IProduct | undefined
}

const ListServicePackageComponent: React.FC<IPropsType> = (
  props
): React.ReactElement => {
  const { listCmsProduct, peroidTypePackage, handleSetNewAddOn, currentPlan } =
    props
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const [userPlan, setUserPlan] = useState<any>(null)
  const [userBillingType, setUserBillingType] = useState<any>(null)
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const lang = i18next.language
  const { newServicePackage, newAddOn } = useAppSelector(
    (state: RootState) => state.organization
  )
  const navigate = useNavigate()

  const [priceSelect, setPriceSelect] = useState<{
    byMonth: IUnitPriceCmsProduct
    byYear: IUnitPriceCmsProduct
  } | null>(null)

  useEffect(() => {
    if (newServicePackage) {
      const service = _.find(listCmsProduct, {
        productId: newServicePackage.servicePackageId,
      })
      if (service) {
        const { prices } = service
        const productCurrency = TypeMoney.JPY.toUpperCase()
        const priceYears = _.find(prices.byYear, { productCurrency })
        const priceMonths = _.find(prices.byMonth, { productCurrency })
        if (priceMonths && priceYears) {
          setPriceSelect({
            byMonth: priceMonths as IUnitPriceCmsProduct,
            byYear: priceYears as IUnitPriceCmsProduct,
          })
        }
      }
    }
  }, [])
  const handleChangePlan = (service: any, peroidTypePackage: string) => {
    if (checkTrialToFree(service.planName)) {
      helper.redirectToContact()
      return
    }

    navigate(PATHNAME.PAYMENT_CONFIRMATION, {
      state: { service, peroidTypePackage },
    })
  }

  useEffect(() => {
    if (priceSelect && newServicePackage) {
      const dataSerivePackage: IUnitServicePackageDetail = {
        ...newServicePackage,
        billingPeriodType: peroidTypePackage,
        price: priceSelect[peroidTypePackage],
      }
      handleSetNewServicePackage(dataSerivePackage)
    } else {
      const currentProduct = _.find(listCmsProduct, {
        productId: currentPlan?.productId,
      })
      if (currentProduct && currentPlan) {
        if (currentPlan.billingPeriodType !== peroidTypePackage) {
          const { productId, productName, productKey } = currentPlan
          const { prices } = currentProduct
          const productCurrency = TypeMoney.JPY.toUpperCase()
          const price = _.find(prices[peroidTypePackage], { productCurrency })
          const dataSerivePackage: IUnitServicePackageDetail = {
            servicePackageId: productId,
            servicePackageName: productName[lang as ILanguageType],
            servicePackageKey: productKey,
            price: price as IUnitPriceCmsProduct,
            billingPeriodType: peroidTypePackage,
          }
          handleSetNewServicePackage(dataSerivePackage)
        } else {
          dispatch(
            organizationActions[
              TYPE_ORGANIZATION.REDUCERS.SET_NEW_SERVICE_PACKAGE
            ](null)
          )
        }
      }
    }
  }, [peroidTypePackage])

  function capitalizeFirstLetter(planName: string) {
    return planName.charAt(0).toUpperCase() + planName.slice(1).toLowerCase()
  }

  const handleSetNewServicePackage = (value: IUnitServicePackageDetail) => {
    dispatch(
      organizationActions[TYPE_ORGANIZATION.REDUCERS.SET_NEW_SERVICE_PACKAGE](
        value
      )
    )
    setLocalCookie(DETAIL_PLAN_KEY, JSON.stringify(value))
  }

  useEffect(() => {
    const getUserPlanByUserId = async () => {
      if (typeof user?.userId === 'undefined') {
        return
      }
      try {
        const data = await dispatch(
          getUserPlanByUserIdAction(user?.userId)
        ).unwrap()
        const data2 = await dispatch(getUserPlanPriceAction()).unwrap()
        setUserBillingType(data2?.data)
        setUserPlan(data)
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getUserPlanByUserId',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
    getUserPlanByUserId()
  }, [dispatch, user])

  const checkUserCurrentPlan = (checkPlan: string): boolean => {
    if (
      (userPlan?.plan?.name.toLowerCase() === checkPlan?.toLowerCase() &&
        peroidTypePackage ===
          (userBillingType?.billingPeriodType === 'byMonth'
            ? 'monthlyPrice'
            : 'yearlyPrice')) ||
      (userPlan?.plan?.name.toLowerCase() === 'free' &&
        checkPlan?.toLowerCase() === 'free')
    ) {
      return true
    }
    return false
  }

  const checkStorageShow = (planStorage: string): boolean => {
    const { currentOrganizationStorage } = userProfile?.info

    if (!currentOrganizationStorage) {
      return false
    }

    try {
      const usedStorageInBytes = parseStorageToBytes(
        `${currentOrganizationStorage}B`
      ) // Assume already in bytes
      const planStorageInBytes = parseStorageToBytes(planStorage) // Parse plan storage with units

      // Compare used storage and plan storage
      return usedStorageInBytes >= planStorageInBytes
    } catch (error) {
      console.error(error.message)
      return false
    }
  }

  const checkProjectLimitShow = (
    planStorage: string,
    planName: string,
    projectLimit: number
  ): string => {
    const storageCheck = checkStorageShow(planStorage)
    const isFreePlan = planName?.toLowerCase() === 'free'
    const exceededProjects =
      (userProfile?.lastActivities?.totalProjects ?? 0) > projectLimit

    if (!isFreePlan) return ''
    if (exceededProjects)
      return storageCheck
        ? t('plan.projectLimitShowBoth')
        : t('plan.projectLimitShow')
    if (storageCheck) return t('plan.storageLimitShow')
    return ''
  }

  const getFormattedPrice = (
    service: any,
    periodType: string,
    language: string
  ): string => {
    if (!service || !periodType || !language) {
      console.error('Missing required parameters.')
      return ''
    }

    // Determine base price
    let price =
      periodType === 'yearlyPrice'
        ? service.yearlyPrice / 12
        : service.monthlyPrice

    // Adjust price for USD (divide by 100)
    if (service.currency === 'USD') {
      price = price / 100
    }

    // Format price conditionally (remove `.00` if not needed)
    const formattedPrice = Number.isInteger(price)
      ? price.toString()
      : price.toFixed(2)

    // Determine currency
    const currency =
      language === 'ja' && service.currency === 'USD'
        ? t('currency.usd')
        : service.currency === 'USD' || language === 'en'
        ? service.currency
        : t(`currency.${service.currency.toLowerCase()}`)

    return `${formattedPrice} ${currency}`
  }

  const checkTrialToFree = (checkPlan: string): boolean => {
    if (
      userPlan?.plan?.name.toLowerCase() === 'trial' &&
      checkPlan?.toLowerCase() === 'free'
    ) {
      return true
    }
    return false
  }

  const checkUpcommigPlan = (checkPlan: string): boolean => {
    const packageBillingType = {
      byMonth: 'monthlyPrice',
      byYear: 'yearlyPrice',
    }

    // Check if the current plan name matches the provided plan name
    if (
      userBillingType?.upcoming?.planName?.toLowerCase() ===
        checkPlan?.toLowerCase() &&
      packageBillingType[userBillingType?.upcoming?.billingPeriodType] ===
        peroidTypePackage
    ) {
      return true
    }
    return false
  }

  return (
    <>
      <div className="grid gap-4 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-4">
        {listCmsProduct &&
          listCmsProduct.length > 0 &&
          listCmsProduct.map((service: any, index) => (
            <div
              className={classNames(
                'flex flex-col max-w-[280px]  py-6 px-4 bg-[rgba(255,255,255,0.1)] rounded-2xl transition-all duration-300 '
                // {
                //   ' md:h-[412px]': true,
                // '!bg-blue-700':
                //     newServicePackage?.servicePackageId === service.productId,
                // }
              )}
              key={index}
            >
              <div className="font-semibold text-[24px] lg:text-[28px] text-white">
                {capitalizeFirstLetter(
                  i18n.language === 'en'
                    ? service?.remarks.nameEn
                    : service?.remarks.name
                )}
              </div>
              {index === 0 && (
                <div className="w-[248px] h-[28px] gap-0 font-montserrat text-[20px] font-semibold leading-[28px] tracking-[-0.01em] text-left text-white">
                  {/* {t('plan.freePrice')} */}
                </div>
              )}
              {index !== 0 && (
                <div className="w-auto h-auto font-montserrat text-[20px] font-semibold leading-[28px] tracking-[-0.01em] text-left text-white md:text-[16px] sm:text-[10px]">
                  {getFormattedPrice(service, peroidTypePackage, i18n.language)}
                  {t('plan.moneySub')}
                </div>
              )}

              <div className="w-full md:max-w-[248px]  h-[76px] mt-2">
                <div className=" sm:leading-6  text-[16px]  font-lato font-normal text-white text-left break-words md:whitespace-normal ">
                  {i18n.language === 'en'
                    ? service.remarks.definitionEn
                    : service.remarks.definition}
                </div>
                <div className="leading-4 sm:leading-5 md:leading-6 font-lato text-white__op-600 mb-4 sm:mb-3 md:mb-4 lg:mb-4 mt-1 sm:mt-1 lg:mt-1 md:mt-5  sm:text-sm text-[14px] ">
                  {service.remarks.storage} {t('plan.storage')}
                </div>
              </div>
              <div className="flex justify-center mt-5 mb-4 flex-col">
                {/* {newServicePackage?.servicePackageId === service.productId ? ( */}
                {/* <button
                    title="Select"
                    className="font-semibold leading-5 bg-[rgba(86,148,240,1)] text-[rgba(255,255,255,0.97)] w-full  text-[14px] sm:w-[200px] md:w-[248px] h-[36px] py-1.5 px-3 rounded-full"
                  >
                    {t('plan.select')}
                  </button> */}
                {/* ) : ( */}
                <Button.Normal
                  classNameContainer=""
                  className="font-semibold text-sm"
                  color="solid"
                  title={
                    userPlan
                      ? checkTrialToFree(service.planName)
                        ? t('plan.contact')
                        : checkUpcommigPlan(service.planName)
                        ? t('plan.upcommigPlan')
                        : checkUserCurrentPlan(service.planName)
                        ? t('plan.currentPlan')
                        : t('plan.select')
                      : t('plan.select')
                  }
                  onClick={() => handleChangePlan(service, peroidTypePackage)}
                  disabled={
                    (userPlan &&
                      (checkUserCurrentPlan(service.planName) ||
                        checkUpcommigPlan(service.planName))) ||
                    checkStorageShow(service.remarks.storage) ||
                    checkProjectLimitShow(
                      service.remarks.storage,
                      service.planName,
                      service.metadata.limited.createProject
                    ) !== ''
                  }
                />
                {checkStorageShow(service.remarks.storage) &&
                  service.planName.toLowerCase() !== 'free' && (
                    <div className="flex flex-row items-start gap-2.5 max-w-full sm:w-[248px] font-lato text-xs leading-[20px] text-[rgba(255,255,255,0.97)]">
                      <p className="w-full">{t('plan.storageLimitShow')}</p>
                    </div>
                  )}

                {checkProjectLimitShow(
                  service.remarks.storage,
                  service.planName,
                  service.metadata.limited.createProject
                ) && (
                  <div className="flex flex-row items-start gap-2.5 max-w-full sm:w-[248px] font-lato text-xs leading-[20px] text-[rgba(255,255,255,0.97)]">
                    <p className="w-full">
                      {checkProjectLimitShow(
                        service.remarks.storage,
                        service.planName,
                        service.metadata.limited.createProject
                      )}
                    </p>
                  </div>
                )}
              </div>

              {/* {index === 1 && (
                <div className="text-white font-semibold text-[14px] md:mt-1 lg:mt-2">
                  {t('plan.freePlan')}
                </div>
              )}
              {index === 2 && (
                <div className="text-white font-semibold text-[14px] md:mt-1 lg:mt-2">
                  {t('plan.personalPlan')}
                </div>
              )}
              {index === 3 && (
                <div className="text-white font-semibold text-[14px] md:mt-1 lg:mt-2">
                  {t('plan.standardPlan')}
                </div>
              )} */}
              {index !== 0 && (
                <>
                  <div className="text-white font-semibold text-[14px] md:mt-1 lg:mt-2">
                    {i18n.language === 'en'
                      ? service.remarks.featureTitleEn
                      : service.remarks.featureTitle}
                  </div>
                </>
              )}

              <div className="font-semibold leading-5 py-0.5" />
              {(i18n.language === 'en'
                ? service.remarks.featuresEn
                : service.remarks.features
              ).map((data: any, idx) => (
                <div className="flex items-center mt-1" key={idx}>
                  <Icon.ActionIcon icon={<img src={IconTick} alt="" />} />
                  <div className="font-lato leading-6 text-white ml-2.5 text-[14px]">
                    {data}
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </>
  )
}

export default ListServicePackageComponent
