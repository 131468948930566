import { IMediaProcess } from './media'
import { IPager } from './project'

export enum OrganizationSubscriptionEvent {
  CMS_REGISTER_SERVICE_PACKAGE_COMPLETED = 'CMS_REGISTER_SERVICE_PACKAGE_COMPLETED',
  CMS_REGISTER_ADD_ONS_ONLY_COMPLETED = 'CMS_REGISTER_ADD_ONS_ONLY_COMPLETED',
  MEDIA_PROCESS_STATUS = 'MEDIA_PROCESS_STATUS',
}

export enum ExtensionKey {
  INVITE_MEMBER = 'INVITE_MEMBER',
  S3_STORAGE = 'S3_STORAGE',
  CONVERT_MEDIA = 'CONVERT_MEDIA',
}

export enum ProjectPublishStatusName {
  PUBLISH = 'public',
  UN_PUBLISH = 'private',
  PASSWORD = 'password',
  PUBLISH_WITH_LIMITED = 'limited_release',
}
//another
export interface IOptionServicePackage {
  [key: string]: {
    planName: string
    monthlyPrice: string[]
    YearlyPrice: string[]
    des: string
    serviceInheritance: string
    option: string[]
    storageData: number
  }
}
//req
export enum IBillingPeriodType {
  BY_MONTH = 'monthlyPrice',
  BY_YEAR = 'yearlyPrice',
}

export interface IUnitProductInput {
  id: number
  billingPeriodType: IBillingPeriodType
}

export interface IreqRegisterServicePackage {
  organizationId: string
  servicePackage?: IUnitProductInput
  addOn?: IUnitProductInput
  sourceToken?: string
}

export interface IreqListCmsProduct {
  organizationId: string
  langCode?: string
}

export interface IreqCmsProductBillings {
  organizationId: string
  pager?: IPager
}

export interface IreqGetCurrentPlanAndCard {
  organizationId: string
  isGetCardOnly?: boolean
  isCurrentPlanOnly?: boolean
}

export interface IReqUpdatePaymentCard {
  organizationId: string
  sourceToken: string
}

//res
export interface IResListCmsProduct {
  productId: number
  productName: IMultiLanguageType
  productKey: string
  monthlyPriceId: string
  productType: string
  planName: string
  prices: IPriceCmsProduct
  productExtension: Array<IProductExtension>
}

export interface IMultiLanguageType {
  en: string
  ja: string
}

export type ITypeCurrency = 'ja' | 'usd'
export interface IPriceCmsProduct {
  byYear: Array<IUnitPriceCmsProduct>
  byMonth: Array<IUnitPriceCmsProduct>
}

export interface IUnitPriceCmsProduct {
  priceId: number
  productPrice: number
  productCurrency: ITypeCurrency
}

export type ITypeExpiredUnit = 'DAYS' | 'WEEKS' | 'MONTHS' | 'YEARS'
export interface IProductExtension {
  extensionKey: string
  limitedValue: string
  expiredTime: number
  expiredUnit: ITypeExpiredUnit
}

export interface IResCurrentPlanAndCardInfo {
  servicePackage: IProduct
  addOn: IAddOn
  cardInfo: ICardInfo
}

export interface IProduct {
  productId: number
  productDataName: string
  productName: IMultiLanguageType
  productKey: string
  billingPeriodType: IBillingPeriodType
  price: number
  nextPaymentDate: number
  nextPaymentPrice: number
  currentPaymentExpireDate: number
}

export interface IAddOn extends IProduct {
  publishStorageLimit: string
  currentViews: number
  viewsLimit: number
}

export interface ICardInfo {
  brand: string
  lastFour: number
}

export interface IResCmsProductBillings {
  billings: Array<ICmsProductBilling>
  pager: IPager
}

export interface ICmsProductBilling {
  name: IMultiLanguageType
  organizationStorage: string
  publishStorage: string
  views: number
  price: number
  status: IBillingStatus
  billingUrl: string
  createdAt: number
}

export const enum IBillingStatus {
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
}

export interface IOrganizationSubscription {
  data: IOrganizationSubscriptionData
  event: OrganizationSubscriptionEvent
}

export interface IOrganizationSubscriptionData {
  mediaProcess: IMediaProcess
}
