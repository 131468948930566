// storageUtils.js

/**
 * Converts a storage string (e.g., "10GB", "500MB") into bytes.
 * @param {string} storage - The storage string with units (e.g., "10GB").
 * @returns {number} The storage in bytes.
 * @throws Will throw an error if the storage format is invalid.
 */
export const parseStorageToBytes = (storage) => {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 ** 2,
    GB: 1024 ** 3,
    TB: 1024 ** 4,
  }

  const regex = /(\d+(\.\d+)?)([a-zA-Z]+)/ // Matches the number and unit
  const match = storage.match(regex)

  if (match) {
    const value = parseFloat(match[1]) // Extract numeric value
    const unit = match[3].toUpperCase() // Extract unit (e.g., GB, MB)
    return value * (units[unit] || 1) // Convert to bytes
  }

  throw new Error(`Invalid storage format: ${storage}`)
}

/**
 * Capitalizes the first letter of a string and makes the rest lowercase.
 * @param {string} str - The input string.
 * @returns {string} - The formatted string.
 */
export const capitalizeFirstLetter = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
